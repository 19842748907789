import type {
  AssetBookingDetailDto,
  BookableAssetDeletedResult,
  BookableAssetDetailDto,
  BookableSlotDto,
  CreatedEntityDto,
} from "api/types";
import iconFile06 from "assets/icons/file-06.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import SuccessIcon from "assets/images/party.png";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { EntityDetailsCard } from "components/EntityDetailsCard/EntityDetailsCard";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Modal } from "components/Modal/Modal";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { isAfter } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookingTimeslotButton } from "modules/bookings/components/BookingTimeslotButton";
import { daysOptions } from "modules/bookings/constants";
import { getTimeslotDateTime, isAfterOrSame, isBeforeOrSame } from "modules/bookings/helpers";
import { canManageAnyBookableAsset, canViewSchedule } from "modules/bookings/permissions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  assetSlots: BookableSlotDto[];
  assetBookingDetails: AssetBookingDetailDto | undefined;
  minDate: Date;
  maxDate: Date;
  defaultValues: DefaultBookAssetFormValues;
  onDateChange: (date: Date | null) => void;
  onSubmit: (data: BookAssetFormValues) => Promise<CreatedEntityDto>;
  futureBookings: number;
  isSubmitting: boolean;
  isLoadingAssetSlots: boolean;
  isEditMode: boolean;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
}

export interface BookAssetFormValues {
  date: Date;
  bookAllDay: boolean;
  startTime: Date | null;
  endTime: Date | null;
  reason?: string;
  isRegulationAccepted: boolean;
}

export type DefaultBookAssetFormValues = Omit<BookAssetFormValues, "bookAllDay">;

export function Layout({
  assetDetails,
  assetSlots,
  assetBookingDetails,
  minDate,
  maxDate,
  defaultValues,
  onDateChange,
  onSubmit,
  futureBookings,
  isSubmitting,
  isLoadingAssetSlots,
  isEditMode,
  onDelete,
}: LayoutProps): React.ReactNode {
  const [potentialEndTime, setPotentialEndTime] = useState<Date | null>(null);

  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();

  const [isSuccessModalOpen, successModalHandler] = useBool(false);
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const form = useForm<BookAssetFormValues>({ defaultValues });
  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });
  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  useEffect(() => {
    form.register("startTime", {
      validate: {
        required: () => {
          if (!bookAllDay && !startTime) return t("page.bookings.book-asset.form.booking-timeslot.error");
          if (startTime && !endTime) return t("page.bookings.book-asset.form.booking-timeslot.error");

          return undefined;
        },
      },
    });
  }, [form, t, startTime, bookAllDay, endTime]);

  const isDayDisabled = useCallback(
    (current: Date) => {
      const disabledDays = assetDetails.bookableDays
        .filter((day) => !day.enabled)
        .map((day) => daysOptions.indexOf(day.day));

      return disabledDays.includes(dayOfWeekIndex(current));
    },
    [assetDetails.bookableDays],
  );

  const handleDateChange = (date: DatePickerValue) => {
    onDateChange(date === "" ? null : date);
    if (date !== "") {
      form.setValue("date", date);
      form.setValue("startTime", null);
      form.setValue("endTime", null);
      form.setValue("bookAllDay", false);
    }
  };

  function handleBookAllDay(checked: boolean) {
    form.setValue("bookAllDay", checked);
    if (checked) {
      const { startTime } = getTimeslotDateTime(assetSlots[0]);
      const { endTime } = getTimeslotDateTime(assetSlots[assetSlots.length - 1]);

      form.setValue("startTime", startTime);
      form.setValue("endTime", endTime);
    } else {
      form.setValue("startTime", null);
      form.setValue("endTime", null);
    }
  }

  const handleSubmit = async () => {
    await onSubmit(form.getValues());

    successModalHandler.setTrue();
  };

  const handleHoverPotentialEndTimeslot = (time: Date | null) => {
    if (endTime) return;

    setPotentialEndTime(time);
  };

  const actions: ContextMenuAction[] = [];
  if (assetDetails.canEdit) {
    actions.push({
      text: t("common.action.edit"),
      callback: () => navigate(routes.bookings.editAsset({ slug, id: assetDetails.id })),
    });
  }
  if (assetDetails.canDelete) {
    actions.push({
      text: t("common.action.delete"),
      callback: () => openDeleteModal(assetDetails.id),
    });
  }
  const firstUnavailableSlot = useMemo(() => {
    if (!startTime) return undefined;

    const listSortedUnavailableSlot = assetSlots
      .filter((slot) => slot.state === "booked")
      .sort((a, b) => a.startTime.localeCompare(b.startTime));

    // When creating a new booking, compare to the selected start time.
    // When editing an existing reservation, compare to the existing booking's start time.
    const comparedStartTime =
      isEditMode && assetBookingDetails
        ? new Date(`${assetBookingDetails.date}T${assetBookingDetails.startTime}`)
        : startTime;

    return listSortedUnavailableSlot.find((slot) => {
      const { startTime: currStartTime } = getTimeslotDateTime(slot);

      return isAfter(currStartTime, comparedStartTime);
    });
  }, [startTime, assetSlots, isEditMode, assetBookingDetails]);
  const isBookAllDayPossible =
    assetSlots.length > 0 &&
    assetSlots.every((slot) => slot.state === "available") &&
    assetDetails.timeslot !== "allDay" &&
    assetDetails.canBookMultipleSlots;
  let labelTimeslotField = t("page.bookings.book-asset.form.booking-timeslot.restricted");
  if (assetDetails.canBookMultipleSlots) {
    if (!startTime) {
      labelTimeslotField = t("page.bookings.book-asset.form.booking-timeslot.first");
    } else if (startTime && !endTime) {
      labelTimeslotField = t("page.bookings.book-asset.form.booking-timeslot.second");
    }
  }
  if (startTime && endTime) {
    labelTimeslotField = t("page.bookings.book-asset.form.booking-timeslot.third");
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <EntityDetailsCard
            title={assetDetails.name}
            description={assetDetails.description}
            images={assetDetails.images}
            renderLabels={() => (
              <>
                {assetDetails.hasAudience && sessionUser.isAdmin && (
                  <Label theme="blue">
                    <Icon size={16} name={iconUsersCheck} />
                  </Label>
                )}
              </>
            )}
            renderBody={() => (
              <>
                {assetDetails.audience &&
                  assetDetails.audience.length > 0 &&
                  sessionUser.isAdmin &&
                  hasPermission(canManageAnyBookableAsset) && (
                    <AudiencePreview audience={assetDetails.audience} readOnly />
                  )}
              </>
            )}
            {...{ actions }}
          />
        </PageGrid.Item>
        <PageGrid.Item size="50%">
          <div className="flex h-fit flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
            <Headline4>{t("page.bookings.book-asset.section.book-asset.title")}</Headline4>
            {maxDate < minDate ? (
              <p>{t("page.bookings.book-asset.section.book-asset.not-available")}</p>
            ) : (
              <Form formMethods={form} onSubmit={handleSubmit}>
                <FormContent maxWidth="4xl">
                  <FormField label={t("page.bookings.book-asset.form.booking-date.label")}>
                    <FormDateAndTimePicker<BookAssetFormValues, "date">
                      data-testid="date-picker-input"
                      name="date"
                      min={minDate}
                      max={maxDate}
                      disabledDate={isDayDisabled}
                      onChange={handleDateChange}
                      rules={{
                        validate: {
                          laterThanMin: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            return date < minDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-after-date", {
                                  date: formatDate(i18n, "date", minDate),
                                })
                              : undefined;
                          },
                          soonerThanMax: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            if (!maxDate) {
                              return;
                            }

                            return date > maxDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-before-date", {
                                  date: formatDate(i18n, "date", maxDate),
                                })
                              : undefined;
                          },
                        },
                      }}
                    />
                  </FormField>
                  <FormErrorWrapper name="startTime" encircle>
                    <div className="flex flex-col gap-2">
                      <FormField label={labelTimeslotField}>
                        <div className="flex flex-wrap gap-2">
                          {isLoadingAssetSlots && <FullSizeLoader />}
                          {!isLoadingAssetSlots &&
                            assetSlots.length > 0 &&
                            assetSlots.map((timeslot, idx) => {
                              let isTimeslotBookedByUser = false;
                              if (assetBookingDetails && timeslot.state === "booked") {
                                const { startTime: currStartTime, endTime: currEndTime } =
                                  getTimeslotDateTime(timeslot);
                                const userBookingStartTime = new Date(
                                  `${assetBookingDetails.date}T${timeslot.startTime}`,
                                );
                                const userBookingEndTime = new Date(`${assetBookingDetails.date}T${timeslot.endTime}`);

                                isTimeslotBookedByUser =
                                  isAfterOrSame(userBookingStartTime, currStartTime) &&
                                  isBeforeOrSame(userBookingEndTime, currEndTime);
                              }

                              return (
                                <BookingTimeslotButton
                                  key={idx}
                                  timeslot={timeslot}
                                  canBookMultipleSlots={assetDetails.canBookMultipleSlots}
                                  onHover={handleHoverPotentialEndTimeslot}
                                  isBookedByUser={isTimeslotBookedByUser}
                                  {...{ potentialEndTime, firstUnavailableSlot }}
                                />
                              );
                            })}
                          {!isLoadingAssetSlots && assetSlots?.length === 0 && (
                            <Subtitle2 className="font-normal">
                              {t("page.bookings.book-asset.form.booking-timeslot.no-slots.available")}
                            </Subtitle2>
                          )}
                        </div>
                      </FormField>
                      {isBookAllDayPossible && (
                        <FormCheckbox
                          name="bookAllDay"
                          label={t("page.bookings.book-asset.form.booking-timeslot.all-day")}
                          onChange={(event) => handleBookAllDay(event.target.checked)}
                        />
                      )}
                    </div>
                  </FormErrorWrapper>
                  {assetDetails.requireBookingReason && (
                    <FormField label={t("page.bookings.book-asset.form.booking-reason.label")} required>
                      <FormInput<BookAssetFormValues, "reason">
                        name="reason"
                        placeholder={t("page.bookings.book-asset.form.booking-reason.placeholder")}
                        rules={{
                          validate: {
                            required: createRequiredStringRule(t, "page.bookings.book-asset.form.booking-reason.name"),
                          },
                        }}
                      />
                    </FormField>
                  )}
                  {assetDetails.regulationDocument && (
                    <FormErrorWrapper name="isRegulationAccepted" encircle>
                      <div className="flex flex-col gap-1">
                        <FormCheckbox
                          name="isRegulationAccepted"
                          label={t("page.bookings.book-asset.form.booking-regulations.label")}
                          alignTop
                          rules={{
                            validate: {
                              required: createRequiredStringRule(
                                t,
                                "page.bookings.book-asset.form.booking-reason.name",
                              ),
                            },
                          }}
                        />
                        <Button
                          styling="tertiary"
                          onClick={() => window.open(assetDetails.regulationDocument?.url, "_blank")}
                          icon={<Icon name={iconFile06} size={20} />}
                          className="max-w-full"
                        >
                          <span className="truncate">{assetDetails.regulationDocument?.fileName}</span>
                        </Button>
                      </div>
                    </FormErrorWrapper>
                  )}
                  <div className="flex w-full justify-end">
                    <Button type="submit" isLoading={isSubmitting}>
                      {t("page.bookings.book-asset.book")}
                    </Button>
                  </div>
                </FormContent>
              </Form>
            )}
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>
      <Modal isOpen={isSuccessModalOpen} isActionRequired shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
        <div className="flex flex-col items-center justify-between gap-8 p-4">
          <Headline4>{t("page.bookings.book-asset.success-modal.title")}</Headline4>
          <img src={SuccessIcon} alt="success" />
          <div className="flex w-full flex-col items-center gap-2">
            <span className="w-full">
              <Button
                onClick={() =>
                  hasPermission(canViewSchedule)
                    ? navigate(routes.reservations.list({ slug }))
                    : navigate({ pathname: routes.calendar.list({ slug }), search: "tab=reservations" })
                }
                className="w-full"
              >
                {t("page.bookings.book-asset.success-modal.see-details")}
              </Button>
            </span>
            <Button styling="tertiary" onClick={() => navigate(routes.bookings.list({ slug }))}>
              {t("common.action.close")}
            </Button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          futureBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: futureBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
