import type { Size } from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";
import type { FixedSizeGridProps } from "react-window";
import { FixedSizeGrid as Grid } from "react-window";
import { twJoin } from "tailwind-merge";

import type { GroupIconType } from "./GroupIcons";
import { GROUP_ICONS, GroupIcon } from "./GroupIcons";

export interface GroupIconPickerProps {
  onChange: (icon: GroupIconType) => void;
  selectedIcon: GroupIconType | null;
}

interface ItemData {
  columnCount: number;
  selectedIcon: GroupIconType | null;
  onChange: (icon: GroupIconType) => void;
}

export function GroupIconPicker({ onChange, selectedIcon }: GroupIconPickerProps): React.ReactNode {
  return (
    <AutoSizer>
      {({ height, width }: Size) => {
        const itemSize = 48;
        const columnCount = Math.floor((width - itemSize / 2) / itemSize);
        const rowCount = Math.ceil(GROUP_ICONS.length / columnCount);
        const initialScrollTop = 0;

        return (
          <Grid<ItemData>
            height={height}
            width={width}
            columnWidth={itemSize}
            rowHeight={itemSize}
            columnCount={columnCount}
            rowCount={rowCount}
            itemData={{
              columnCount,
              onChange,
              selectedIcon,
            }}
            initialScrollTop={initialScrollTop}
          >
            {Cell}
          </Grid>
        );
      }}
    </AutoSizer>
  );
}

function Cell({ style, rowIndex, columnIndex, data }: React.ComponentProps<FixedSizeGridProps<ItemData>["children"]>) {
  const icon = GROUP_ICONS[rowIndex * data.columnCount + columnIndex];
  if (!icon) {
    return null;
  }

  const isSelected = data.selectedIcon === icon;

  return (
    <div className="inline-flex" style={{ ...style, pointerEvents: "auto" }}>
      <button
        className={twJoin(
          "flex size-11 items-center justify-center rounded-lg border hocus:border-aop-basic-blue hocus:outline-none",
          isSelected ? "border-aop-basic-blue bg-blue-lightest" : "border-grey-lighter",
        )}
        data-testid="icon-action-select"
        onClick={() => {
          data.onChange(icon);
        }}
      >
        <GroupIcon size={32} icon={icon} />
      </button>
    </div>
  );
}
