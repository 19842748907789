import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  CommunityGroupV2Dto,
  ConstraintListItemDto,
  LanguageDto,
  TranslateRequest,
  UserRoleCommunityGroupPermissionsDto,
} from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormGroupIconPicker } from "components/Form/FormGroupIconPicker";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { PreviewLayout } from "components/Layouts/PreviewLayout";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { Phone } from "components/Phone/Phone";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import { validateSize } from "helpers/file-size";
import type { FormTranslations } from "helpers/languages";
import { useCurrentTranslation } from "helpers/languages";
import type { FormPermissions } from "helpers/permissions";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { AppGroupDetailsView } from "modules/community-groups/components/AppGroupDetailsView";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { GroupAudienceSelector } from "../GroupAudienceSelector";
import type { GroupIconType } from "../GroupIcons";

export interface CreateOrEditLayoutProps {
  type: CommunityGroupV2Dto["type"];
  canToggleStatus: boolean;
  isMultiProjectGroup: boolean;
  multiProjectGroupType: "system" | "connection" | undefined;
  isEditMode: boolean;
  languages: LanguageDto[];
  translations: CreateOrEditTranslations;
  defaultValues: CreateOrEditFormFields;
  isSubmitting: boolean;
  onSubmit: (data: CreateOrEditFormFields) => void;
}

export function CreateOrEditLayout({
  type,
  isMultiProjectGroup,
  multiProjectGroupType,
  translations,
  defaultValues,
  isSubmitting,
  ...props
}: CreateOrEditLayoutProps): React.ReactNode {
  const [appPreviewOpen, appPreviewOpenHandlers] = useBool();

  const { t } = useTranslation();
  const user = useSessionUser();
  const formMethods = useForm<CreateOrEditFormFields>({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const api = useApi();
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });
  useKey("Escape", appPreviewOpenHandlers.setFalse, appPreviewOpen);

  const onTranslate = async (field: "name" | "description", languageId: LanguageDto["id"]) => {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await translate({
      languages: props.languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  };

  const onSaveAudience = ({
    audience,
    isRealEstateGroup,
    isAllResidentsAdded,
  }: {
    audience: ConstraintListItemDto[];
    isRealEstateGroup: boolean;
    isAllResidentsAdded: boolean;
  }) => {
    formMethods.setValue("audience", audience);
    formMethods.setValue("isRealEstate", isRealEstateGroup);
    formMethods.setValue("isAllResidentsAdded", isAllResidentsAdded);
  };

  return (
    <DocumentPaper theme="no-gaps" title={translations.title}>
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        {isMultiProjectGroup && (
          <Wizard
            id="help-category-wizard"
            actionsText={{
              finish: translations.actionFinish,
            }}
            isSubmitting={isSubmitting}
            onFinish={formMethods.handleSubmit(props.onSubmit)}
            strictOrder={!props.isEditMode}
          >
            <WizardStep
              id={1}
              canLeave={formMethods.trigger}
              hasFinish
              title={
                <>
                  <span>{t("component.wizard.step", { step: 1 })}</span>
                  <span>{translations.generalStep}</span>
                </>
              }
            >
              <PreviewLayout
                previewTitleText={translations.appPreviewTitle}
                preview={<Preview />}
                showPreviewButtonText={translations.appPreviewOpen}
              >
                <FormContent>
                  <FormField label={t("page.community-groups.create-or-edit.posting-level.status")}>
                    <div>
                      <FormCheckbox<CreateOrEditFormFields>
                        name="enabled"
                        label={t("page.community-groups.create-or-edit.posting-level.status.label")}
                        disabled={!props.canToggleStatus}
                      />
                    </div>
                  </FormField>
                  <FormField
                    label={t("page.platform-groups.form.posting-level.label")}
                    tooltip={t("page.platform-groups.form.posting-level.tooltip")}
                  >
                    <FormSelect<CreateOrEditFormFields, string>
                      name="postingLevel"
                      items={
                        multiProjectGroupType === "system"
                          ? ["project", "projectConnection", "projectAndProjectConnection"]
                          : ["projectConnection", "projectAndProjectConnection"]
                      }
                      emptyItem={t("page.community-groups.create-or-edit.posting-level.options.default")}
                      keySelector={(x) => x}
                      renderOption={(x) => {
                        switch (x) {
                          case "project":
                            return t("page.community-groups.create-or-edit.posting-level.options.project");
                          case "projectConnection":
                            return t("page.community-groups.create-or-edit.posting-level.options.connection");
                          case "projectAndProjectConnection":
                            return t("page.community-groups.create-or-edit.posting-level.options.both");
                        }
                      }}
                      rules={{
                        required: t("components.form.error.required", {
                          inputName: t("page.community-groups.create-or-edit.posting-level.label"),
                        }),
                      }}
                    />
                  </FormField>
                </FormContent>
              </PreviewLayout>
            </WizardStep>
            <WizardStep
              id={2}
              canLeave={formMethods.trigger}
              hasFinish
              title={
                <>
                  <span>{t("component.wizard.step", { step: 2 })}</span>
                  <span>{translations.permissionStep}</span>
                </>
              }
              isHidden={!user.isSuperAdmin}
            >
              <FormContent maxWidth="none">
                <Accordion allowMultipleExpanded allowZeroExpanded>
                  {Object.values(defaultValues.permissions).map(({ name, roleId }) => (
                    <React.Fragment key={roleId}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>{name}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <PermissionListLayout>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCommentPost}
                                name={`permissions.${roleId}.canCommentPost`}
                                label={translations.canComment}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCreateAnnouncement}
                                name={`permissions.${roleId}.canCreateAnnouncement`}
                                label={translations.canCreateAnnouncement}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCreatePost}
                                name={`permissions.${roleId}.canCreatePost`}
                                label={translations.canCreatePost}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId]?.canListPosts}
                                name={`permissions.${roleId}.canListPosts`}
                                label={translations.canListPosts}
                              />
                            </PermissionWrapper>
                          </PermissionListLayout>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </React.Fragment>
                  ))}
                </Accordion>
              </FormContent>
            </WizardStep>
          </Wizard>
        )}
        {!isMultiProjectGroup && (
          <Wizard
            id="help-category-wizard"
            actionsText={{
              finish: translations.actionFinish,
            }}
            isSubmitting={isSubmitting}
            onFinish={formMethods.handleSubmit(props.onSubmit)}
            strictOrder={!props.isEditMode}
          >
            <WizardStep
              id={1}
              canLeave={formMethods.trigger}
              hasFinish={props.isEditMode}
              title={
                <>
                  <span>{t("component.wizard.step", { step: 1 })}</span>
                  <span>{translations.generalStep}</span>
                </>
              }
            >
              <PreviewLayout
                previewTitleText={translations.appPreviewTitle}
                preview={<Preview />}
                showPreviewButtonText={translations.appPreviewOpen}
              >
                <FormContent>
                  {props.languages.map((lng) => {
                    const nameId = `name-${lng.id}`;
                    const descriptionId = `description-${lng.id}`;

                    return (
                      <React.Fragment key={lng.id}>
                        <FormField
                          htmlFor={nameId}
                          label={`${translations.fieldName} (${lng.poEditorCode})`}
                          required
                          actions={
                            <Button
                              styling="ghostPrimary"
                              onClick={() => onTranslate("name", lng.id)}
                              icon={<Icon name={iconGlobe02} />}
                            >
                              {t("model.interest-group.name.translate")}
                            </Button>
                          }
                        >
                          <FormInput<CreateOrEditFormFields>
                            className="max-w-lg"
                            data-testid="group-name"
                            autoComplete="off"
                            name={`nameTranslations.${lng.id}`}
                            id={nameId}
                            rules={{
                              validate: {
                                required: createRequiredStringRule(t, translations.fieldName as any),
                              },
                              maxLength: {
                                message: t("components.form.error.max-length", {
                                  length: NAME_MAX_LENGTH,
                                }),
                                value: NAME_MAX_LENGTH,
                              },
                            }}
                            placeholder={`${translations.fieldNamePlaceholder} ${lng.description}`}
                            disabled={isTranslating}
                          />
                        </FormField>
                        <FormField
                          htmlFor={descriptionId}
                          label={`${translations.fieldDescription} (${lng.poEditorCode})`}
                          required
                          actions={
                            <Button
                              styling="ghostPrimary"
                              onClick={() => onTranslate("description", lng.id)}
                              icon={<Icon name={iconGlobe02} />}
                            >
                              {t("model.interest-group.description.translate")}
                            </Button>
                          }
                        >
                          <FormTextArea<CreateOrEditFormFields>
                            className="max-w-lg"
                            data-testid="group-description"
                            autoComplete="off"
                            name={`descriptionTranslations.${lng.id}`}
                            id={descriptionId}
                            rules={{
                              validate: {
                                required: createRequiredStringRule(t, translations.fieldDescription as any),
                              },
                            }}
                            placeholder={`${translations.fieldDescriptionPlaceholder} ${lng.description}`}
                            disabled={isTranslating}
                          />
                        </FormField>
                      </React.Fragment>
                    );
                  })}
                  <FormField
                    htmlFor="icon"
                    label={translations.fieldIcon}
                    description={translations.fieldIconDescription}
                    required
                  >
                    <FormGroupIconPicker<CreateOrEditFormFields>
                      data-testid="group-icon"
                      id="icon"
                      name="icon"
                      placeholder={translations.fieldIconPlaceholder}
                      rules={{
                        required: t("components.form.error.required", {
                          inputName: translations.fieldIcon,
                        }),
                      }}
                    />
                  </FormField>
                  <FormField
                    htmlFor="imageFile"
                    label={translations.fieldImage}
                    description={translations.fieldImageDescription}
                  >
                    <FormImageInput<CreateOrEditFormFields, "image">
                      data-testid="group-image"
                      name="image"
                      id="imageFile"
                      rules={{
                        validate: {
                          size(image) {
                            if (image) {
                              return validateSize(t, image);
                            }
                          },
                        },
                      }}
                    />
                  </FormField>
                  <FormField
                    label={t("common.entity.audience")}
                    description={t("component.audience-section.description", {
                      audienceTarget: t("common.entity.event").toLowerCase(),
                    })}
                  >
                    <GroupAudienceSelector isEditMode={props.isEditMode} groupType={type} onSave={onSaveAudience} />
                  </FormField>
                </FormContent>
              </PreviewLayout>
            </WizardStep>
            <WizardStep
              id={2}
              canLeave={formMethods.trigger}
              hasFinish={props.isEditMode}
              title={
                <>
                  <span>{t("component.wizard.step", { step: 2 })}</span>
                  <span>{translations.permissionStep}</span>
                </>
              }
              isHidden={!user.isSuperAdmin}
            >
              <FormContent maxWidth="none">
                <Accordion allowMultipleExpanded allowZeroExpanded>
                  {Object.values(defaultValues.permissions).map(({ name, roleId }) => (
                    <React.Fragment key={roleId}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>{name}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <PermissionListLayout>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCommentPost}
                                name={`permissions.${roleId}.canCommentPost`}
                                label={translations.canComment}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCreateAnnouncement}
                                name={`permissions.${roleId}.canCreateAnnouncement`}
                                label={translations.canCreateAnnouncement}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId].canCreatePost}
                                name={`permissions.${roleId}.canCreatePost`}
                                label={translations.canCreatePost}
                              />
                            </PermissionWrapper>
                            <PermissionWrapper>
                              <FormCheckbox<CreateOrEditFormFields>
                                alignTop
                                memorise={defaultValues.permissions[roleId]?.canListPosts}
                                name={`permissions.${roleId}.canListPosts`}
                                label={translations.canListPosts}
                              />
                            </PermissionWrapper>
                          </PermissionListLayout>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </React.Fragment>
                  ))}
                </Accordion>
              </FormContent>
            </WizardStep>
          </Wizard>
        )}
      </Form>
    </DocumentPaper>
  );
}

export interface CreateOrEditFormFields {
  icon?: GroupIconType;
  nameTranslations: FormTranslations;
  descriptionTranslations: FormTranslations;
  audience: ConstraintListItemDto[];
  type: "interest" | "helpCategory" | "realEstate";
  image: FormImage[];
  permissions: FormPermissions<UserRoleCommunityGroupPermissionsDto>;
  isAllResidentsAdded: boolean;
  isRealEstate?: boolean;
  postingLevel?: "project" | "projectConnection" | "projectAndProjectConnection";
  enabled?: boolean;
}

interface CreateOrEditTranslations {
  title: string;
  actionFinish: string;
  generalStep: string;
  audienceStep: string;
  permissionStep: string;
  fieldName: string;
  fieldNamePlaceholder: string;
  fieldDescription: string;
  fieldDescriptionPlaceholder: string;
  fieldIcon: string;
  fieldIconDescription: string;
  fieldIconPlaceholder: string;
  fieldImage: string;
  fieldImageDescription: string;
  fieldImagePlaceholder: string;
  fieldAddressDescription: string;
  fieldBuildingDescription: string;
  fieldCompanyDescription: string;
  fieldFloorDescription: string;
  fieldAudienceDescription?: string;
  appPreviewTitle: string;
  appPreviewOpen: string;
  canComment: string;
  canCreateAnnouncement: string;
  canCreatePost: string;
  canListPosts: string;
}

function Preview() {
  const user = useSessionUser();
  const { nameTranslations, descriptionTranslations, icon, image } = useWatch<CreateOrEditFormFields>();
  const name = useCurrentTranslation(nameTranslations);
  const description = useCurrentTranslation(descriptionTranslations);

  return (
    <Phone>
      <AppGroupDetailsView
        name={name.slice(0, NAME_MAX_LENGTH) || ""}
        description={description}
        icon={icon!}
        memberCount={12}
        image={image?.[0]?.url ?? user.project.backgroundImage?.url ?? bgPlaceholder}
        user={user}
      />
    </Phone>
  );
}

const NAME_MAX_LENGTH = 40;
