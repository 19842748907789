import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { addDays, endOfMonth, format, startOfWeek } from "date-fns";
import { downloadFile } from "helpers/file-parse";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProject } from "hooks/Network/useProject";
import { getBookingsExportFilename } from "modules/bookings/helpers";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const { id: projectId, name: projectName } = useProject();
  const { id: assetId } = useParams<{ id: string }>();
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  const {
    data: assetDetails,
    isLoading: isLoadingAssetDetails,
    error: errorLoadingAssetDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsDetailsV1(assetId!),
    select: commonAPIDataSelector,
  });

  const now = new Date();
  const startDate = startOfWeek(now, { weekStartsOn: 1 });
  const [view, setView] = useState<"week" | "month">("week");
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const selectedEndDate = useMemo(
    () => (view === "week" ? addDays(selectedStartDate, 6) : endOfMonth(selectedStartDate)),
    [view, selectedStartDate],
  );

  function onChangeView(view: "week" | "month", startDate: Date) {
    setView(view);
    setSelectedStartDate(startDate);
  }

  const query = {
    Date: format(selectedStartDate, "yyyy-MM-dd"),
    Until: format(selectedEndDate, "yyyy-MM-dd"),
  };
  const {
    data: bookings = [],
    isLoading: isLoadingBookings,
    error: errorLoadingBookings,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_BOOKINGS(projectId, assetId!, query),
    queryFn: () => api.getBookableAssetsBookingsV1(assetId!, query),
    select: commonAPIDataSelector,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });

  const {
    data: assetStatistics,
    isFetching: isFetchingAssetStatistics,
    error: assetStatisticsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSET_STATISTICS(projectId, assetId!),
    queryFn: () => api.getBookableAssetsStatisticsV1(assetId!, { includeToday: true }),
    select: commonAPIDataSelector,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });

  const exportBookings = useMutation({
    mutationFn: (includeHistory: boolean) =>
      api.getBookableAssetsBookingsExportV1(assetId!, {
        includeHistory: includeHistory,
      }),
    onSuccess: async (data) => {
      const blob = await data.blob();
      downloadFile(blob, getBookingsExportFilename(projectName, assetDetails!.name));
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.bookings.asset-detail.export.error") });
    },
  });

  const error = errorLoadingAssetDetails || errorLoadingBookings || assetStatisticsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingAssetDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    assetDetails: assetDetails!,
    isLoadingBookings,
    bookings,
    isLoadingAssetStatistics: isFetchingAssetStatistics,
    assetStatistics,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    view,
    onChangeView,
    exportBookings: exportBookings.mutateAsync,
    isExporting: exportBookings.isPending,
  });
}
