import iconAlertCircle from "assets/icons/alert-circle.svg";
import { Icon } from "components/Icon/Icon";
import type { InputAutocompleteProps } from "components/InputAutocomplete/InputAutocomplete";
import { InputAutocomplete } from "components/InputAutocomplete/InputAutocomplete";
import { useBool } from "hooks/useBool";
import type { FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormInputAutocompleteProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<InputAutocompleteProps, "name" | "value"> {
  name: TName;
  rules?: RegisterOptions;
  onChange?: (value?: string) => void;
  className?: string;
  "data-testid"?: string;
}

export function FormInputAutocomplete<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  "data-testid": dataTestid,
  name,
  rules,
  ...props
}: FormInputAutocompleteProps<TFormValues, TName>): React.ReactNode {
  const [isFocused, isFocusHandlers] = useBool(false);
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<TFormValues>({ name, rules });

  const maxLengthValue = typeof rules?.maxLength === "object" ? rules.maxLength.value : undefined;
  const isError = error != null;
  const currentValueLength = `${field.value || ""}`.length || 0;

  return (
    <FormErrorWrapper
      name={name}
      className={props.className}
      data-testid={dataTestid}
      subtext={
        maxLengthValue != null &&
        isFocused && (
          <>
            <span className={maxLengthValue < currentValueLength ? "text-red-dark" : undefined}>
              {currentValueLength}
            </span>
            /{maxLengthValue}
          </>
        )
      }
    >
      <InputAutocomplete
        {...props}
        {...field}
        value={(field.value as any) || ""}
        aria-invalid={isError}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        onBlur={() => {
          field.onBlur();
          props.onBlur?.();
          isFocusHandlers.setFalse();
        }}
      />
      {isError && (
        <Icon name={iconAlertCircle} size={16} className="absolute right-2 top-2/4 -translate-y-2/4 text-red-dark" />
      )}
    </FormErrorWrapper>
  );
}
