import flipHorizontalIcon from "assets/icons/aop/flip-horizontal.svg";
import flipVerticalIcon from "assets/icons/aop/flip-horizontal-1.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { RangeInput } from "components/RangeInput/RangeInput";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { useEffect, useState } from "react";
import type { Area } from "react-easy-crop";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

import getCroppedImg from "./cropImage";

interface ImageCropperProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (img: File) => void;
  imgFile: File | null;
}

export function ImageCropper({ title, isOpen, onClose, onSave, imgFile }: ImageCropperProps): React.ReactNode {
  const { t } = useTranslation();

  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [flip, setFlip] = useState({ horizontal: false, vertical: false });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onSaveCrop = async () => {
    const newImg = await getCroppedImg(imgFile!, imgSrc!, croppedAreaPixels!, rotation, flip);
    if (!newImg) {
      return;
    }

    onSave(newImg);
    onClose();
  };

  useEffect(() => {
    if (imgFile) {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setRotation(0);
      setCroppedAreaPixels(null);
      setFlip({ horizontal: false, vertical: false });
      setImgSrc(URL.createObjectURL(imgFile));
    }
  }, [imgFile]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="flex min-w-80 flex-col gap-2 py-4 md:min-w-96">
        <Headline4 className="px-4">{title}</Headline4>
        <div className="relative h-52">
          <Cropper
            image={imgSrc}
            transform={[
              `translate(${crop.x}px, ${crop.y}px)`,
              `rotateZ(${rotation}deg)`,
              `rotateY(${flip.horizontal ? 180 : 0}deg)`,
              `rotateX(${flip.vertical ? 180 : 0}deg)`,
              `scale(${zoom})`,
            ].join(" ")}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            cropSize={{ width: 208, height: 208 }}
            cropShape="round"
            restrictPosition={false}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="flex gap-4 px-4">
          <div className="flex items-center gap-2">
            <label className="text-grey-darkest" htmlFor="zoom-input">
              <Subtitle2>{t("component.image-input.cropper.zoom")}</Subtitle2>
            </label>
            <RangeInput
              id="zoom-input"
              label={t("component.image-input.cropper.zoom")}
              min={0.1}
              max={5}
              step={0.1}
              value={zoom}
              onChange={setZoom}
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-grey-darkest" htmlFor="rotation-input">
              <Subtitle2>{t("component.image-input.cropper.rotation")}</Subtitle2>
            </label>
            <RangeInput
              id="rotation-input"
              label={t("component.image-input.cropper.rotation")}
              min={0}
              max={360}
              step={1}
              value={rotation}
              onChange={setRotation}
            />
          </div>
          <IconButton
            title={t("component.image-input.cropper.flip-horizontal")}
            onClick={() => {
              setFlip((prev) => ({ horizontal: !prev.horizontal, vertical: prev.vertical }));
              setRotation((prev) => 360 - prev);
            }}
          >
            {" "}
            <Icon name={flipHorizontalIcon} />
          </IconButton>
          <IconButton
            title={t("component.image-input.cropper.flip-vertical")}
            onClick={() => {
              setFlip((prev) => ({ horizontal: prev.horizontal, vertical: !prev.vertical }));
              setRotation((prev) => 360 - prev);
            }}
          >
            <Icon name={flipVerticalIcon} />
          </IconButton>
        </div>
        <div className="flex w-full flex-row-reverse items-center gap-2 px-4">
          <Button styling="secondary" onClick={onClose}>
            {t("common.action.cancel")}
          </Button>
          <Button data-testid="confirm-cropping-btn" styling="primary" onClick={onSaveCrop}>
            {t("common.action.save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
