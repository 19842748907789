import { LoadingIcon } from "components/Icons/Icons";
import { Subtitle2 } from "components/Text/Text";

export function NextButton({
  isLoading,
  onClick,
  children,
}: {
  isLoading?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}): React.ReactNode {
  return (
    <button
      className="relative rounded-br-lg rounded-tl-lg bg-aop-basic-blue p-4 text-white disabled:bg-aop-basic-blue-dark"
      type={onClick ? "button" : "submit"}
      disabled={isLoading}
      onClick={onClick}
    >
      <Subtitle2>
        {isLoading ? (
          <LoadingIcon className="absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2 text-white" />
        ) : null}
        <span className={isLoading ? "invisible" : undefined}>{children}</span>
      </Subtitle2>
    </button>
  );
}
