import iconCheckCircle from "assets/icons/check-circle.svg";
import iconLoading01 from "assets/icons/loading-01.svg";
import iconTrash01 from "assets/icons/trash-01.svg";
import iconXCircle from "assets/icons/x-circle.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { Input } from "components/Input/Input";
import { Select } from "components/Select/Select";
import { Subtitle2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import type { Column, Data } from "../helpers/parser";
import type { Validation } from "../helpers/validation";

interface Props<TColumns extends readonly Column<any>[]> {
  data: Data<TColumns>;
  columns: TColumns;
  dataState: Validation;
  onUpdateValue: (row: number, column: string, value: string) => void;
  onRemoveRow: (row: number) => void;
  disabled: boolean;
  "data-testid"?: string;
}

export function DataTable<T extends readonly Column<any>[]>({
  data,
  columns,
  dataState,
  onUpdateValue,
  onRemoveRow,
  disabled,
  "data-testid": dataTestId,
}: Props<T>): React.ReactNode {
  const { t } = useTranslation();
  const [editingColumn, setEditingColumn] = useState<{ row: number; column: string }>();

  return (
    <div
      className="grid w-full items-center gap-1"
      style={{
        gridTemplateColumns: `32px repeat(${columns.length}, minmax(min-content, auto)) 24px`,
      }}
      data-testid={dataTestId}
    >
      <div className="contents">
        <span />
        {columns.map((x) => (
          <Subtitle2 className="-ml-px -mt-px px-2 py-1" key={x.name}>
            {x.name}
          </Subtitle2>
        ))}
        <span />
      </div>
      {data.map((row, y) => (
        <div className="contents" key={row.id}>
          {row.uploadStatus === "NONE" ? (
            <span />
          ) : row.uploadStatus === "UPLOADING" ? (
            <Tooltip tooltip={t("page.user-bulk-upload.loading")}>
              <Icon name={iconLoading01} className="m-2 animate-spin self-center text-blue-dark" />
            </Tooltip>
          ) : row.uploadStatus === "SUCCESS" ? (
            <Tooltip tooltip={t("page.user-bulk-upload.success")}>
              <Icon name={iconCheckCircle} className="m-2 self-center text-green-dark" />
            </Tooltip>
          ) : (
            <Tooltip tooltip={t("page.user-bulk-upload.error")}>
              <Icon name={iconXCircle} className="m-2 self-center text-red-dark" />
            </Tooltip>
          )}

          {columns.map((c) => {
            const value = row[c.name as Exclude<keyof typeof row, "id" | "uploadStatus">];
            const warning = dataState.getWarnings(y, c.name);
            const invalid = dataState.getInvalidError(y, c.name);
            const missing = dataState.getMissingError(y, c.name);
            const isEditing = editingColumn?.row === y && editingColumn.column === c.name;

            const rowDisabled = row.uploadStatus === "SUCCESS" || disabled;

            return (
              <span className="relative" key={c.name}>
                {isEditing ? (
                  <div className="absolute z-10 m-auto -ml-1 -mt-1 w-[110%] min-w-40">
                    {c.rules && "oneOf" in c.rules ? (
                      <Select
                        autoFocus
                        items={c.rules.oneOf.values}
                        selected={value}
                        keySelector={(x) => x}
                        renderOption={(x) => (c.render ? c.render(x) : x)}
                        onChange={(value) => {
                          onUpdateValue(y, c.name, value);
                          setEditingColumn(undefined);
                        }}
                        onBlur={() => setEditingColumn(undefined)}
                      />
                    ) : (
                      <Input
                        autoFocus
                        defaultValue={value}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            onUpdateValue(y, c.name, event.currentTarget.value?.trim());
                            setEditingColumn(undefined);
                          }

                          if (event.key === "Escape") {
                            setEditingColumn(undefined);
                          }
                        }}
                        onBlur={(event) => {
                          onUpdateValue(y, c.name, event.currentTarget.value?.trim());
                          setEditingColumn(undefined);
                        }}
                      />
                    )}
                  </div>
                ) : null}
                <Tooltip tooltip={row.uploadStatus === "SUCCESS" ? undefined : invalid || missing || warning}>
                  <button
                    className={twJoin(
                      "-mt-px w-full cursor-pointer px-2 py-1 text-left ring-inset active:ring-2 active:ring-black disabled:pointer-events-none disabled:bg-grey-lightest/50 disabled:text-grey",
                      value ? "text-black" : "text-black/40",
                      row.uploadStatus === "SUCCESS"
                        ? ""
                        : invalid || missing
                          ? "bg-red-lighter ring-1 ring-red-darker hocus:bg-red-lighter/50"
                          : warning
                            ? "bg-yellow-lighter ring-1 ring-yellow-darker hocus:bg-yellow-lighter/50"
                            : "hocus:bg-grey-lightest/50",
                    )}
                    disabled={rowDisabled}
                    onClick={() => setEditingColumn({ column: c.name, row: y })}
                  >
                    {(c.render && value ? c.render(value) : value) || "-"}
                  </button>
                </Tooltip>
              </span>
            );
          })}

          <span className="flex">
            <IconButton
              title={t("page.user-bulk-upload.delete-row")}
              disabled={disabled}
              onClick={() => onRemoveRow(y)}
              styling="danger"
              size="sm"
            >
              <Icon name={iconTrash01} size={16} />
            </IconButton>
          </span>
        </div>
      ))}
    </div>
  );
}
