import type { ChatReplyDto } from "api/types";
import { Capture2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface SystemChatMessageProps {
  chatMessage: ChatReplyDto;
  chatName?: string;
  className?: string;
}

export function SystemChatMessage({ chatMessage, chatName, className }: SystemChatMessageProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  function getSystemMessage(): string {
    let systemMessage = "";
    switch (chatMessage.replyType) {
      case "systemCreation":
        systemMessage =
          chatMessage.author?.id === sessionUser.id
            ? t("page.chat-detail.chat-created.self", { group: chatName })
            : t("page.chat-detail.chat-created.other.not-affected", {
                author: chatMessage.author?.fullName,
                group: chatName,
              });
        break;
      case "systemUserLeft":
        systemMessage =
          chatMessage.author?.id === sessionUser.id
            ? t("page.chat-detail.chat-user-left.self")
            : t("page.chat-detail.chat-user-left.other", { author: chatMessage.author?.fullName });
        break;
      case "systemUserAdded":
        systemMessage = chatMessage.users?.map((x) => x.id).includes(sessionUser.id)
          ? chatMessage.users.length > 1
            ? t("page.chat-detail.chat-user-added.you_and_others", {
                author: chatMessage.author?.fullName,
                count: chatMessage.users.length - 1,
              })
            : t("page.chat-detail.chat-user-added.you", {
                author: chatMessage.author?.fullName,
              })
          : chatMessage.users && chatMessage.users.length > 1
            ? t("page.chat-detail.chat-user-added.user_and_others", {
                author: chatMessage.author?.fullName,
                user: chatMessage.users[0].fullName,
                count: chatMessage.users.length - 1,
              })
            : t("page.chat-detail.chat-user-added.user", {
                author: chatMessage.author?.fullName,
                user: chatMessage.users?.[0].fullName,
              });
        break;
      case "systemUserRemoved":
        systemMessage =
          chatMessage.author?.id === sessionUser.id
            ? t("page.chat-detail.chat-user-removed.self")
            : t("page.chat-detail.chat-user-removed.other", { author: chatMessage.author?.fullName });
        break;
      case "systemGroupNameChanged":
        systemMessage =
          chatMessage.author?.id === sessionUser.id
            ? t("page.chat-detail.chat-name-changed.self")
            : t("page.chat-detail.chat-name-changed.other", { author: chatMessage.author?.fullName });
        break;
      case "systemGroupImageChanged":
        systemMessage =
          chatMessage.author?.id === sessionUser.id
            ? t("page.chat-detail.chat-image-changed.self")
            : t("page.chat-detail.chat-image-changed.other", { author: chatMessage.author?.fullName });
        break;
    }

    return systemMessage;
  }

  return <Capture2 className={twJoin("w-fit", className)}>{getSystemMessage()}</Capture2>;
}
