import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EventDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import type { LayoutFormValues, LayoutProps } from "./Layout";
import { getDefaultFormValues, useCreateEvent, useEditEvent } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export interface EventObserver {
  type: "project" | "projectConnection";
  id: string;
  name: string;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const { id: eventId } = useParams<{ id: string }>();
  const { state } = useLocation();
  const { id: eventIdToDuplicate } = useMemo(
    () => (state && typeof state === "object" && "id" in state ? (state as { id: EventDto["id"] }) : { id: undefined }),
    [state],
  );
  const isEditMode = eventId != null;
  const api = useApi();
  const { create: createEvent, isSubmitting: isSubmittingCreate } = useCreateEvent();
  const { edit: editEvent, isSubmitting: isSubmittingEdit } = useEditEvent(eventId!);
  const {
    data: eventCategories = [],
    isFetching: eventCategoriesIsFetching,
    error: eventCategoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_CATEGORIES(projectId),
    queryFn: () => api.getEventsCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const {
    data: eventDetails,
    isFetching: eventDetailsIsFetching,
    error: eventDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, (eventId || eventIdToDuplicate)!),
    queryFn: () => api.getEventsDetailsV1((eventId || eventIdToDuplicate)!),
    enabled: eventId != null || eventIdToDuplicate != null,
    select: commonAPIDataSelector,
  });
  function onSubmit(formValues: LayoutFormValues) {
    if (eventId == null) {
      createEvent(formValues);
    } else {
      editEvent(formValues);
    }
  }

  const showAnnouncementModal = eventIdToDuplicate != null || eventId == null;
  const error = eventDetailsError || eventCategoriesError || languagesError;
  const isDataLoading = eventCategoriesIsFetching || languagesIsFetching || eventDetailsIsFetching;

  if (error) {
    return <ErrorPage error={error} />;
  }

  // A list of projects or project connections that join this asset
  const eventObservers: EventObserver[] = [
    { type: "project", id: sessionUser.project.id, name: sessionUser.project.name },
    ...sessionUser.connections.map((x) => ({
      type: "projectConnection" as const,
      id: x.id,
      name: x.name,
    })),
  ];

  return (
    <>
      {isDataLoading ? (
        <FullSizeLoader withPadding />
      ) : (
        props.children({
          isEditMode,
          isResident: !sessionUser.isAdmin,
          defaultFormValues: getDefaultFormValues(
            eventIdToDuplicate != undefined,
            languages,
            eventCategories,
            eventDetails,
            eventObservers,
          ),
          eventObservers,
          languages,
          isSubmitting: isSubmittingEdit || isSubmittingCreate,
          onSubmit,
          showAnnouncementModal,
          eventCategories: sessionUser.isAdmin
            ? eventCategories
            : eventCategories.filter((x) => x.id !== "maintenance"),
          organizer: eventDetails?.organizer || sessionUser,
          isPublished: eventDetails?.publishedAt != null,
        })
      )}
    </>
  );
}
