import iconArrowLeft from "assets/icons/arrow-left.svg";
import iconArrowRight from "assets/icons/arrow-right.svg";
import { Icon } from "components/Icon/Icon";
import { Subtitle2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface PaginationProps {
  count: number;
  currentIndex: number;
  onChange: (newIndex: number) => void;
  boundaryCount?: number;
  siblingsCount?: number;
}

type View = "simple" | "truncatedMiddle" | "truncatedStart" | "truncatedEnd" | "truncatedBoth";

export function Pagination(props: PaginationProps): React.ReactNode {
  const { onChange, currentIndex, count, boundaryCount = 2, siblingsCount = 1 } = props;
  const { t } = useTranslation();
  const pageIndexes = useMemo(() => {
    return Array.from({ length: count }, (_, i) => i);
  }, [count]);

  const view = getView(currentIndex, count, boundaryCount, siblingsCount);

  return (
    <nav className="flex items-center justify-between px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="relative mt-3 inline-flex items-center rounded-2px border-transparent py-1 pr-1 text-grey-dark hover:bg-grey-lightest focus:outline-none focus-visible:ring-2 focus-visible:ring-grey-darkest active:text-blue disabled:pointer-events-none disabled:text-grey-lighter"
          disabled={currentIndex === 0}
          onClick={() => onChange(currentIndex - 1)}
        >
          <Icon name={iconArrowLeft} size={24} className="mr-3" aria-hidden="true" />
          <Subtitle2>{t("common.action.previous")}</Subtitle2>
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {view === "simple"
          ? pageIndexes.map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))
          : null}
        {view === "truncatedMiddle" ? (
          <>
            {pageIndexes.slice(0, boundaryCount).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-grey-dark">...</span>
            {pageIndexes.slice(boundaryCount * -1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
          </>
        ) : null}
        {view === "truncatedEnd" ? (
          <>
            {pageIndexes.slice(0, boundaryCount + siblingsCount * 2 + 1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-grey-dark">...</span>
            {pageIndexes.slice(-1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
          </>
        ) : null}
        {view === "truncatedBoth" ? (
          <>
            {pageIndexes.slice(0, boundaryCount - 1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-grey-dark">...</span>
            {pageIndexes.slice(currentIndex - siblingsCount, currentIndex + siblingsCount + 1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-grey-dark">...</span>
            {pageIndexes.slice(boundaryCount * -1 + 1).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
          </>
        ) : null}
        {view === "truncatedStart" ? (
          <>
            {pageIndexes.slice(0, boundaryCount).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-grey-dark">...</span>
            {pageIndexes.slice(boundaryCount * -1 - (siblingsCount * 2 + 1)).map((pageIndex) => (
              <PageLink
                key={pageIndex}
                onClick={() => onChange(pageIndex)}
                pageIndex={pageIndex}
                isCurrent={currentIndex === pageIndex}
              />
            ))}
          </>
        ) : null}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="relative mt-3 inline-flex items-center rounded-2px border-transparent py-1 pl-1 text-grey-dark hover:bg-grey-lightest focus:outline-none focus-visible:ring-2 focus-visible:ring-grey-darkest active:text-blue disabled:pointer-events-none disabled:text-grey-lighter"
          disabled={currentIndex === props.count - 1}
          onClick={() => onChange(currentIndex + 1)}
        >
          <Subtitle2>{t("common.action.next")}</Subtitle2>
          <Icon name={iconArrowRight} size={24} className="ml-3" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}

function PageLink(props: { pageIndex: number; isCurrent: boolean; onClick: () => void }) {
  return (
    <button
      className={twResolve(
        "relative mt-2 inline-flex items-center border-t-2 border-transparent px-4 py-2 text-grey-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-grey-darkest active:text-blue disabled:pointer-events-none disabled:text-grey-lighter",
        props.isCurrent
          ? "text-aop-basic-blue before:absolute before:inset-x-0 before:-top-2 before:block before:h-0.5 before:w-full before:bg-aop-basic-blue before:content-['']"
          : "hover:bg-grey-lightest",
      )}
      onClick={props.onClick}
      key={props.pageIndex}
    >
      <Subtitle2>{props.pageIndex + 1}</Subtitle2>
    </button>
  );
}

function getView(currentIndex: number, count: number, boundaryCount: number, siblingsCount: number): View {
  const baseNumber = 4;
  const boundaryStartEdgeIndex = boundaryCount - 1;
  const boundaryEndEdgeIndex = count - boundaryCount;
  let type: View = "simple";
  const isSimple = count <= baseNumber + boundaryCount;
  const isTruncatedEnd =
    !isSimple && currentIndex >= boundaryStartEdgeIndex && currentIndex <= boundaryStartEdgeIndex + siblingsCount * 2;
  const isTruncatedStart =
    !isSimple && currentIndex <= boundaryEndEdgeIndex && currentIndex >= boundaryEndEdgeIndex - siblingsCount * 2;
  const isTruncatedBoth = !isSimple && currentIndex > boundaryStartEdgeIndex && !isTruncatedEnd && !isTruncatedStart;
  const isTruncatedMiddle = !isSimple && (currentIndex < boundaryStartEdgeIndex || currentIndex > boundaryEndEdgeIndex);
  if (isSimple) {
    return type;
  }
  if (isTruncatedEnd) {
    type = "truncatedEnd";
  }
  if (isTruncatedStart) {
    type = "truncatedStart";
  }
  if (isTruncatedBoth) {
    type = "truncatedBoth";
  }
  if (isTruncatedMiddle) {
    type = "truncatedMiddle";
  }

  return type;
}
