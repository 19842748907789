import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconDownload01 from "assets/icons/download-01.svg";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { downloadFile } from "helpers/file-parse";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProject } from "hooks/Network/useProject";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookableAssetsGridView } from "modules/bookings/components/BookableAssetsGridView";
import { getBookingsExportFilename } from "modules/bookings/helpers";
import { canManageAnyBookableAsset, canViewSchedule } from "modules/bookings/permissions";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { ApiQueryParams } from "types/api-types";

import { ExportBookingsModal } from "./components/ExportBookingsModal";

const AMOUNT_ITEMS_TO_LOAD = 12;

export function Layout(): React.ReactNode {
  const slug = useSlug();

  const { id: projectId, name: projectName } = useProject();
  const api = useApi();
  const hasPermission = usePermission();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const [isExportAllBookingsModalOpen, exportAllBookingsModalHandlers] = useBool(false);

  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assetsData,
    isPending: isPendingAssets,
    error: assetsError,
    hasNextPage: hasMoreAssets,
    fetchNextPage: fetchMoreAssets,
    isFetchingNextPage: isFetchingMoreAssets,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsV1({
          ...assetsQuery,
          Offset: pageParam * AMOUNT_ITEMS_TO_LOAD,
          Limit: AMOUNT_ITEMS_TO_LOAD,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const { mutateAsync: exportAllBookings, isPending: isPendingExportAllBookings } = useMutation({
    mutationFn: (includeHistory: boolean) =>
      api.getBookableAssetsBookingsProjectExportV1({
        includeHistory: includeHistory,
      }),
    onSuccess: async (data) => {
      const blob = await data.blob();
      downloadFile(blob, getBookingsExportFilename(projectName));
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.bookings.list-assets.export.error") });
    },
  });

  const assets = useMemo(() => assetsData?.pages.flatMap((x) => x.items ?? []) ?? [], [assetsData]);

  if (assetsError) {
    return <ErrorPage error={assetsError} />;
  }

  if (isPendingAssets) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.list-assets.title")}
      subTitle={t("page.bookings.list-assets.subtitle")}
      actions={
        hasPermission(canManageAnyBookableAsset) ? (
          <>
            <Button
              icon={<Icon name={iconDownload01} />}
              styling="secondary"
              onClick={exportAllBookingsModalHandlers.setTrue}
              isLoading={isPendingExportAllBookings}
            >
              <span className="flex items-center gap-2">{t("page.bookings.list-assets.actions.export")}</span>
            </Button>
            <Button type="link" href={routes.bookings.createAsset({ slug })} styling="primary">
              {t("page.bookings.list-assets.actions.new")}
            </Button>
          </>
        ) : (
          !hasPermission(canViewSchedule) && (
            <Button type="link" href={`${routes.calendar.list({ slug })}?tab=reservations`} styling="primary">
              {t("page.bookings.list-assets.actions.see-reservations")}
            </Button>
          )
        )
      }
    >
      <BookableAssetsGridView
        onLoadMore={fetchMoreAssets}
        isLoading={isFetchingMoreAssets}
        hasMore={hasMoreAssets}
        {...{ assets }}
      />
      <Modal
        isOpen={isExportAllBookingsModalOpen}
        onRequestClose={exportAllBookingsModalHandlers.setFalse}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
      >
        <ExportBookingsModal onExport={exportAllBookings} onClose={exportAllBookingsModalHandlers.setFalse} />
      </Modal>
    </DocumentPaper>
  );
}
