import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import {
  type ConstraintListItemDto,
  type LanguageDto,
  type PartnerCategoriesDto,
  type ProjectConnectionDetailsDto,
  type ProjectConnectionDto,
  type TranslateRequest,
  type UserRoleServicePartnerPagePermissionsDto,
} from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { AudienceSelector } from "components/AudienceSelector/AudienceSelector";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormRadio, FormRadioGroup } from "components/Form/FormRadio";
import { FormRichTextEditor } from "components/Form/FormRichTextEditor";
import { FormSelect } from "components/Form/FormSelect";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { PreviewLayout } from "components/Layouts/PreviewLayout";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { AppServicePartnerDetailsView } from "components/Phone/AppServicePartnerDetailsView";
import { Phone } from "components/Phone/Phone";
import type { RichTextEditorRef } from "components/RichTextEditor/RichTextEditor";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import { validateSize } from "helpers/file-size";
import type { FormTranslations } from "helpers/languages";
import { useCurrentTranslation } from "helpers/languages";
import type { FormPermissions } from "helpers/permissions";
import { createRequiredStringRule } from "helpers/rules";
import { isValidEmail, isValidPhoneNumber } from "helpers/validation";
import { useSessionUser } from "hooks/Network/useSessionUser";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ServicePartnerType = "general" | "iqbi" | "myPup";

export interface LayoutProps {
  isEditMode: boolean;
  allowedTypes: ServicePartnerType[];
  languages: LanguageDto[];
  categories: PartnerCategoriesDto[];
  defaultValues: CreateOrEditFormFields;
  isSubmitting: boolean;
  connections: ProjectConnectionDetailsDto[];
  onSubmit: (data: CreateOrEditFormFields) => void;
}

const MAX_LENGTH = 128;

export function Layout({
  defaultValues,
  isSubmitting,
  connections,
  allowedTypes,
  ...props
}: LayoutProps): React.ReactNode {
  const editorRefs = React.useRef<Record<string, RichTextEditorRef>>({});

  const api = useApi();
  const { t } = useTranslation();
  const user = useSessionUser();
  const formMethods = useForm<CreateOrEditFormFields>({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  const connection = useWatch<CreateOrEditFormFields, "connection">({
    control: formMethods.control,
    name: "connection",
  });
  const audience = useWatch<CreateOrEditFormFields, "audience">({ control: formMethods.control, name: "audience" });

  const canEditAudience = user.isAdmin;
  const canLeave = () => formMethods.trigger();

  async function onTranslate(field: "title" | "subtitle" | "description", languageId: LanguageDto["id"]) {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await translate({
      languages: props.languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
        if (field === "description") {
          editorRefs.current[translation.languages].setContent(translation.text);
        }
      }
    }
  }

  const showIntegrationOptions = props.isEditMode
    ? defaultValues.type !== "general"
    : allowedTypes.length > 1 || !allowedTypes.includes("general");

  return (
    <DocumentPaper
      theme="no-gaps"
      title={
        props.isEditMode
          ? t("page.service.create-or-edit.edit-mode.title")
          : t("page.service.create-or-edit.create-mode.title")
      }
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        <Wizard
          id="service-create-wizard"
          actionsText={{
            finish: props.isEditMode
              ? t("component.wizard.action.edit.finish")
              : t("component.wizard.action.create.finish"),
          }}
          isSubmitting={isSubmitting}
          onFinish={formMethods.handleSubmit(props.onSubmit)}
          strictOrder={!props.isEditMode}
        >
          <WizardStep
            id={1}
            canLeave={canLeave}
            hasFinish={props.isEditMode}
            title={
              <>
                <span>{t("component.wizard.step", { step: 1 })}</span>
                <span>{t("component.wizard.step-general")}</span>
              </>
            }
          >
            <PreviewLayout
              preview={<Preview />}
              showPreviewButtonText={t("page.service.create-or-edit.app-preview.open")}
              previewTitleText={t("page.service.create-or-edit.app-preview.title")}
              previewWarning={t("page.service.create-or-edit.app-preview.warning")}
            >
              <FormContent maxWidth="xl">
                {showIntegrationOptions && (
                  <FormField label={t("model.service.type")}>
                    <FormRadioGroup>
                      {(defaultValues.type === "general" || allowedTypes.includes("general")) && (
                        <FormRadio<CreateOrEditFormFields>
                          name="type"
                          value="general"
                          displayValue={t("model.service.type.general")}
                          rules={{
                            required: {
                              message: t("components.form.error.required", {
                                inputName: t("model.service.type"),
                              }),
                              value: true,
                            },
                          }}
                          disabled={props.isEditMode}
                        />
                      )}
                      {(defaultValues.type === "iqbi" || allowedTypes.includes("iqbi")) && (
                        <FormRadio<CreateOrEditFormFields>
                          name="type"
                          value="iqbi"
                          displayValue={t("model.service.type.iqbi")}
                          rules={{
                            required: {
                              message: t("components.form.error.required", {
                                inputName: t("model.service.type"),
                              }),
                              value: true,
                            },
                          }}
                          disabled={props.isEditMode}
                        />
                      )}
                      {(defaultValues.type === "myPup" || allowedTypes.includes("myPup")) && (
                        <FormRadio<CreateOrEditFormFields>
                          name="type"
                          value="myPup"
                          displayValue={t("model.service.type.mypup")}
                          rules={{
                            required: {
                              message: t("components.form.error.required", {
                                inputName: t("model.service.type"),
                              }),
                              value: true,
                            },
                          }}
                          disabled={props.isEditMode}
                        />
                      )}
                    </FormRadioGroup>
                  </FormField>
                )}
                {props.languages.map((lng) => {
                  const nameId = `titleTranslations-${lng.id}`;
                  const sloganId = `subtitleTranslations-${lng.id}`;
                  const descriptionId = `descriptionTranslations-${lng.id}`;

                  return (
                    <React.Fragment key={lng.id}>
                      <FormField
                        htmlFor={nameId}
                        label={`${t("model.service.title")} (${lng.poEditorCode})`}
                        required
                        actions={
                          <Button
                            styling="ghostPrimary"
                            onClick={() => onTranslate("title", lng.id)}
                            icon={<Icon name={iconGlobe02} size={16} />}
                          >
                            {t("model.service.title.translate")}
                          </Button>
                        }
                      >
                        <FormInput<CreateOrEditFormFields>
                          className="max-w-xl"
                          id={nameId}
                          name={`titleTranslations.${lng.id}`}
                          placeholder={`${t("model.service.title.placeholder")} (${lng.description})`}
                          data-testid="service-name"
                          rules={{
                            maxLength: {
                              message: t("components.form.error.max-length", {
                                length: MAX_LENGTH,
                              }),
                              value: MAX_LENGTH,
                            },
                            validate: {
                              required: createRequiredStringRule(t, "model.service.title"),
                            },
                          }}
                          disabled={isTranslating}
                        />
                      </FormField>
                      <FormField
                        htmlFor={sloganId}
                        label={`${t("model.service.subtitle")} (${lng.poEditorCode})`}
                        description={t("model.service.subtitle.description")}
                        required
                        actions={
                          <Button
                            styling="ghostPrimary"
                            onClick={() => onTranslate("subtitle", lng.id)}
                            icon={<Icon name={iconGlobe02} size={16} />}
                          >
                            {t("model.service.subtitle.translate")}
                          </Button>
                        }
                      >
                        <FormInput<CreateOrEditFormFields>
                          className="max-w-xl"
                          id={sloganId}
                          name={`subtitleTranslations.${lng.id}`}
                          placeholder={`${t("model.service.subtitle.placeholder")} (${lng.description})`}
                          data-testid="service-slogan"
                          rules={{
                            maxLength: {
                              message: t("components.form.error.max-length", {
                                length: MAX_LENGTH,
                              }),
                              value: MAX_LENGTH,
                            },
                            validate: {
                              required: createRequiredStringRule(t, "model.service.subtitle"),
                            },
                          }}
                          disabled={isTranslating}
                        />
                      </FormField>
                      <FormField
                        htmlFor={descriptionId}
                        label={`${t("model.service.description")} (${lng.poEditorCode})`}
                        required
                        actions={
                          <Button
                            styling="ghostPrimary"
                            onClick={() => onTranslate("description", lng.id)}
                            icon={<Icon name={iconGlobe02} size={16} />}
                          >
                            {t("model.service.description.translate")}
                          </Button>
                        }
                      >
                        <FormRichTextEditor<CreateOrEditFormFields>
                          editorRef={(ref) => (editorRefs.current[lng.id] = ref!)}
                          name={`descriptionTranslations.${lng.id}`}
                          placeholder={`${t("model.service.description.placeholder")} (${lng.description})`}
                          data-testid="service-description"
                          rules={{
                            required: {
                              message: t("components.form.error.required", {
                                inputName: t("model.service.description"),
                              }),
                              value: true,
                            },
                          }}
                          disabled={isTranslating}
                        />
                      </FormField>
                    </React.Fragment>
                  );
                })}
                {defaultValues.type === "iqbi" && (
                  <FormField htmlFor="type" label={t("model.service.type")} required>
                    <div className="flex flex-row flex-wrap items-center gap-4">
                      <FormRadio<CreateOrEditFormFields, "type">
                        name="type"
                        value="general"
                        displayValue={t("model.service.type.general")}
                        disabled
                      />
                      <FormRadio<CreateOrEditFormFields, "type">
                        name="type"
                        value="iqbi"
                        displayValue={t("model.service.type.iqbi")}
                        disabled
                      />
                    </div>
                  </FormField>
                )}
                <FormField
                  htmlFor="category"
                  label={t("model.service.category")}
                  description={t("model.service.category.description")}
                  required
                >
                  <FormSelect<CreateOrEditFormFields, PartnerCategoriesDto>
                    id="category"
                    name="category"
                    placeholder={t("model.service.category.placeholder")}
                    items={props.categories}
                    keySelector={({ id }) => id}
                    renderOption={(tab) => tab.name}
                    data-testid="service-category"
                    rules={{
                      required: {
                        message: t("components.form.error.required", {
                          inputName: t("model.service.category"),
                        }),
                        value: true,
                      },
                    }}
                  />
                </FormField>
                <FormField
                  htmlFor="email"
                  label={t("model.service.email")}
                  description={t("model.service.email.description")}
                >
                  <FormInput<CreateOrEditFormFields, "email">
                    id="email"
                    name="email"
                    type="email"
                    inputMode="email"
                    data-testid="service-email"
                    rules={{
                      validate: {
                        isValid(value) {
                          value = value?.trim();

                          if (!value) {
                            return;
                          }

                          return isValidEmail(value) ? undefined : t("components.form.error.invalid-email-address");
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField
                  htmlFor="phoneNumber"
                  label={t("model.service.phone-number")}
                  description={t("model.service.phone-number.description")}
                >
                  <FormInput<CreateOrEditFormFields, "phoneNumber">
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    inputMode="tel"
                    data-testid="service-phone"
                    rules={{
                      validate: {
                        isValid(value) {
                          value = value?.trim();

                          if (!value) {
                            return;
                          }

                          return isValidPhoneNumber(value)
                            ? undefined
                            : t("components.form.error.invalid-phone-number");
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField
                  htmlFor="profileImage"
                  label={t("model.service.profile-image")}
                  description={t("model.service.profile-image.description")}
                  required
                >
                  <FormImageInput<CreateOrEditFormFields, "profileImage">
                    name="profileImage"
                    id="profileImage"
                    cropper
                    data-testid="service-profile-image"
                    rules={{
                      required: t("components.form.error.required", {
                        inputName: t("model.service.profile-image"),
                      }),
                      validate: {
                        size(image) {
                          if (image) {
                            return validateSize(t, image);
                          }
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField
                  htmlFor="headerImage"
                  label={t("model.service.header-image")}
                  description={t("model.service.header-image.description")}
                >
                  <FormImageInput<CreateOrEditFormFields, "headerImage">
                    name="headerImage"
                    id="headerImage"
                    data-testid="service-header-image"
                    rules={{
                      validate: {
                        size(image) {
                          if (image) {
                            return validateSize(t, image);
                          }
                        },
                      },
                    }}
                  />
                </FormField>
                {canEditAudience && (
                  <div className="flex w-full flex-col gap-2">
                    <FormField
                      label={t("common.entity.audience")}
                      description={t("component.audience-section.description", {
                        audienceTarget: t("common.entity.service").toLowerCase(),
                      })}
                    >
                      {connections.length > 0 && (
                        <FormField label={t("page.service.create-or-edit.connection.label")} htmlFor="connection">
                          <FormSelect<CreateOrEditFormFields, ProjectConnectionDto>
                            id="connection"
                            name="connection"
                            items={connections}
                            keySelector={(x) => x.id}
                            renderOption={(x) => x.name}
                            emptyItem={t("page.service.create-or-edit.connection.none")}
                          />
                        </FormField>
                      )}
                    </FormField>
                    {!connection && (
                      <AudienceSelector
                        defaultAudience={audience}
                        isEditMode={props.isEditMode}
                        onSaveAudience={(audience) => formMethods.setValue("audience", audience)}
                      />
                    )}
                  </div>
                )}
              </FormContent>
            </PreviewLayout>
          </WizardStep>
          <WizardStep
            id={2}
            hasFinish={props.isEditMode}
            title={
              <>
                <span>{t("component.wizard.step", { step: 2 })}</span>
                <span>{t("component.wizard.step-permissions")}</span>
              </>
            }
            isHidden={!user.isAdmin}
            {...{ canLeave }}
          >
            <FormContent maxWidth="none">
              {/* While we only support super admins you shouldn't be able to set permissions for connections. When supporting admins we should remove this */}
              {connection ? (
                <p className="py-6 pl-2 text-grey-darker">
                  {t("page.service.create-or-edit.permissions.disabled-for-connection")}
                </p>
              ) : null}
              {/* need to keep it as hidden but rendered so we fill the form data */}
              <div className={connection ? "hidden" : undefined}>
                <Accordion allowMultipleExpanded allowZeroExpanded>
                  {Object.entries(defaultValues.permissions).map(([roleId, permission]) => (
                    <AccordionItem key={permission.roleId}>
                      <AccordionItemHeading tabIndex={-1}>
                        <AccordionItemButton>{permission.name}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <PermissionListLayout>
                          <PermissionWrapper>
                            <FormCheckbox<CreateOrEditFormFields>
                              alignTop
                              disabled={!!connection}
                              memorise={defaultValues.permissions[roleId]?.canManageContent || false}
                              name={`permissions.${roleId}.canManageContent`}
                              label={t("model.permission.service-page.can-manage-content")}
                            />
                          </PermissionWrapper>
                        </PermissionListLayout>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}

export interface CreateOrEditFormFields {
  titleTranslations: FormTranslations;
  subtitleTranslations: FormTranslations;
  descriptionTranslations: FormTranslations;
  audience: ConstraintListItemDto[];
  connection?: ProjectConnectionDto;
  permissions: FormPermissions<UserRoleServicePartnerPagePermissionsDto>;
  category?: PartnerCategoriesDto;
  email?: string;
  phoneNumber?: string;
  profileImage?: FormImage[];
  headerImage?: FormImage[];
  type: ServicePartnerType;
}

function Preview() {
  const user = useSessionUser();
  const {
    email,
    phoneNumber,
    titleTranslations,
    subtitleTranslations,
    descriptionTranslations,
    profileImage,
    headerImage,
  } = useWatch<CreateOrEditFormFields>();
  const title = useCurrentTranslation(titleTranslations);
  const subtitle = useCurrentTranslation(subtitleTranslations);
  const description = useCurrentTranslation(descriptionTranslations);

  return (
    <Phone>
      <AppServicePartnerDetailsView
        name={title}
        slogan={subtitle}
        description={description}
        profileImage={profileImage?.[0]?.url}
        headerImage={headerImage?.[0]?.url ?? user.project.backgroundImage?.url ?? bgPlaceholder}
        email={email}
        phoneNumber={phoneNumber}
        projectTintColor={user.project?.styling.tintColor}
      />
    </Phone>
  );
}
