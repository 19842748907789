import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { BookableAssetDto } from "api/types";
import { CardGrid } from "components/CardGrid/CardGrid";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { LoadingIcon } from "components/Icons/Icons";
import { Subtitle2 } from "components/Text/Text";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import { BookableAssetCard } from "../pages/ListAssets/components/BookableAssetCard";

interface BookableAssetsGridViewProps {
  assets: BookableAssetDto[];
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
}

export function BookableAssetsGridView({
  assets,
  hasMore,
  isLoading,
  onLoadMore,
}: BookableAssetsGridViewProps): React.ReactNode {
  const [assetBookings, setAssetBookings] = useState<number>(0);

  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const api = useApi();
  const queryClient = useQueryClient();

  const mutationAssetStatistics = useMutation({
    mutationFn: (id: string) => api.getBookableAssetsStatisticsV1(id).then((x) => x.data),
  });
  const mutationDeleteAsset = useMutation({
    mutationFn: (id: string) => api.deleteBookableAssetsByIdV1(id).then((x) => x.data),
    onSuccess: (_, id) => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.delete-asset.notification.success"),
      });

      queryClient.removeQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_DETAILS(projectId, id) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSETS(projectId) });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.delete-asset.notification.error"),
      }),
  });

  const onOpenDeleteModal = useCallback(
    async (id: string) => {
      const { futureBookingsCount } = await mutationAssetStatistics.mutateAsync(id);
      setAssetBookings(futureBookingsCount);
      openDeleteModal(id);
    },
    [mutationAssetStatistics, openDeleteModal],
  );

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoading && hasMore) {
        onLoadMore();
      }
    }, [onLoadMore, hasMore, isLoading]),
  });

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        <CardGrid>
          {assets.length === 0 ? (
            <Subtitle2>
              {sessionUser.isAdmin
                ? t("page.bookings.list-assets.no-assets.admin")
                : t("page.bookings.list-assets.no-assets.resident")}
            </Subtitle2>
          ) : (
            assets.map((asset) => (
              <BookableAssetCard
                data-testid="asset-card"
                onDelete={() => onOpenDeleteModal(asset.id)}
                onEdit={() => navigate(routes.bookings.editAsset({ slug, id: asset.id }))}
                key={asset.id}
                {...{ asset }}
              />
            ))
          )}
        </CardGrid>
        {hasMore && (
          <div className="p-4" ref={loaderRef}>
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          </div>
        )}
      </div>

      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          assetBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: assetBookings })
        }
        onDelete={mutationDeleteAsset.mutateAsync}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </>
  );
}
