import type { ImageDto } from "api/types";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { twResolve } from "helpers/tw-resolve";

export interface UserProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  avatar?: ImageDto;
  deletedAt?: string;
  className?: string;
}

export function User(props: UserProps): React.ReactNode {
  return (
    <div className={twResolve("flex items-center text-left", props.className)}>
      <div className="size-10">
        <UserAvatar img={props.avatar} isUserDeleted={!!props.deletedAt} />
      </div>
      <div className="ml-3 text-sm">
        <p className="font-semibold text-black">{props.title}</p>
        <p className="text-grey">{props.subtitle}</p>
      </div>
    </div>
  );
}
