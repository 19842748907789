import iconChevronLeft from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Paper } from "components/Paper/Paper";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { useSlug } from "hooks/useSlug";
import { NextButton } from "modules/onboarding-flow/components/NextButton";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface FormValues {
  avatar?: FormImage[];
  firstName: string;
  lastName: string;
  biography?: string;
  company?: string;
  occupation?: string;
}

export interface LayoutProps {
  defaultValues: FormValues;
  isCompanyProject: boolean;
  onSubmit: (data: FormValues) => void;
  isSubmitting: boolean;
}

export function Layout({ defaultValues, isCompanyProject, onSubmit, isSubmitting }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({ defaultValues });

  function handleSubmit() {
    const data = formMethods.getValues();
    onSubmit({
      avatar: data.avatar,
      firstName: data.firstName,
      lastName: data.lastName,
      biography: data.biography,
      occupation: data.occupation,
    });
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex-auto md:flex md:h-full md:flex-row">
        <main className="z-10 min-h-full w-full overflow-auto">
          <Paper theme="no-header-minimal">
            <div className="flex size-full items-center justify-center">
              <div className="flex min-h-[700px] w-[320px] flex-col justify-between gap-3 overflow-y-auto rounded-lg bg-white shadow-md">
                <div className="flex flex-col gap-4 p-4">
                  <Button
                    type="link"
                    styling="tertiary"
                    className="p-0 text-aop-basic-blue underline"
                    href={routes.onboardingFlow.slides({ slug })}
                  >
                    <Icon name={iconChevronLeft} size={16} />
                    {t("common.action.back")}
                  </Button>
                  <Headline4>{t("page.onboarding.user-details.title.1")}</Headline4>
                  <Headline4>{t("page.onboarding.user-details.title.2")}</Headline4>
                  <Subtitle2 className="font-normal">{t("page.onboarding.user-details.subtitle-photo")}</Subtitle2>
                </div>
                <Form formMethods={formMethods} onSubmit={handleSubmit}>
                  <div className="px-4">
                    <FormContent>
                      <FormField htmlFor="avatar" label="">
                        <div className="flex size-full items-center justify-center">
                          <FormImageInput<FormValues, "avatar">
                            isAvatarInput
                            name="avatar"
                            id="avatar"
                            rules={{
                              validate: {
                                size(image) {
                                  if (image) {
                                    return validateSize(t, image);
                                  }
                                },
                              },
                            }}
                          />
                        </div>
                      </FormField>
                      <FormField
                        label={t("page.onboarding.user-details.form.first-name.label")}
                        required
                        htmlFor="firstName"
                      >
                        <FormInput<FormValues>
                          id="firstName"
                          name="firstName"
                          placeholder={t("page.onboarding.user-details.form.first-name.placeholder")}
                          rules={{
                            validate: {
                              required: createRequiredStringRule(
                                t,
                                "page.onboarding.user-details.form.first-name.label",
                              ),
                            },
                          }}
                        />
                      </FormField>
                      <FormField
                        label={t("page.onboarding.user-details.form.last-name.label")}
                        required
                        htmlFor="lastName"
                      >
                        <FormInput<FormValues>
                          id="lastName"
                          name="lastName"
                          placeholder={t("page.onboarding.user-details.form.last-name.placeholder")}
                          rules={{
                            validate: {
                              required: createRequiredStringRule(
                                t,
                                "page.onboarding.user-details.form.last-name.label",
                              ),
                            },
                          }}
                        />
                      </FormField>
                      <FormField label={t("page.onboarding.user-details.form.biography.label")} htmlFor="biography">
                        <FormTextArea<FormValues>
                          id="biography"
                          name="biography"
                          placeholder={t("page.onboarding.user-details.form.biography.placeholder")}
                        />
                      </FormField>
                      {isCompanyProject && (
                        <>
                          <FormField label={t("page.onboarding.user-details.form.company.label")} htmlFor="company">
                            <FormInput<FormValues> id="company" name="company" disabled />
                          </FormField>
                          <FormField
                            label={t("page.onboarding.user-details.form.occupation.label")}
                            htmlFor="occupation"
                          >
                            <FormInput<FormValues>
                              id="occupation"
                              name="occupation"
                              placeholder={t("page.onboarding.user-details.form.occupation.placeholder")}
                            />
                          </FormField>
                        </>
                      )}
                    </FormContent>
                  </div>
                  <div className="flex justify-end justify-self-end">
                    <NextButton isLoading={isSubmitting}>{t("common.action.next")}</NextButton>
                  </div>
                </Form>
              </div>
            </div>
          </Paper>
        </main>
      </div>
    </div>
  );
}
