import type { ApiQueryParams } from "types/api-types";

// Only add global query keys if you're certain they can be reused across projects
export const GLOBAL_QUERY_KEYS = {
  ALERTS: ["ALERTS"],
  ALERTS_LIST: () => [...GLOBAL_QUERY_KEYS.ALERTS, "LIST"] as const,

  ALERTS_TRIGGERED_LIST: (query: ApiQueryParams<"getTriggeredAlertsV1">, projectId: string | null) =>
    ["ALERTS_TRIGGERED", query, projectId] as const,

  AUTOMATED_SURVEYS: ["AUTOMATED_SURVEYS"],
  AUTOMATED_SURVEY_DETAILS: (id: string) => [...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "DETAILS", id] as const,
  AUTOMATED_SURVEY_LIST: (query?: ApiQueryParams<"getAutomatedSurveysV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "LIST", query] as const)
      : ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "LIST"] as const),
  AUTOMATED_SURVEY_ACTIVE_LIST: (id: string, query?: ApiQueryParams<"getAutomatedSurveysV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "ACTIVE_LIST", id, query] as const)
      : ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "ACTIVE_LIST", id] as const),

  CONNECTED_PROJECTS: ["SELF_CONNECTED_PROJECTS"],

  INTERCOM_IDENTITY: ["INTERCOM_IDENTITY"],

  AVAILABLE_INTEGRATIONS: (language: string) => ["AVAILABLE_INTEGRATIONS", language] as const,
  AVAILABLE_INTEGRATIONS_DETAIL: (id: string, language: string) =>
    [...GLOBAL_QUERY_KEYS.AVAILABLE_INTEGRATIONS(language), id] as const,

  LANGUAGES_BASE: ["LANGUAGES_BASE"],
  LANGUAGES_ALL: ["LANGUAGES_ALL"],

  MASS_MESSAGES: ["MASS_MESSAGES"],
  MASS_MESSAGES_PERMISSION: () => [...GLOBAL_QUERY_KEYS.MASS_MESSAGES, "PERMISSION"] as const,
  MASS_MESSAGES_PROJECTS: (type: string, groupId?: string) =>
    groupId
      ? ([...GLOBAL_QUERY_KEYS.MASS_MESSAGES, "PROJECTS", type, groupId] as const)
      : ([...GLOBAL_QUERY_KEYS.MASS_MESSAGES, "PROJECTS", type] as const),
  MASS_MESSAGES_GROUPS: (type: string) => [...GLOBAL_QUERY_KEYS.MASS_MESSAGES, "GROUPS", type] as const,

  PLATFORM_GROUPS: ["PLATFORM_GROUPS"],
  PLATFORM_GROUPS_LIST: (query?: ApiQueryParams<"getSystemGroupsV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PLATFORM_GROUPS, "LIST", query] as const)
      : ([...GLOBAL_QUERY_KEYS.PLATFORM_GROUPS, "LIST"] as const),
  PLATFORM_GROUP_DETAILS: (id: string) => [...GLOBAL_QUERY_KEYS.PLATFORM_GROUPS, "DETAILS", id] as const,

  PORTFOLIO: ["PORTFOLIO"],
  PORTFOLIO_LIST: (month: string) => [...GLOBAL_QUERY_KEYS.PORTFOLIO, "LIST", month] as const,
  PORTFOLIO_BENCHMARK: (month: string) => [...GLOBAL_QUERY_KEYS.PORTFOLIO, "BENCHMARK", month] as const,

  PORTFOLIO_TICKETS: ["PORTFOLIO_TICKETS"],
  PORTFOLIO_TICKETS_LIST: (query?: ApiQueryParams<"getAdminPortfolioTicketsV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "LIST", query] as const)
      : ([...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "LIST"] as const),
  PORTFOLIO_TICKETS_IDS: (query?: ApiQueryParams<"getAdminPortfolioTicketsV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "IDS", query] as const)
      : ([...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "IDS"] as const),
  PORTFOLIO_TICKETS_TABS_AMOUNT: (query: ApiQueryParams<"getAdminPortfolioTicketsStatsAllTabsV1">) =>
    [...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "TAB_AMOUNT", query] as const,
  PORTFOLIO_TICKETS_TAB_TOTAL: (query: ApiQueryParams<"getAdminPortfolioTicketsStatsDetailsV1">) =>
    [...GLOBAL_QUERY_KEYS.PORTFOLIO_TICKETS, "TAB_TOTAL", query] as const,

  PROJECT_CONNECTIONS: ["PROJECT_CONNECTIONS"],
  PROJECT_CONNECTION_LIST_INCLUDE_ALL: (projectId: string, query: ApiQueryParams<"getProjectConnectionsV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTIONS, "LIST", projectId, query] as const)
      : ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTIONS, "LIST", projectId] as const),
  PROJECT_CONNECTION_LIST: (query?: ApiQueryParams<"getProjectConnectionsV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTIONS, "LIST", query] as const)
      : ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTIONS, "LIST"] as const),
  PROJECT_CONNECTION_DETAILS: (id: string) => [...GLOBAL_QUERY_KEYS.PROJECT_CONNECTIONS, "DETAILS", id] as const,

  PROJECT_CONNECTION_GROUPS: ["PROJECT_CONNECTION_GROUPS"],
  PROJECT_CONNECTION_GROUPS_LIST: (id: string, query?: { Offset: number; Limit: number }) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTION_GROUPS, "LIST", id, query] as const)
      : ([...GLOBAL_QUERY_KEYS.PROJECT_CONNECTION_GROUPS, "LIST", id] as const),
  PROJECT_CONNECTION_GROUP_DETAILS: (id: string) =>
    [...GLOBAL_QUERY_KEYS.PROJECT_CONNECTION_GROUPS, "DETAILS", id] as const,

  QUICK_REPLY_REPAIR_DETAILS: (token: string) => ["QUICK_REPLY_REPAIR_DETAILS", token] as const,
  QUICK_REPLY_REPAIR_ACTIVITIES: (token: string) => ["QUICK_REPLY_REPAIR_ACTIVITIES", token] as const,
  QUICK_REPLY_REPAIR_ACTIVITY_TRANSLATION: (token: string, activityId: string) =>
    ["QUICK_REPLY_REPAIR_ACTIVITIES", token, activityId, "translation"] as const,
  QUICK_REPLY_REPAIR_TRANSLATION: (token: string) =>
    [GLOBAL_QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token), "translation"] as const,

  SELF: (projectId?: string) => (projectId ? (["SELF", projectId] as const) : (["SELF"] as const)),

  TICKET_SETTINGS: ["TICKET_SETTINGS"] as const,
} as const;
