import { twResolve } from "helpers/tw-resolve";
import type { ForwardedRef } from "react";
import type React from "react";
import { forwardRef } from "react";
import { twJoin } from "tailwind-merge";

export interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "prefix"> {
  ref?: ForwardedRef<HTMLInputElement>;
  className?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  isError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, prefix, value, postfix, isError, disabled, readOnly, ...props },
  ref,
) {
  const Component = prefix ? "div" : "label";

  return (
    <Component className="relative">
      {prefix && <InputExtra position="start">{prefix}</InputExtra>}
      <input
        autoComplete="off"
        {...props}
        value={value}
        className={twResolve(
          "h-10 w-full appearance-none rounded-lg border border-grey-lighter px-2 py-1.5 text-base leading-6 placeholder:text-grey hover:border-aop-dark-blue focus-visible:border-aop-dark-blue focus-visible:outline-none",
          (disabled || readOnly) && "border-grey-lighter bg-grey-lightest text-grey-darker hover:border-grey-lighter",
          isError && "border-red-dark",
          prefix && "pl-8",
          postfix && "pr-8",
          className,
        )}
        aria-invalid={isError}
        {...{ readOnly, ref, disabled }}
      />
      {postfix && <InputExtra position="end">{postfix}</InputExtra>}
    </Component>
  );
});

interface InputExtraProps {
  children: React.ReactNode;
  position: "start" | "end";
}

const InputExtra = ({ children, position }: InputExtraProps) => {
  return (
    <div
      className={twJoin(
        "absolute top-0 flex h-full w-8 items-center justify-center text-grey-light",
        position === "start" && "left-0",
        position === "end" && "right-0",
      )}
    >
      {children}
    </div>
  );
};
