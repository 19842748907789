import type React from "react";
import { twJoin } from "tailwind-merge";

export interface TagProps {
  clickable?: boolean;
  children: React.ReactNode;
}

export function Tag({ children, clickable }: TagProps): React.ReactNode {
  return (
    <div
      className={twJoin(
        "inline-flex items-center justify-center whitespace-nowrap rounded-2px bg-blue-lightest p-1.5 text-capture2 font-normal leading-capture2",
        clickable ? "hover:bg-blue-lighter" : undefined,
      )}
    >
      {children}
    </div>
  );
}
