import { autoUpdate, useFloating } from "@floating-ui/react";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import iconX from "assets/icons/x.svg";
import logoDark from "assets/images/logo-dark.svg";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Subtitle2 } from "components/Text/Text";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSidebarManager } from "hooks/useSidebar";
import type React from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { ProjectSidebarProjectSwitcher } from "./ProjectSidebarProjectSwitcher";

interface ProjectSidebarHeaderProps {
  onToggleOpen: (state: boolean) => void;
}

export function ProjectSidebarHeader({ onToggleOpen }: ProjectSidebarHeaderProps): React.ReactNode {
  const [isProjectSwitcherVisible, projectSwitcherVisibilityHandlers] = useBool();

  const { t } = useTranslation();
  const [sessionUser, { isPlaceholderData }] = useSessionUserOptional({ keepPreviousData: true });
  const isDesktop = useScreenIsBiggerThan("md");
  const { isCollapsed } = useSidebarManager();
  const connectedProjects = useConnectedProjects();
  const { refs, floatingStyles } = useFloating({ whileElementsMounted: autoUpdate });

  const canSwitchProjects = connectedProjects.data && connectedProjects.data?.length > 1;

  return (
    <Sidebar.Header>
      {isDesktop && isPlaceholderData && (
        <div className="flex h-10 items-center justify-center">
          <LoadingIcon className="w-7 delay-300 duration-300 animate-in fade-in" />
        </div>
      )}
      {isDesktop && !isPlaceholderData && (
        <div className={twJoin("flex items-center justify-start md:h-10", isCollapsed ? "w-fit px-2" : "w-full px-6")}>
          <button
            className={twJoin(
              "flex w-full items-center gap-2 rounded-lg p-1 outline-none transition-colors [&:not(&:disabled)]:hover:bg-aop-basic-blue-lightest [&:not(&:disabled)]:focus-visible:bg-aop-basic-blue-lightest",
            )}
            onClick={projectSwitcherVisibilityHandlers.toggle}
            disabled={!canSwitchProjects}
          >
            <ProjectLogo className="size-8" src={sessionUser?.project?.logoUrl} />
            {!isCollapsed && (
              <>
                <Subtitle2 className="line-clamp-1 flex-1 break-all text-left">{sessionUser?.project?.name}</Subtitle2>
                {canSwitchProjects && <Icon name={chevronDownIcon} />}
              </>
            )}
          </button>
        </div>
      )}
      {!isDesktop && (
        <div className="flex items-center justify-between px-4">
          <img src={logoDark} className="aspect-[80/39] w-16" alt="Area of People logo" />
          <button
            className="rounded p-0.5 text-grey"
            aria-label={t("common.action.cancel")}
            onClick={() => onToggleOpen(false)}
          >
            <Icon name={iconX} size={24} />
          </button>
        </div>
      )}
      <ProjectSidebarProjectSwitcher
        isOpened={isProjectSwitcherVisible}
        onClose={projectSwitcherVisibilityHandlers.setFalse}
        ref={refs.setFloating}
        style={floatingStyles}
      />
    </Sidebar.Header>
  );
}
