import type { UserDto } from "api/types";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { useBool } from "hooks/useBool";

import { TicketResidentCommentField } from "./TicketResidentCommentField";

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  isOpen: boolean;
  user: UserDto;
  onClose?: () => void | Promise<void>;
  onSubmit: (value: string, image: FormImage | undefined) => void | Promise<void>;
}

export function TicketResidentRejectModal({
  title,
  description,
  isOpen,
  user,
  onClose,
  onSubmit,
}: Props): React.ReactNode {
  const [submitting, submittingHandlers] = useBool();

  async function submit({ value, image }: { value: string; image: FormImage | undefined }) {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      if (value) {
        await onSubmit?.(value, image);
      } else {
        await onClose?.();
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  async function close() {
    if (submitting) {
      return;
    }

    try {
      submittingHandlers.setTrue();

      await onClose?.();
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={close} shouldCloseOnEsc={!submitting}>
      <div className="max-w-md rounded p-4 text-center" data-testid="ticket-reject-modal">
        <Headline4 className="my-4 block">{title}</Headline4>

        {submitting ? (
          <LoadingIcon className="mx-auto mb-6 mt-8 w-8" />
        ) : (
          <>
            <p className="mx-1 my-4">{description}</p>

            <TicketResidentCommentField
              canComment
              user={user}
              onComment={(payload) => submit({ value: payload.content, image: payload.image })}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
