import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import iconX from "assets/icons/x.svg";
import iconZoomIn from "assets/icons/zoom-in.svg";
import { IconButton } from "components/Button/IconButton";
import { Carousel } from "components/Carousel/Carousel";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Tooltip } from "components/Tooltip/Tooltip";
import type { AnimationProps } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import { preloadImage } from "helpers/image";
import { useKey } from "hooks/useKey";
import { memo, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface GalleryProps {
  images: FormImage[];
  size?: "small" | "normal";
  isDeleted?: boolean;
  onRemove?: (image: FormImage) => void;
}

const commonAnimProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const Gallery = memo(function Gallery({ images, isDeleted, onRemove, size }: GalleryProps) {
  const { t } = useTranslation();
  const [zoomedImage, setZoomedImage] = useState<FormImage | undefined>(undefined);
  const resolveImage = useImageResolver();

  if (zoomedImage && !images.includes(zoomedImage)) {
    setZoomedImage(undefined);
  }

  useKey(
    "Escape",
    () => {
      setZoomedImage(undefined);
    },
    zoomedImage !== undefined,
  );

  if (images.length === 0) {
    return null;
  }

  return (
    <div className={twJoin("flex items-end", onRemove ? "pt-6" : undefined)}>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {zoomedImage && (
            <motion.div className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto">
              <motion.div
                className="absolute inset-0 z-0 bg-black/80"
                role="button"
                onClick={() => setZoomedImage(undefined)}
                {...commonAnimProps}
              />
              {images.length === 1 && (
                <motion.img
                  className="z-10 w-4/5 cursor-pointer select-none rounded object-cover sm:max-h-[80%] sm:w-auto sm:px-16"
                  src={zoomedImage.url}
                  alt={"description" in zoomedImage ? zoomedImage.description || "" : ""}
                  layoutId={zoomedImage.url}
                  onClick={() => setZoomedImage(undefined)}
                  {...commonAnimProps}
                />
              )}
              {images.length > 1 && (
                <>
                  <motion.div className="relative aspect-[3/4] w-4/5 sm:h-4/5 sm:w-auto" {...commonAnimProps}>
                    <Carousel
                      defaultImage={images.indexOf(zoomedImage)}
                      images={images as ImageDto[]}
                      allowZoom={false}
                      objectFit="contain"
                    />
                    <IconButton
                      className="absolute -right-3 top-0 z-10 translate-x-full"
                      styling="ghostPrimary"
                      title={t("common.action.previous")}
                      withTooltip={false}
                      onClick={() => setZoomedImage(undefined)}
                      isCircular
                    >
                      <Icon name={iconX} size={24} />
                    </IconButton>
                  </motion.div>
                </>
              )}
            </motion.div>
          )}
        </AnimatePresence>,
        document.body,
      )}
      <AnimatePresence>
        {images.map((image) => (
          <div
            key={image.url}
            className={twJoin("group relative mr-2 cursor-pointer last:mr-0", isDeleted ? "opacity-30" : undefined)}
            onClick={() => setZoomedImage(image)}
            onMouseEnter={() => {
              if ("id" in image) {
                preloadImage(image.url);
              }
            }}
          >
            <motion.div className="absolute inset-0" layoutId={image.url} />
            <img
              className={twJoin(
                "max-w-full select-none rounded bg-grey-lightest shadow",
                size === "small" ? "max-h-[50px]" : "max-h-36 sm:max-w-xs",
              )}
              loading="lazy"
              src={"id" in image ? resolveImage(image, "large") : image.url}
              alt={"description" in image ? image.description || "" : ""}
            />

            {onRemove ? (
              <Tooltip tooltip={t("component.gallery.remove-image")}>
                <motion.button
                  className={
                    "absolute -right-4 -top-4 flex size-8 items-center justify-center rounded-full bg-grey-darkest shadow transition-opacity hover:bg-grey-darker hover:shadow-md focus:bg-grey-dark focus:outline-none"
                  }
                  type="button"
                  layout
                  exit={{ opacity: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemove(image);
                  }}
                >
                  <Icon name={iconX} size={20} className="text-white" />
                </motion.button>
              </Tooltip>
            ) : null}

            <div className="absolute right-2 top-2 flex size-8 items-center justify-center rounded-full bg-black/40 opacity-0 shadow transition-opacity group-hover:opacity-100">
              <Tooltip tooltip={t("component.gallery.zoom-in-image")}>
                <Icon name={iconZoomIn} size={20} className="text-white" />
              </Tooltip>
            </div>
          </div>
        ))}
      </AnimatePresence>
    </div>
  );
});
