import type { ServicePartnerPageDto } from "api/types";
import emailIcon from "assets/icons/mail-01.svg";
import connectionIcon from "assets/icons/marker-pin-01.svg";
import phoneIcon from "assets/icons/phone.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Card, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

interface ServiceCardProps {
  service: ServicePartnerPageDto;
  "data-testid"?: string;
  onEdit: () => void;
  onDelete: () => void;
}

export function ServiceCard({
  service,
  "data-testid": dataTestId,
  onEdit,
  onDelete,
}: ServiceCardProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (service.canEdit) {
      result.push({
        callback: onEdit,
        text: t("common.action.edit"),
      });
    }
    if (service.canDelete) {
      result.push({
        callback: onDelete,
        text: t("common.action.delete"),
      });
    }

    return result;
  }, [service.canEdit, service.canDelete, onEdit, t, onDelete]);

  const image = service.headerImage || sessionUser.project.backgroundImage || null;

  return (
    <Card data-testid={dataTestId} link={routes.servicePartners.details({ slug, id: service.id })}>
      <CardImage
        image={image ? image : sessionUser.project.backgroundImage ? sessionUser.project.backgroundImage : null}
        labels={[
          <Label key="service-type" theme="blue">
            {service.type}
          </Label>,
          service.connection && (
            <Label key="connection-name" theme="gray">
              <Icon name={connectionIcon} />
              {service.connection.name}
            </Label>
          ),
          service.hasAudience && sessionUser.isAdmin && (
            <Label theme="blue">
              <Icon size={16} name={iconUsersCheck} />
            </Label>
          ),
        ]}
      />

      <CardContent>
        <CardHeader
          title={service.title}
          titleLink={routes.servicePartners.details({ slug, id: service.id })}
          menuActions={actions}
        />

        <div className="flex flex-col gap-2">
          {service.phoneNumber ? (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-lightest px-1 py-0.5 text-grey-dark">
              <Icon name={phoneIcon} />
              <span>{service.phoneNumber}</span>
            </div>
          ) : null}
          {service.email ? (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-lightest px-1 py-0.5 text-grey-dark">
              <Icon name={emailIcon} />
              <span>{service.email}</span>
            </div>
          ) : null}
        </div>

        {service.subtitle && <CardDescription description={service.subtitle} />}
      </CardContent>
    </Card>
  );
}
