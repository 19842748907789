import type { DocumentsItemDto } from "api/types";
import iconFileText from "assets/icons/file-06.svg";
import iconFolder from "assets/icons/folder.svg";
import iconImage01 from "assets/icons/image-01.svg";
import iconLink03 from "assets/icons/link-03.svg";
import { Icon } from "components/Icon/Icon";

export function DocumentsFileIcon({ type }: { type: DocumentsItemDto["type"] }): React.ReactNode {
  switch (type) {
    case "folder":
      return <Icon name={iconFolder} className="text-yellow-dark" />;
    case "document":
      return <Icon name={iconFileText} className="text-blue-darker" />;
    case "image":
      return <Icon name={iconImage01} className="text-green-darker" />;
    case "link":
      return <Icon name={iconLink03} className="text-grey-darker" />;
    default:
      console.warn(`File type ${type} unknown`);

      return <Icon name={iconFileText} className="text-blue-darker" />;
  }
}
