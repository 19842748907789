import type { BookableSlotDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconClock from "assets/icons/clock.svg";
import { Icon } from "components/Icon/Icon";
import { Capture2 } from "components/Text/Text";
import { add, differenceInDays, format, isBefore, isEqual } from "date-fns";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getTimeslotDateTime, isAfterOrSame, isBeforeOrSame } from "../helpers";
import type { BookAssetFormValues } from "../pages/BookAsset/Layout";

interface BookingTimeslotButtonProps {
  timeslot: BookableSlotDto;
  potentialEndTime: Date | null;
  canBookMultipleSlots: boolean;
  firstUnavailableSlot: BookableSlotDto | undefined;
  isBookedByUser: boolean;
  onHover: (timeslot: Date | null) => void;
}

type FormattedBookableSlot = Omit<BookableSlotDto, "startTime" | "endTime"> & { startTime: Date; endTime: Date };

export function BookingTimeslotButton({
  timeslot,
  potentialEndTime,
  firstUnavailableSlot,
  canBookMultipleSlots,
  isBookedByUser,
  onHover,
}: BookingTimeslotButtonProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useFormContext<BookAssetFormValues>();
  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });
  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  const isTimeslotHighlighted = (timeslot: FormattedBookableSlot): boolean => {
    if (!startTime) return false;

    if (endTime) {
      return isAfterOrSame(timeslot.startTime, startTime) && isBeforeOrSame(timeslot.endTime, endTime);
    }

    if (potentialEndTime) {
      return isAfterOrSame(timeslot.startTime, startTime) && isBeforeOrSame(timeslot.endTime, potentialEndTime);
    }

    return isEqual(timeslot.startTime, startTime);
  };

  // A timeslot is selected when it is inbetween (inclusive) the selected start and end time
  const isTimeslotSelected = (timeslot: FormattedBookableSlot): boolean => {
    return (
      !!startTime &&
      isAfterOrSame(timeslot.startTime, startTime) &&
      !!endTime &&
      isBeforeOrSame(timeslot.endTime, endTime)
    );
  };

  // A timeslot is disabledwhen it is before the selected start time or it's after a booked timeslot
  const isTimeslotDisabled = (timeslot: FormattedBookableSlot): boolean => {
    if (!startTime) {
      return bookAllDay;
    } else {
      if (!canBookMultipleSlots) {
        return !isEqual(timeslot.startTime, startTime);
      }

      if (firstUnavailableSlot) {
        const { startTime: firstUnAvailableSlotStartTime } = getTimeslotDateTime(firstUnavailableSlot);

        return (
          isBefore(timeslot.startTime, startTime) ||
          (!!firstUnavailableSlot && isAfterOrSame(timeslot.startTime, firstUnAvailableSlotStartTime))
        );
      } else {
        return isBefore(timeslot.startTime, startTime);
      }
    }
  };

  const onClickTimeslot = (timeslot: FormattedBookableSlot) => {
    if (startTime && endTime) {
      // If a time range has been selected but the start timeslot is clicked, cancel current selection
      if (isEqual(timeslot.startTime, startTime)) {
        form.setValue("startTime", null);
        form.setValue("endTime", null);

        return;
      } else {
        if (canBookMultipleSlots) {
          form.setValue("endTime", timeslot.endTime);
        } else {
          form.setValue("endTime", null);
        }
      }
    } else if (!startTime) {
      form.setValue("startTime", timeslot.startTime);

      if (timeslot.isAllDay || !canBookMultipleSlots) {
        form.setValue("endTime", timeslot.endTime);
      } else {
        form.setValue("endTime", null);
      }
    } else if (startTime && !endTime) {
      form.setValue("endTime", timeslot.endTime);
      form.clearErrors("startTime");
    }
  };

  // Transform the timeslot times to date objects
  const formattedBookableSlot: FormattedBookableSlot = useMemo(() => {
    const { startTime: newStartTime, endTime } = getTimeslotDateTime(timeslot);

    let newEndTime = endTime;
    // If the endtime of a timeslot is midnight, then its the start of the next day
    if (timeslot.endTime === "00:00:00") {
      newEndTime = add(newEndTime, { days: 1 });
    }

    return {
      ...timeslot,
      startTime: newStartTime,
      endTime: newEndTime,
    };
  }, [timeslot]);
  const labelTimeslot = `${format(formattedBookableSlot.startTime, "HH:mm")} - ${format(formattedBookableSlot.endTime, "HH:mm")}`;
  let labelButton = labelTimeslot;
  if (formattedBookableSlot.isAllDay) {
    // If the timeslot is meant from 00:00 to 00:00 (next day), then hide timeslot in label
    if (differenceInDays(formattedBookableSlot.endTime, formattedBookableSlot.startTime) === 1) {
      labelButton = t("page.bookings.book-asset.form.booking-timeslot.full-day");
    } else {
      labelButton = `${t("page.bookings.book-asset.form.booking-timeslot.full-day")} ${labelTimeslot}`;
    }
  }
  const isHighlighted = isTimeslotHighlighted(formattedBookableSlot);
  const isUnavailable = formattedBookableSlot.state === "booked" && !isBookedByUser;
  const isDisabled = isTimeslotDisabled(formattedBookableSlot);
  const isSelected = isTimeslotSelected(formattedBookableSlot);

  return (
    <button
      data-testid="timeslot-btn"
      type="button"
      className={twResolve(
        "rounded-full bg-green-lightest px-2 py-1 text-green-darker transition-colors  hover:bg-green-darker hover:text-white disabled:bg-grey-lightest disabled:text-grey",
        isHighlighted && "bg-green-darker text-white hover:bg-green-darkest",
        isUnavailable && "cursor-not-allowed bg-red-lightest text-red-dark hover:bg-red-lightest hover:text-red-dark",
      )}
      onClick={() => onClickTimeslot(formattedBookableSlot)}
      onMouseEnter={() => onHover(formattedBookableSlot.startTime)}
      onMouseLeave={() => onHover(null)}
      disabled={isDisabled}
    >
      <Capture2 className="flex items-center gap-1">
        {isSelected ? <Icon name={iconCheck} /> : <Icon name={iconClock} />}
        {labelButton}
      </Capture2>
    </button>
  );
}
