import { Capture2 } from "components/Text/Text";
import { twJoin } from "tailwind-merge";

export function AlertBar({
  theme,
  icon,
  noWrap,
  children,
}: {
  theme: "yellow" | "blue";
  icon: React.ReactNode;
  noWrap?: boolean;
  children?: React.ReactNode;
}): React.ReactNode {
  return (
    <div
      className={twJoin(
        "flex items-center gap-2 rounded-lg border p-2",
        theme === "yellow" ? "border-yellow bg-yellow-lightest text-black" : "border-blue bg-blue/10 text-blue",
      )}
      data-testid="alert-bar"
    >
      <div className={twJoin("shrink-0", theme === "yellow" ? "text-yellow" : undefined)}>{icon}</div>
      <Capture2 className={twJoin("font-semibold", noWrap ? "whitespace-nowrap" : undefined)}>{children}</Capture2>
    </div>
  );
}
