import Logo503 from "assets/images/503.svg";
import { AnchorButton } from "components/Anchor/Anchor";
import { SUPPORT_EMAIL } from "helpers/constants";
import { fallbackLanguageCode, hardcodedTranslations } from "helpers/hardcoded-translations";

import { ErrorPageInner } from "./ErrorPageInner";

interface ConnectionFailurePageProps {
  poEditorCode?: string;
}

export function ConnectionFailurePage({ poEditorCode }: ConnectionFailurePageProps): React.ReactNode {
  const solutions: React.ReactNode[] = [];
  const connectionFailureTranslations = (
    poEditorCode && poEditorCode in hardcodedTranslations
      ? hardcodedTranslations[poEditorCode as keyof typeof hardcodedTranslations]
      : hardcodedTranslations[fallbackLanguageCode]
  ).errors.connectionFailure;

  solutions.push(
    <>
      <AnchorButton isBold onClick={() => location.reload()}>
        {connectionFailureTranslations.refresh}
      </AnchorButton>{" "}
      {connectionFailureTranslations.page}
    </>,
  );

  solutions.push(connectionFailureTranslations.later);

  solutions.push(
    <>
      {connectionFailureTranslations.contact}{" "}
      <a
        className="cursor-pointer font-semibold text-aop-basic-blue hocus:text-aop-basic-blue-dark hocus:underline"
        href={`mailto:${SUPPORT_EMAIL}`}
      >
        {SUPPORT_EMAIL}
      </a>
    </>,
  );

  return (
    <ErrorPageInner
      labels={{
        error: connectionFailureTranslations.error,
        solutions: connectionFailureTranslations.solutions,
      }}
      content={{
        title: connectionFailureTranslations.title,
        status: connectionFailureTranslations.unknown,
        solutions,
        image: Logo503,
      }}
    />
  );
}
