import type { EventDetailsDto } from "api/types";
import { List } from "components/List/List";
import { Capture2, Headline4 } from "components/Text/Text";
import { User } from "components/User/User";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import type React from "react";
import { useTranslation } from "react-i18next";

interface EventDetailsParticipantsSectionProps {
  event: EventDetailsDto;
}

export function EventDetailsParticipantsSection({ event }: EventDetailsParticipantsSectionProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="h-full rounded-lg bg-white p-4" data-testid="event-participants">
      <Headline4 as="h2" className="block leading-relaxed tracking-[-0.008em]">
        {t("page.event.details.participants.title")}
      </Headline4>
      <Capture2 as="p" data-testid="event-participants-number" className="block leading-5 text-grey-darker">
        {t("page.event.details.participants.details", { count: event.participantCount })}
      </Capture2>
      <div className="mt-4" data-testid="event-participants-list">
        <List getKey={(item) => item.id} list={event.participants}>
          {(user) => (
            <UserNameLink key={user.id} user={user}>
              <User avatar={user.avatar} deletedAt={user.deletedAt} title={user.fullName} subtitle={user.locatedAt} />
            </UserNameLink>
          )}
        </List>
      </div>
    </div>
  );
}
