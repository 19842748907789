import type { RemsStatusDto } from "api/types";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import remsLogoBig from "../assets/rems-logo-big.svg";
import remsLogoSmall from "../assets/rems-logo-small.svg";

export function RemsStatus({
  bigLogo,
  rems,
  isLoading,
}: {
  bigLogo?: boolean;
  rems?: RemsStatusDto;
  isLoading?: boolean;
}): React.ReactNode {
  if (!rems) {
    return null;
  }

  return (
    <div className={twJoin("flex items-center font-semibold text-grey-dark", bigLogo ? "flex-col gap-4" : "gap-2")}>
      {bigLogo ? (
        <img src={remsLogoBig} width="153.4px" height="35px" alt="Aareon" />
      ) : (
        <img src={remsLogoSmall} width="22px" height="21.9px" alt="Aareon" />
      )}
      <div className="flex items-center gap-2">
        <span className={bigLogo ? undefined : "inline-block max-w-xs truncate"}>
          <RemsStatusContent rems={rems} isLoading={isLoading} />
        </span>
      </div>
    </div>
  );
}

function RemsStatusContent({ rems, isLoading }: { rems: RemsStatusDto; isLoading?: boolean }): React.ReactNode {
  const { t } = useTranslation();

  rems.state = isLoading ? "pending" : rems.state;

  switch (rems.state) {
    case "pending":
      return (
        <>
          {t("page.tickets.rems.state")} <span>{t("page.tickets.rems.state.pending")}</span>
        </>
      );
    case "synchronized":
    case "synchronizedAutoClosed":
      return (
        <>
          <span className="block text-center">
            {t("page.tickets.rems.synced")} <span className="text-green-darker">{rems.externalId || "-"}</span>
          </span>
          {rems.state === "synchronizedAutoClosed" && (
            <span className="mt-2 block text-center">{t("page.tickets.rems.state.auto-closed")}</span>
          )}
        </>
      );
    case "ready":
      return (
        <>
          {t("page.tickets.rems.state")} <span className="text-green-darker">{t("page.tickets.rems.state.ready")}</span>
        </>
      );
    case "errorNoUserFound":
      return (
        <>
          {t("page.tickets.rems.state")}{" "}
          <span className="text-red-dark">{t("page.tickets.rems.state.user-not-found")}</span>
        </>
      );
    case "errorNoContracts":
      return (
        <>
          {t("page.tickets.rems.state")}{" "}
          <span className="text-red-dark">{t("page.tickets.rems.state.user-no-contracts")}</span>
        </>
      );
    case "errorGeneric":
      return (
        <>
          {t("page.tickets.rems.state")} <span className="text-red-dark">{t("page.tickets.rems.state.error")}</span>
        </>
      );
    case "notStarted":
      return (
        <>
          {t("page.tickets.rems.state")} <span>{t("page.tickets.rems.state.not-started")}</span>
        </>
      );
    default:
      return null;
  }
}
