import { Capture2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import type { ButtonHTMLAttributes } from "react";

export interface BadgeProps<T>
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> {
  as?: T;
  selected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  isBig?: boolean;
  centerText?: boolean;
}
export function Badge<T extends React.ElementType>({ children, ...props }: BadgeProps<T>): React.ReactNode {
  return (
    <Capture2
      {...props}
      className={twResolve(
        "inline-flex h-6 max-w-full items-center truncate rounded-full border border-grey-darker bg-transparent px-2 py-0.5 leading-none text-grey-darkest focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-grey-light focus-visible:ring-offset-1",
        props.selected
          ? "bg-grey-darkest text-white focus-visible:ring-grey-light"
          : props.onClick
            ? "hover:bg-grey-lightest hover:text-black focus-visible:ring-grey-light"
            : undefined,
        props.disabled ? "pointer-events-none bg-grey text-grey-lightest" : undefined,
        props.isBig ? "py-4" : undefined,
        props.centerText ? "justify-center" : undefined,
      )}
    >
      {children}
    </Capture2>
  );
}
