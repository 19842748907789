import priceIcon from "assets/icons/currency-euro.svg";
import areaIcon from "assets/icons/layer-single.svg";
import locationIcon from "assets/icons/marker-pin-01.svg";
import capacityIcon from "assets/icons/users-01.svg";
import { Icon } from "components/Icon/Icon";

import type { Specification } from "../constants";

interface SpecificationProps {
  specification: Specification;
  value?: string | number;
}

function showSpecification(specification: Specification, value: string | number): React.ReactNode {
  switch (specification) {
    case "sizeSpecification":
      return (
        <>
          <Icon name={areaIcon} className="text-grey-dark" />
          <span>{`${value}m²`}</span>
        </>
      );
    case "pricePerHourSpecification":
      return (
        <>
          <Icon name={priceIcon} className="text-grey-dark" />
          <span>{`${value}/h`}</span>
        </>
      );
    case "capacitySpecification":
      return (
        <>
          <Icon name={capacityIcon} className="text-grey-dark" />
          <span>{value}</span>
        </>
      );
    case "locationSpecification":
      return (
        <>
          <Icon name={locationIcon} className="text-grey-dark" />
          <span>{value}</span>
        </>
      );
  }
}

export function Specification({ specification, value }: SpecificationProps): React.ReactNode {
  if (!value) {
    return null;
  }

  return (
    <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-lightest px-1 py-0.5">
      {showSpecification(specification, value)}
    </div>
  );
}
