import { useApi } from "api/hooks/useApi";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconEye from "assets/icons/eye.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconX from "assets/icons/x.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { motion } from "framer-motion";
import { removeFromHeaders } from "helpers/headers";
import { isHttpError } from "helpers/Network/errors";
import { createRequiredStringRule } from "helpers/rules";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import EmailPassword from "supertokens-web-js/recipe/emailpassword";

import { AuthenticationPage } from "../components/AuthenticationPage";
import { PasswordHelper } from "../components/PasswordHelper";
import { emailRegex } from "../helpers/constants";

const LAST_USED_EMAIL_KEY = "last_used_email";

interface FormValues {
  email: string;
  password: string;
}

enum PageState {
  Initial,
  LogIn,
  SignUp,
}

export function SignInSignUpPage(): React.ReactNode {
  const showFlashToast = useFlashToast();
  const { t, i18n } = useTranslation();
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const [globalError, setGlobalError] = useState<string>();
  const [showPassword, showPasswordHandlers] = useBool(false);
  const [state, setState] = useState(PageState.Initial);
  const [isSubmitting, submittingHandlers] = useBool(false);
  const [submitCountInInitialScreen, setSubmitCountInInitialScreen] = useState(0);

  const lastUsedEmail = useMemo(() => getLocalStorageValue<string | undefined>(LAST_USED_EMAIL_KEY, undefined), []);
  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: location.state?.email || lastUsedEmail || "",
      password: "",
    },
  });
  const { setError } = formMethods;
  const submitCount = formMethods.formState.submitCount;

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  async function onSubmit(data: FormValues) {
    if (isSubmitting) {
      return false;
    }

    try {
      submittingHandlers.setTrue();
      if (state === PageState.Initial) {
        await onCheckEmail(data.email);
      } else if (state === PageState.LogIn) {
        await onLogin(data.email, data.password);
      } else if (state === PageState.SignUp) {
        await onSignUp(data.email, data.password);
      } else {
        throw new Error("Invalid state");
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  const onCheckEmail = useCallback(
    async (email: string) => {
      updateLocalStorage(LAST_USED_EMAIL_KEY, email);

      try {
        const response =
          (await EmailPassword.doesEmailExist({
            email,
            options: {
              preAPIHook: (x) => {
                return Promise.resolve({
                  ...x,
                  requestInit: removeFromHeaders(x.requestInit, "Authorization"),
                });
              },
            },
          }).then((x) => x.doesExist)) ||
          (await api
            .postUserSignInSignUpVerifyEmailV1({
              email,
            })
            .then((x) => x.data.exists));

        setState(response ? PageState.LogIn : PageState.SignUp);
        setSubmitCountInInitialScreen(submitCount + 1);
        setTimeout(() => {
          passwordRef.current?.focus();
        }, 10);
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          setGlobalError(err.message);
        } else if (isHttpError(err)) {
          if (err.status === 429) {
            setGlobalError(t("page.login.errors.too-many-attempts"));
          } else {
            setGlobalError(t("page.login.errors.unknown"));
          }
        } else {
          showFlashToast({ type: "error", title: t("common.generic-error") });
        }
      }
    },
    [api, showFlashToast, submitCount, t],
  );

  const onLogin = useCallback(
    async (email: string, password: string) => {
      try {
        const response = await EmailPassword.signIn({
          formFields: [
            {
              id: "email",
              value: email,
            },
            {
              id: "password",
              value: password,
            },
          ],
          options: {
            preAPIHook: (x) => {
              return Promise.resolve({
                ...x,
                requestInit: removeFromHeaders(x.requestInit, "Authorization"),
              });
            },
          },
        });

        if (response.status === "FIELD_ERROR") {
          response.formFields.forEach((formField) => {
            if (formField.id === "email") {
              setError("email", { type: "manual", message: formField.error });
            }
          });
        } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
          setGlobalError(t("page.login.errors.wrong-credentials"));
        } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
          setGlobalError(response.reason);
        } else {
          let redirectPath = new URLSearchParams(window.location.search).get("redirect") || "/";
          if (!redirectPath.startsWith("/")) {
            redirectPath = "/" + redirectPath;
          }
          navigate(redirectPath);
        }
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          setGlobalError(err.message);
        } else if (isHttpError(err)) {
          setGlobalError(t("page.login.errors.unknown"));
        } else {
          showFlashToast({ type: "error", title: t("common.generic-error") });
        }
      }
    },
    [navigate, setError, showFlashToast, t],
  );

  const onSignUp = useCallback(
    async (email: string, password: string) => {
      try {
        const response = await EmailPassword.signUp({
          formFields: [
            {
              id: "email",
              value: email,
            },
            {
              id: "password",
              value: password,
            },
          ],
          options: {
            preAPIHook: (x) => {
              return Promise.resolve({
                ...x,
                requestInit: removeFromHeaders(x.requestInit, "Authorization"),
              });
            },
          },
        });

        if (response.status === "FIELD_ERROR") {
          for (const formField of response.formFields) {
            if (formField.id === "email") {
              setError("email", { type: "manual", message: formField.error });
            } else if (formField.id === "password") {
              setError("password", { type: "manual", message: formField.error });
            }
          }
        } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
          setError("email", { type: "manual", message: response.reason });
        } else {
          const otherQuery = location.search.replace("?", "");
          const query = `?from=signup${otherQuery ? "&" + otherQuery : ""}`;
          navigate(routes.authentication.verifyEmail() + query, { state: { noLoader: true } });
        }
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          setError("email", { type: "manual", message: err.message });
        } else {
          setError("email", {
            type: "manual",
            message: t("page.login.errors.unknown"),
          });
        }
      }
    },
    [navigate, setError, t, location.search],
  );

  const password = useWatch({ control: formMethods.control, name: "password" });

  const hasSubmittedPassword = submitCount > submitCountInInitialScreen;
  const languagePath = i18n.language === "de" ? "/de/" : i18n.language === "en" ? "/en/" : "/";

  return (
    <AuthenticationPage
      header={
        state === PageState.LogIn
          ? t("page.login.welcome-back.title")
          : state === PageState.SignUp
            ? t("page.login.sign-up.title")
            : t("page.login.title")
      }
      body={t("page.login.subtitle")}
      step={state === PageState.SignUp ? 1 : undefined}
      totalSteps={state === PageState.SignUp ? 2 : undefined}
    >
      <Form onSubmit={onSubmit} formMethods={formMethods}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4 text-left">
            {globalError && (
              <div className="relative flex items-center rounded bg-red-lightest p-2 text-center text-sm text-red-dark">
                <span className="flex-1 px-8">{globalError}</span>
                <span className="absolute right-0">
                  <IconButton
                    title={t("common.action.close")}
                    styling="ghostSecondary"
                    onClick={() => setGlobalError(undefined)}
                  >
                    <Icon name={iconX} size={16} />
                  </IconButton>
                </span>
              </div>
            )}
            <div>
              <FormField label={t("page.login.form.email.label")}>
                <FormInput<FormValues>
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.login.form.email.label"),
                      isValidEmail(email) {
                        if (!new RegExp(emailRegex).test(email)) {
                          return t("page.login.errors.email-invalid-pattern");
                        }
                      },
                    },
                  }}
                  placeholder={t("page.login.form.email.placeholder")}
                  inputRef={emailRef}
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="username"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoFocus
                  aria-required
                  disabled={state !== PageState.Initial || isSubmitting}
                  postfix={
                    state !== PageState.Initial && (
                      <IconButton
                        title={t("page.login.form.email.change")}
                        onClick={() => {
                          setState(PageState.Initial);
                          setGlobalError(undefined);
                          // Time out to make sure the input is no longer readonly after rerender
                          setTimeout(() => {
                            if (emailRef.current) {
                              emailRef.current.focus();

                              // Move cursor to end of input
                              const inputType = emailRef.current.type;
                              emailRef.current.type = "text";
                              emailRef.current.selectionStart = emailRef.current.selectionEnd =
                                emailRef.current.value.length;
                              emailRef.current.type = inputType;
                            }
                          }, 10);
                        }}
                        styling="ghostSecondary"
                      >
                        <Icon name={iconEdit05} size={18} />
                      </IconButton>
                    )
                  }
                />
              </FormField>
              <motion.div
                aria-hidden={state === PageState.Initial}
                {...{ inert: state === PageState.Initial ? "" : undefined }}
                className={state === PageState.Initial ? "pointer-events-none" : "mt-4"}
                variants={{
                  open: { opacity: 1, height: "auto" },
                  closed: { opacity: 0, height: 0 },
                }}
                initial="closed"
                animate={state === PageState.Initial ? "closed" : "open"}
              >
                <FormField label={t("page.login.form.password.label")}>
                  <FormInput<FormValues>
                    inputRef={passwordRef}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    hideErrorText={state === PageState.SignUp}
                    autoComplete="current-password"
                    autoCapitalize="none"
                    spellCheck="false"
                    aria-required
                    rules={{
                      validate: {
                        ...(state === PageState.LogIn
                          ? { required: createRequiredStringRule(t, "page.login.form.password.label") }
                          : {}),
                        ...(state === PageState.SignUp
                          ? {
                              required: createRequiredStringRule(t, "page.login.form.password.label"),
                              minCharacters(password) {
                                if (password.length < 8) {
                                  return t("page.login.errors.password-minimum-chars", { chars: 8 });
                                }
                              },
                              containsLowercase(password) {
                                if (password.toUpperCase() === password) {
                                  return t("page.login.errors.password-lowercase");
                                }
                              },
                              containsNumber(password) {
                                if (!/\d/.test(password)) {
                                  return t("page.login.errors.password-number");
                                }
                              },
                            }
                          : {}),
                      },
                    }}
                    postfix={
                      <IconButton
                        title={
                          showPassword
                            ? t("page.login.form.password.toggle-hidden")
                            : t("page.login.form.password.toggle-visible")
                        }
                        onClick={showPasswordHandlers.toggle}
                        styling="ghostSecondary"
                      >
                        <Icon name={showPassword ? iconEyeOff : iconEye} size={18} />
                      </IconButton>
                    }
                  />

                  {state === PageState.SignUp && <PasswordHelper {...{ password, hasSubmittedPassword }} />}
                </FormField>
              </motion.div>
            </div>
            <div className="mt-2">
              <Button isLoading={isSubmitting} type="submit" className="w-full">
                {state === PageState.Initial && t("page.login.form.submit")}
                {state === PageState.LogIn && t("page.login.form.login.submit")}
                {state === PageState.SignUp && t("page.login.form.signup.submit")}
              </Button>
              {state === PageState.LogIn && (
                <div className="mt-4 flex justify-center text-sm">
                  <Anchor
                    to={routes.authentication.forgotPassword()}
                    state={{
                      email: formMethods.getValues("email"),
                    }}
                    isBold
                  >
                    {t("page.login.form.forgot-password")}
                  </Anchor>
                </div>
              )}
            </div>
          </div>
          {state === PageState.Initial && (
            <p className="text-overline leading-overline">
              <Trans
                i18nKey="page.login.form.license"
                components={{
                  newline: <br />,
                  terms: <Anchor isBold isExternal to={`https://areaofpeople.com${languagePath}license-agreement`} />,
                  policy: <Anchor isBold isExternal to={`https://areaofpeople.com${languagePath}privacy-policy`} />,
                }}
              />
            </p>
          )}
        </div>
      </Form>
    </AuthenticationPage>
  );
}
