import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import { AlertBar } from "components/AlertBar/AlertBar";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { Headline4 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  projectName: string;
  onSubmit: (deleteUsers: boolean) => void;
  onClose: () => void;
  isLoading: boolean;
}

export function CloseProjectModal({ projectName, onSubmit, onClose, isLoading }: Props): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<CloseProjectModalValues>({
    defaultValues: {
      name: "",
      deleteUsers: true,
    },
  });

  const deleteUsers = useWatch({ control: form.control, name: "deleteUsers" });

  return (
    <Form<CloseProjectModalValues> formMethods={form} onSubmit={(data) => onSubmit(data.deleteUsers)} noPrompt>
      <div className="flex max-w-lg flex-col items-center gap-4 p-4 text-left">
        <Headline4>{t("page.projects.close-project.modal.title")}</Headline4>
        <div className="justify-self-center">
          <Trans
            i18nKey="page.projects.close-project.modal.description"
            components={{
              b: <strong />,
            }}
          />
        </div>

        <div className="w-full">
          <FormContent maxWidth="2xl">
            <FormField label="">
              <FormInput<CloseProjectModalValues>
                id="name"
                name="name"
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.projects.close-project.modal.form.name"),
                    notSameAsProjectName: (value) => {
                      if (!value) {
                        return undefined;
                      }

                      return value === projectName
                        ? undefined
                        : t("page.projects.close-project.modal.error.not-same-as-name");
                    },
                  },
                }}
              />
            </FormField>
            <FormField label="">
              <FormCheckbox<CloseProjectModalValues>
                name="deleteUsers"
                label={t("page.projects.close-project.modal.form.delete-users")}
              />

              {deleteUsers && (
                <div className="mt-2">
                  <AlertBar theme="blue" icon={<Icon name={iconAlertTriangle} />}>
                    {t("page.projects.close-project.modal.warning")}
                  </AlertBar>
                </div>
              )}
            </FormField>
          </FormContent>
        </div>

        <div className="flex items-center justify-center gap-2">
          <Button type="button" styling="secondary" onClick={onClose} disabled={isLoading}>
            {t("common.action.cancel")}
          </Button>
          <Button type="submit" styling="danger" isLoading={isLoading}>
            {t("page.projects.close-project.modal.confirm")}
          </Button>
        </div>
      </div>
    </Form>
  );
}

interface CloseProjectModalValues {
  name: string;
  deleteUsers: boolean;
}
