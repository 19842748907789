import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatReplyDto, ChatReplyRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { UserDetailsLayoutProps } from "./Layout";

interface UserDetailsLoaderProps {
  children: (props: UserDetailsLayoutProps) => React.ReactNode;
}

const POST_OFFSET = 0;
const POST_LIMIT = 2;
const CHATS_PAGE = 10;

export function Loader(props: UserDetailsLoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const query = useQueryClient();
  const { id: userIdParam } = useParams<{ id: string }>();
  const showFlashToast = useFlashToast();
  const { uploadFormImage } = useUploadImage();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const [chatReplies, setChatReplies] = useState<ChatReplyDto[]>([]);

  const {
    data: chatUserDetails,
    isPending: isLoadingChatUserDetails,
    error: chatUserDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.USER_CHAT_DETAILS(projectId, userIdParam!),
    queryFn: () => api.getChatsUserDetailsV2(userIdParam!),
    select: commonAPIDataSelector,
  });

  const { signalRConnection } = useSignalRHub("chat-hub", {
    query: `userId=${sessionUser.id}&chatId=${chatUserDetails?.id}`,
    disabled: !chatUserDetails,
  });

  const { invoke: onReadMessage } = useSignalRInvocation(signalRConnection, "ChatMessageRead");

  const onNewChatMessage = useCallback(
    (...args: [{ chatId: string; isGroupChat: boolean; latestReply: ChatReplyDto }]) => {
      const latestChatReply = args[0].latestReply;
      setChatReplies((prev) => [...prev, latestChatReply]);
      void onReadMessage({ chatId: chatUserDetails?.id, userId: sessionUser.id });
    },
    [setChatReplies, onReadMessage, chatUserDetails, sessionUser.id],
  );

  useSignalRSubscription(signalRConnection, "NewChatMessage", onNewChatMessage);

  const {
    data: userDetails,
    isPending: isLoadingUserDetails,
    error: userDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.USER_DETAILS(projectId, userIdParam!),
    queryFn: () => api.getUsersDetailsV1(userIdParam!),
    select: commonAPIDataSelector,
  });

  const { data: deletedBy } = useQuery({
    queryKey: QUERY_KEYS.USER_DETAILS(projectId, userDetails?.deletedByUserId as any),
    queryFn: () => api.getUsersDetailsV1(userDetails?.deletedByUserId as any),
    select: commonAPIDataSelector,
    enabled: sessionUser.isSuperAdmin && !!userDetails?.deletedAt && !!userDetails?.deletedByUserId,
  });

  const {
    data: userProjectsData,
    isLoading: isLoadingUserProjects,
    error: userProjectsError,
  } = useQuery({
    queryKey: QUERY_KEYS.USER_PROJECTS(projectId, userIdParam!),
    queryFn: () => api.getUsersProjectsV1(userIdParam!),
    select: commonAPIDataSelector,
    enabled: !!userIdParam && sessionUser.isSuperAdmin && !!userDetails && !userDetails.project,
  });

  const { data: userPosts, isPending: isLoadingUserPosts } = useQuery({
    queryKey: QUERY_KEYS.USER_POSTS(projectId, userIdParam!),
    queryFn: () =>
      api.getMessagesV2({
        Tab: "all",
        Offset: POST_OFFSET,
        Limit: POST_LIMIT,
        PosterId: userIdParam,
        Connection: "included",
      }),
    select: commonAPIDataSelector,
  });

  const setDeletionDate = useMutation({
    mutationFn: (date: Date | undefined) => api.putUsersDeletionDateV1(userIdParam!, { deleteAt: date?.toISOString() }),
    onSuccess: () => {
      void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, userIdParam!) });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_POSTS(projectId, userIdParam!) });
      showFlashToast({ type: "success", title: t("page.user-detail.set-deletion-date.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.user-detail.set-deletion-date.error") });
    },
  });

  const deleteUser = useMutation({
    mutationFn: () => api.deleteUsersByIdV1(userIdParam!),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.user-detail.delete.success") });
      if (!sessionUser.isSuperAdmin && sessionUser.isAdmin) {
        navigate(routes.users.list({ slug }));

        return;
      }
      void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, userIdParam!) });
      void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_POSTS(projectId, userIdParam!) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.user-detail.delete.error") });
    },
  });

  const {
    data: repliesData,
    hasNextPage: hasMoreReplies,
    fetchNextPage: fetchMoreReplies,
    isFetchingNextPage: isLoadingMoreReplies,
    error: errorLoadingReplies,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatUserDetails?.id || ""),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsRepliesV2(chatUserDetails?.id || "", { Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
    enabled: !!chatUserDetails,
  });

  useEffect(() => {
    setChatReplies(repliesData?.pages.flatMap((x) => x.items) ?? []);
  }, [repliesData]);

  const createChat = useMutation({
    mutationFn: () => api.postChatsPrivateV2({ userId: userIdParam! }).then((x) => x.data),
    onSuccess: () => {
      void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_CHAT_DETAILS(projectId, userIdParam!) });
    },
    onError: () => {
      console.error("Error creating private chat!");
    },
  });

  const sendChat = async ({ message, files }: { message: string; files: FormImage[] }) => {
    let chatId = chatUserDetails?.id;
    if (!chatUserDetails) {
      chatId = await createChat.mutateAsync().then((x) => x.id);
    }

    const uploadedImage = await uploadFormImage(files[0]);

    return await replyChat.mutateAsync({
      chatId: chatId!,
      payload: { text: message, imageId: uploadedImage?.id },
    });
  };

  const replyChat = useMutation({
    mutationFn: ({ chatId, payload }: { chatId: string; payload: ChatReplyRequest }) =>
      api.postChatsReplyV2(chatId, payload).then((x) => x.data),
    onSuccess: async (_, { chatId }) => {
      await query.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.user-detail.chat.send-error") });
    },
  });

  const { data: signUpReminders } = useQuery({
    queryKey: QUERY_KEYS.USER_SIGNUP_REMINDERS(projectId, userIdParam!),
    queryFn: () => api.getUsersUserSignupRemindersV1(userIdParam!),
    select: commonAPIDataSelector,
    enabled: !!userDetails && !userDetails.registeredAt && !userDetails.project,
  });

  const sendReminder = useMutation({
    mutationFn: () => api.postUsersUserSignupRemindersV1(userIdParam!),
    onSuccess: async () => {
      await query.invalidateQueries({ queryKey: QUERY_KEYS.USER_SIGNUP_REMINDERS(projectId, userIdParam!) });
      showFlashToast({ type: "success", title: t("page.user-detail.invite-user.success") });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.user-detail.invite-user.error") });
    },
  });

  const posts = useMemo(() => userPosts?.items ?? [], [userPosts]);
  const userProjects = useMemo(
    () => userProjectsData?.filter((project) => project.id !== sessionUser.projectId),
    [userProjectsData, sessionUser],
  );

  const error = userDetailsError || userProjectsError || chatUserDetailsError || errorLoadingReplies || !userIdParam;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingUserDetails || isLoadingUserProjects || isLoadingChatUserDetails;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    userDetails: userDetails,
    userProjects,
    userPosts: posts,
    isLoadingUserPosts,
    onUserDelete: deleteUser.mutateAsync,
    onUserSetDeletionDate: setDeletionDate.mutateAsync,
    chatReplies: chatReplies,
    hasMoreReplies: hasMoreReplies,
    fetchMoreReplies: fetchMoreReplies,
    isLoadingMoreReplies: isLoadingMoreReplies,
    sendChat,
    signUpReminders: signUpReminders,
    sendReminder: sendReminder.mutate,
    isSubmittingModal: deleteUser.isPending || setDeletionDate.isPending,
    deletedBy,
  });
}
