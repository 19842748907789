import iconCalendar from "assets/icons/calendar.svg";
import { AnchorButton } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import type { DateAndTimePickerProps, DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Subtitle2 } from "components/Text/Text";
import { addMinutes } from "date-fns";
import { useBool } from "hooks/useBool";
import { useState } from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { FormErrorWrapper } from "./FormErrorWrapper";

type Props<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> = UseControllerProps<TFormValues, TName> &
  Pick<DateAndTimePickerProps, "min" | "max"> & {
    hideScheduledLabel?: boolean;
    labelWhenNoValue?: string;
    canEdit: boolean;
    onChange?: (value: DatePickerValue) => void;
  };

export function FormScheduleInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  onChange,
  canEdit,
  labelWhenNoValue,
  hideScheduledLabel,
  ...props
}: Props<TFormValues, TName>): React.ReactNode {
  const { t } = useTranslation();
  const [showSchedule, showScheduleHandler] = useBool();

  const {
    field: { onChange: fieldOnChange, ...field },
    fieldState,
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const [value, setValue] = useState<DatePickerValue>(field.value || defaultValue || addMinutes(new Date(), 5));

  const isScheduled = !!field.value;

  return (
    <FormErrorWrapper name={name} encircle>
      <div className={twJoin("flex items-start", hideScheduledLabel ? undefined : "flex-col gap-2")}>
        <div className="flex items-center gap-2">
          <Icon name={iconCalendar} className={hideScheduledLabel && showSchedule ? "mt-3" : ""} />
          <Subtitle2 className="flex flex-wrap gap-x-1">
            {field.value || showSchedule ? (
              hideScheduledLabel ? null : (
                <span className={showSchedule ? "font-normal" : ""}>
                  {t("component.schedule-input.state.scheduled")}
                </span>
              )
            ) : (
              <span className="font-normal">{labelWhenNoValue || t("component.schedule-input.state.immediate")}</span>
            )}
            {isScheduled && !showSchedule ? (
              <span className="font-normal">
                <FormattedDate date={field.value} format="datetime" />
              </span>
            ) : null}
          </Subtitle2>

          {!showSchedule ? (
            <AnchorButton data-testid="schedule-btn" isBold onClick={showScheduleHandler.toggle} disabled={!canEdit}>
              {t("common.action.edit")}
            </AnchorButton>
          ) : null}
        </div>
        {showSchedule ? (
          <div className="flex w-full max-w-sm flex-col gap-2">
            <DateAndTimePicker
              data-testid="scheduled-date-time"
              value={value}
              {...props}
              type="datetime"
              onChange={setValue}
              error={fieldState.error != null}
              aria-invalid={fieldState.error != null}
            />
            <div className="flex w-full flex-row-reverse items-center justify-between">
              <div className="flex gap-2">
                <Button
                  styling="secondary"
                  onClick={() => {
                    showScheduleHandler.setFalse();
                    field.onBlur();
                  }}
                >
                  {t("common.action.cancel")}
                </Button>
                <Button
                  data-testid="schedule-confirmation-btn"
                  onClick={() => {
                    fieldOnChange(value);
                    field.onBlur();
                    showScheduleHandler.setFalse();
                  }}
                >
                  {t("common.action.ok")}
                </Button>
              </div>
              {isScheduled ? (
                <Button
                  styling="danger"
                  onClick={() => {
                    fieldOnChange("");
                    field.onBlur();
                    showScheduleHandler.setFalse();
                  }}
                >
                  {t("common.action.remove")}
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </FormErrorWrapper>
  );
}
