import type { ImageDto } from "api/types";

import { useApiClient } from "./useApi";

export function useImageResolver(): (image: ImageDto, size: "square" | "squareBig" | "large") => string {
  const api = useApiClient();

  return (image, size) => `${api.baseUrl}/api/v1/images/${image.id}/${imageSizeMap[size]}`;
}

const imageSizeMap = {
  square: "90x90",
  squareBig: "180x180",
  large: "large",
};
