import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture2, Headline4 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";

const DEBOUNCE_WAIT = 200;
const AVAILABLE_USERS_PAGE = 10;
const MIN_SEARCH_CHARACTERS = 2;

interface Props {
  groupId: string;
  header: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function GroupMembersModal({ groupId, header, isOpen, onClose }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDeferredValue(
    useDebounce(query.trim().length < MIN_SEARCH_CHARACTERS ? "" : query, DEBOUNCE_WAIT),
  );
  const {
    data: availableUsersData,
    hasNextPage: hasMoreUsers,
    fetchNextPage: fetchMoreUsers,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isFetchingMoreUsers,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_MEMBERS(projectId, groupId, debouncedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getGroupsMembersV1(groupId, {
          search: debouncedQuery,
          Offset: pageParam * AVAILABLE_USERS_PAGE,
          Limit: AVAILABLE_USERS_PAGE,
        })
        .then(commonAPIDataSelector),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: isOpen,
    staleTime: 30 * 1000, // 30 seconds
  });
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreUsers && hasMoreUsers) {
        void fetchMoreUsers();
      }
    }, [fetchMoreUsers, hasMoreUsers, isFetchingMoreUsers]),
  });

  const availableUsers = useMemo(() => availableUsersData?.pages.flatMap((x) => x.items) ?? [], [availableUsersData]);

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onClose()} shouldCloseOnEsc>
      <div className="flex flex-col gap-3 px-5 pb-2 pt-5" data-testid="group-members-modal">
        <div className="flex flex-col gap-2">
          <Headline4 as="h2">{t("page.group-details.members-modal.title")}</Headline4>
          {header}
        </div>
        <SearchInput
          data-testid="search-input"
          onChange={(e) => setQuery(e.target.value.trim())}
          placeholder={t("page.group-details.members-modal.search-placeholder")}
        />
        <div className="max-h-96 overflow-y-scroll rounded-md md:min-h-96 md:min-w-96 md:max-w-xl">
          {isLoadingUsers ? (
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          ) : availableUsers.length === 0 ? (
            <Capture2 className="px-3 pb-5 pt-1 text-grey-dark" as="p">
              {t("page.group-details.members-modal.no-results")}
            </Capture2>
          ) : (
            <ul>
              {availableUsers.map((availableUser) => (
                <li key={availableUser.id} data-testid="available-user">
                  <Link to={routes.users.details({ slug, id: availableUser.id })}>
                    <div className="border-b border-b-grey-lightest p-3 hover:shadow-lg">
                      <div className="flex grow items-center gap-3">
                        <div className="size-10 shrink-0">
                          <UserAvatar img={availableUser?.avatar} />
                        </div>
                        <div className="text-sm">
                          <span className="font-semibold text-black">{availableUser.fullName}</span>
                          <p className="text-grey">
                            <span>{availableUser.locatedAt}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {hasMoreUsers && (
            <div className="p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
