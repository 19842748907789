import iconX from "assets/icons/x.svg";
import { Icon } from "components/Icon/Icon";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { twJoin } from "tailwind-merge";

export interface ReactModalAdapterProps
  extends Pick<
    ReactModal.Props,
    | "isOpen"
    | "onRequestClose"
    | "onAfterClose"
    | "shouldCloseOnOverlayClick"
    | "shouldCloseOnEsc"
    | "shouldReturnFocusAfterClose"
    | "children"
    | "id"
    | "aria"
  > {
  className?: string;
  isActionRequired?: boolean;
  overflowYVisible?: boolean;
  fullScreen?: boolean;
}

export function Modal({
  className,
  isActionRequired,
  overflowYVisible,
  fullScreen,
  ...props
}: ReactModalAdapterProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <ReactModal
      {...props}
      appElement={document.getElementById("root")!}
      data-testid="modal"
      contentLabel="modal"
      closeTimeoutMS={160}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ?? !isActionRequired ?? true}
      shouldCloseOnEsc={props.shouldCloseOnEsc ?? !isActionRequired ?? true}
      shouldReturnFocusAfterClose
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      overlayClassName="custom-modal-overlay fixed inset-0 z-40 flex justify-center overflow-auto bg-grey-darkest/60 opacity-0 transition-opacity items-center"
      className={twJoin(
        "custom-modal relative mx-2 max-h-screen-minus-8 w-full max-w-[calc(100%-32px)] overflow-y-auto rounded bg-white text-black shadow-xl outline-none transition-transform sm:mx-0 md:w-auto",
        overflowYVisible && "overflow-y-visible",
        fullScreen ? "h-screen !w-screen" : undefined,
      )}
    >
      {!isActionRequired && (
        <button
          className="absolute right-1.5 top-1.5 z-10 rounded text-grey focus-within:bg-grey-light focus-within:text-white hover:bg-grey-light hover:text-white focus:outline-none"
          data-testid="modal-close"
          aria-label={t("components.modal.action.close")}
          onClick={props.onRequestClose}
        >
          <Icon name={iconX} size={24} />
        </button>
      )}
      {props.children}
    </ReactModal>
  );
}

type ModalStateData<TData> =
  | (TData extends never
      ? { isOpen: true }
      : {
          data: TData;
          isOpen: true;
        })
  | {
      data?: TData;
      isOpen: false;
    };

interface ModalHelpers<TData> {
  requestClose: () => void;
  afterClose: () => void;
  open: (data: TData) => void;
}

export type ModalState<TData = never> = ModalStateData<TData> & ModalHelpers<TData>;

/**
 * To make sure close animations are smooth we provide a utility with an afterClose callback that resets the data.
 */
export function useModalState<TData = never>(initialData?: ModalStateData<TData>): ModalState<TData> {
  const [state, setState] = useState<ModalStateData<TData>>(
    initialData || {
      isOpen: false,
      data: undefined,
    },
  );

  const requestClose = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const afterClose = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false, data: undefined }));
  }, []);

  const open = useCallback((data: TData) => {
    setState({ isOpen: true as any, data });
  }, []);

  return useMemo(
    () => ({
      isOpen: state.isOpen,
      data: (state as any).data,
      requestClose,
      afterClose,
      open,
    }),
    [afterClose, requestClose, open, state],
  );
}
