import type { AdoptionRateDetailsGraphItemDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Subtitle2 } from "components/Text/Text";
import { Capture2 } from "components/Text/Text";
import { Capture1 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { BarChart } from "modules/analytics/components/BarChart";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { NonEmptyArray } from "types/utility-types";

export interface LayoutProps {
  adoption: AdoptionRateDetailsGraphItemDto[];
  vacant: AdoptionRateDetailsGraphItemDto[];
  isRefetchingAdoptionData: boolean;
  isRefetchingVacantData: boolean;
  selectedDateRange: DateRangeOption;
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
  onDateRangeChange: (dateRange: DateRangeOption) => void;
}

const BENCHMARK_VALUE = 80;

export function Layout({
  adoption,
  vacant,
  isRefetchingAdoptionData,
  isRefetchingVacantData,
  selectedDateRange,
  dateRangeOptions,
  onDateRangeChange,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { project } = useSessionUser();

  const adoptionDataPoints = useMemo(
    () => (adoption ? adoption.map((point) => ({ x: formatChartDate(point.range), y: point.percentage })) : []),
    [adoption],
  );

  const vacantDataPoints = useMemo(
    () => (vacant ? vacant.map((point) => ({ x: formatChartDate(point.range), y: point.percentage })) : []),
    [vacant],
  );

  const benchmark = useMemo(
    () =>
      adoption
        ? adoption.map((point) => ({ x: formatChartDate(point.range), y: BENCHMARK_VALUE }))
        : vacant
          ? vacant.map((point) => ({ x: formatChartDate(point.range), y: BENCHMARK_VALUE }))
          : [],
    [adoption, vacant],
  );

  const noAdoptionDataAvailable = !adoption || adoption.every((x) => x.percentage === 0);
  const noVacantDataAvailable = !vacant;

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.analytics-details.adoption.title")}
      subTitle={
        <Button
          type="link"
          styling="tertiary"
          className="pl-0 font-normal text-aop-basic-blue"
          href={routes.analytics.overview({ slug })}
          icon={<Icon name={iconChevronLeft} />}
        >
          {t("page.analytics-details.back")}
        </Button>
      }
      header={
        <div className="flex items-center gap-2 py-4">
          <Subtitle2>{t("page.analytics-details.time-range.label")}</Subtitle2>
          <Select
            items={dateRangeOptions}
            selected={selectedDateRange}
            onChange={onDateRangeChange}
            renderOption={(x) => x.name}
            keySelector={(x) => x.value}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div className="flex flex-col gap-4 lg:col-span-2">
          <Card>
            <CardHeader
              title={
                project.type === "addressBased"
                  ? t("page.analytics-details.adoption.chart.adoption.title")
                  : t("page.analytics-details.adoption.chart.company.title")
              }
            />
            {isRefetchingAdoptionData ? (
              <LoadingIcon className="mx-auto w-8" />
            ) : noAdoptionDataAvailable ? (
              <NoData error={noAdoptionDataAvailable} />
            ) : (
              <div className="flex flex-col gap-4">
                <Capture2 className="text-grey-darker">
                  {project.type === "addressBased"
                    ? t("page.analytics-details.adoption.chart.adoption.description")
                    : t("page.analytics-details.adoption.chart.company.description")}
                </Capture2>
                <div className="w-full">
                  <BarChart
                    type="date"
                    height={250}
                    primaryData={adoptionDataPoints}
                    benchmark={project.type === "addressBased" ? benchmark : undefined}
                    formatYTick={(y) => y}
                    formatXTick={(x) => x}
                    maxY={100}
                    yLabel={t("page.analytics-details.adoption.chart.adoption.y-axis")}
                    showLegend={false}
                    renderLabel={(data) => (
                      <div className="whitespace-pre-line">
                        {project.type === "addressBased"
                          ? t("page.analytics-details.adoption.chart.adoption-benchmark.tooltip", {
                              percentage: data.primary?.y,
                            })
                          : t("page.analytics-details.adoption.chart.adoption.tooltip", {
                              percentage: data.primary?.y,
                            })}
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
          </Card>
          {project.type === "addressBased" && (
            <Card>
              <CardHeader title={t("page.analytics-details.adoption.chart.vacant.title")} />
              {isRefetchingVacantData ? (
                <LoadingIcon className="mx-auto w-8" />
              ) : noVacantDataAvailable ? (
                <NoData error={noVacantDataAvailable} />
              ) : (
                <div className="flex flex-col gap-4">
                  <Capture2 className="text-grey-darker">
                    {t("page.analytics-details.adoption.chart.vacant.description")}
                  </Capture2>
                  <div className="w-full">
                    <BarChart
                      type="date"
                      height={250}
                      primaryData={vacantDataPoints}
                      formatYTick={(y) => y}
                      formatXTick={(x) => x}
                      maxY={100}
                      yLabel={t("page.analytics-details.adoption.chart.adoption.y-axis")}
                      showLegend={false}
                      renderLabel={(data) => (
                        <div className="whitespace-pre-line">
                          {t("page.analytics-details.adoption.chart.adoption.tooltip", {
                            percentage: data.primary?.y,
                          })}
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
            </Card>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.adoption.information.title")} />
            <Capture1>{t("page.analytics-details.adoption.information.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.adoption.information.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`information-${idx}`}>{item}</li>)}
            </ul>
          </Card>
          <Card className="h-fit justify-start">
            <CardHeader title={t("page.analytics-details.adoption.best-practices.title")} />
            <Capture1>{t("page.analytics-details.adoption.best-practices.subtitle")}</Capture1>
            <ul className="list-disc p-4">
              {t("page.analytics-details.adoption.best-practices.content")
                .split("\n")
                .map((item, idx) => item.length > 0 && <li key={`best-practices-${idx}`}>{item}</li>)}
            </ul>
          </Card>
        </div>
      </div>
    </DocumentPaper>
  );
}
