import iconLightning02 from "assets/icons/lightning-02.svg";
import { Icon } from "components/Icon/Icon";
import type React from "react";

import type { ButtonBaseProps } from "./Button";
import { Button } from "./Button";

type AIButtonProps = Pick<ButtonBaseProps, "children" | "onClick" | "disabled" | "data-testid"> & {
  type?: "button" | "submit";
};

export function AIButton({ children, type = "button", ...otherProps }: AIButtonProps): React.ReactNode {
  return (
    <Button styling="ai" iconPosition="left" icon={<Icon name={iconLightning02} />} {...{ ...otherProps, type }}>
      {children}
    </Button>
  );
}
