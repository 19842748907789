export interface BookableDay {
  day: WeekDay;
  endTime?: Date;
  startTime?: Date;
  allDay: boolean;
  enabled: boolean;
}

export type Specification =
  | "sizeSpecification"
  | "pricePerHourSpecification"
  | "capacitySpecification"
  | "locationSpecification";

export const specificationOptions: Specification[] = [
  "sizeSpecification",
  "pricePerHourSpecification",
  "capacitySpecification",
  "locationSpecification",
];

export type WeekDay = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export const daysOptions: WeekDay[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export type TimeSlot =
  | "fifteenMinutes"
  | "thirtyMinutes"
  | "oneHour"
  | "twoHours"
  | "threeHours"
  | "fourHours"
  | "allDay";

export const timeSlotOptions: TimeSlot[] = [
  "fifteenMinutes",
  "thirtyMinutes",
  "oneHour",
  "twoHours",
  "threeHours",
  "fourHours",
  "allDay",
];
