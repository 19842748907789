import iconChevronDown from "assets/icons/chevron-down.svg";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useClickOutside } from "hooks/useClickOutside";
import { useKey } from "hooks/useKey";
import { useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

interface Props {
  value: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  placement: "left" | "right";
}

export function MonthPicker({ value: selected, onChange, minDate, maxDate, placement }: Props): React.ReactNode {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, isOpenHandlers] = useBool();
  const [year, setYear] = useState<number>(() => new Date().getFullYear());

  useKey("Escape", isOpenHandlers.setFalse, isOpen);
  useClickOutside(ref, isOpenHandlers.setFalse, isOpen);

  function previousYear() {
    setYear((y) => y - 1);
  }

  function nextYear() {
    setYear((y) => y + 1);
  }

  function setMonth(month: number) {
    const newDate = new Date(year, month, 1);
    onChange?.(newDate);
    isOpenHandlers.setFalse();
  }

  useEffect(() => {
    if (isOpen) {
      setYear(selected.getFullYear());
    }
  }, [isOpen, selected]);

  return (
    <div className="relative" ref={ref}>
      <button
        className={twJoin(
          "group relative flex min-w-36 cursor-default items-center justify-between gap-2 whitespace-nowrap rounded-lg border bg-white px-2 py-1 text-left leading-[26px] focus:outline-none focus-visible:ring-1 focus-visible:ring-grey-darkest",
          isOpen
            ? "rounded-b-none border-grey-darker hocus:border-grey-darker"
            : "border-grey-lighter hocus:border-grey-darker",
        )}
        onClick={isOpenHandlers.toggle}
      >
        <FormattedDate date={selected} format="monthYear" />
        <Icon name={iconChevronDown} size={16} />
      </button>
      {isOpen ? (
        <div
          className={twJoin(
            "absolute top-full z-10 -mt-px flex min-w-60 flex-col rounded rounded-tl-none border border-grey-dark bg-white p-2 shadow",
            placement === "left" ? "left-0" : "right-0",
          )}
        >
          <div className="flex items-center justify-center gap-2 p-2">
            <button
              className="text-grey-dark hover:text-grey-darkest disabled:cursor-default disabled:opacity-0"
              disabled={minDate && minDate.getFullYear() >= year}
              onClick={previousYear}
            >
              <Icon name={iconChevronLeft} />
            </button>
            <span>{year}</span>
            <button
              className="text-grey-dark hover:text-grey-darkest disabled:cursor-default disabled:opacity-0"
              disabled={maxDate && maxDate.getFullYear() <= year}
              onClick={nextYear}
            >
              <Icon name={iconChevronRight} />
            </button>
          </div>
          <div className="grid grid-cols-4 gap-1">
            {Array(12)
              .fill(undefined)
              .map((_, index) => {
                const active = selected && selected.getMonth() === index && selected.getFullYear() === year;
                let disabled = false;

                if (minDate) {
                  if ((year === minDate.getFullYear() && index < minDate.getMonth()) || year < minDate.getFullYear()) {
                    disabled = true;
                  }
                }

                if (maxDate) {
                  if ((year === maxDate.getFullYear() && index > maxDate.getMonth()) || year > maxDate.getFullYear()) {
                    disabled = true;
                  }
                }

                return (
                  <button
                    className={twResolve(
                      "!disabled:bg-white rounded p-2 hover:bg-grey-lightest disabled:cursor-default disabled:text-grey-light",
                      active ? "!bg-aop-basic-blue text-white" : undefined,
                    )}
                    data-testid="month-button"
                    key={index}
                    disabled={disabled}
                    onClick={() => setMonth(index)}
                  >
                    <FormattedDate date={new Date(year, index)} format="monthShort" />
                  </button>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
