import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";

type NoticeProps = {
  icon?: string;
  "data-testid"?: string;
  type?: "info" | "error";
} & (
  | {
      message: string;
      customBody?: never;
    }
  | {
      message?: never;
      customBody: React.ReactNode;
    }
);

export function Notice({
  icon,
  message,
  customBody,
  type = "info",
  "data-testid": dataTestId,
}: NoticeProps): React.ReactNode {
  return (
    <div
      className={twResolve(
        "flex w-full items-start gap-4 rounded-lg border p-4",
        type === "info" && "border-blue-dark bg-blue-lightest [&>*]:text-blue-dark",
        type === "error" && "border-red-dark bg-red-lightest [&>*]:text-red-dark",
      )}
      data-testid={dataTestId}
    >
      {icon && <Icon name={icon} size={24} className="py-1" />}
      {message && <p className="text-capture1">{message}</p>}
      {customBody}
    </div>
  );
}
