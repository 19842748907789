import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EditTicketCommentRequest, TicketCommentRequest, TicketStatusDto, UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Headline2 } from "components/Text/Text";
import { TicketLeaveCommentModal } from "components/Ticket/TicketLeaveCommentModal";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { isHttpError } from "helpers/Network/errors";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { useSlug } from "hooks/useSlug";
import { sumBy } from "lodash-es";
import { TicketContent } from "modules/tickets/components/TicketDetails/TicketContent";
import { TicketHistory } from "modules/tickets/components/TicketDetails/TicketHistory";
import { TicketHomeDnaCard } from "modules/tickets/components/TicketDetails/TicketHomeDnaCard";
import { TicketInfoHeader } from "modules/tickets/components/TicketDetails/TicketInfoHeader";
import { TicketLog } from "modules/tickets/components/TicketDetails/TicketLog";
import { TicketReminderButton } from "modules/tickets/components/TicketDetails/TicketReminderButton";
import { TicketRemsCard } from "modules/tickets/components/TicketDetails/TicketRemsCard";
import { TicketResidentInfo } from "modules/tickets/components/TicketDetails/TicketResidentInfo";
import { canSeeServiceHistory, changingAssigneeWillResultIn403 } from "modules/tickets/helpers";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import type { ApiQueryParams, ApiResponseType } from "types/api-types";

interface Props {
  ticketId: string;
  leftInSession?: number;
  showNextTicketBtn?: boolean;
  onGoToNextTicket?: () => void;
}

const ACTIVITY_AMOUNT = 20;
const TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY = "ticket-descending-activities";

export function PortfolioTicketDetails({
  ticketId,
  showNextTicketBtn,
  onGoToNextTicket,
  leftInSession,
}: Props): React.ReactNode {
  const [changedAssignee, setChangedAssignee] = useState<UserDto>();
  const [isStatusChangeModalOpen, statusChangeModalOpenHandlers] = useBool();
  const [isLeaveCommentModalOpen, leaveCommentModalOpenHandlers] = useBool();
  const [warningBeforeAssigneeChangeUser, setWarningBeforeAssigneeChangeUser] = useState<UserDto>();

  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const [activitiesAmount, setActivitiesAmount] = useState(ACTIVITY_AMOUNT);
  const [descendingActivities, descendingActivitiesHandlers] = useBool(
    getLocalStorageValue(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, true),
  );

  const { uploadFormImage } = useUploadImage();
  const { uploadFormDocument } = useUploadDocument();
  useUpdateLocalStorage(TICKET_DESCENDING_ACTIVITIES_STORAGE_KEY, descendingActivities);

  const detailToken = QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId);
  const {
    data: ticket,
    isPending: isLoadingTicket,
    error: ticketDetailsError,
  } = useQuery({
    queryKey: detailToken,
    queryFn: () => api.getAdminTicketsDetailsV1(ticketId),
    select: commonAPIDataSelector,
  });

  const activitiesParams = {
    sortDescending: descendingActivities,
  } satisfies Partial<ApiQueryParams<"getAdminTicketsActivitiesV1", 1>>;
  const activitiesQuery = useInfiniteQuery({
    queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, ticketId, activitiesParams),
    queryFn: ({ pageParam }) =>
      api
        .getAdminTicketsActivitiesV1(ticketId, {
          Offset: pageParam,
          Limit: activitiesAmount,
          ...activitiesParams,
        })
        .then((x) => x.data),
    initialPageParam: 0,
    getNextPageParam: (latestResponse, allResponses) => {
      const newOffset = sumBy(allResponses, (page) => page.items.length);

      return latestResponse?.hasMore ? newOffset : undefined;
    },
  });

  const changeAssigneeMutation = useMutation({
    mutationFn: (payload: { assigneeId: string; note?: string }) => api.putAdminTicketsAssigneeV1(ticketId, payload),
    onMutate(payload) {
      // Optimistic update
      queryClient.setQueryData(detailToken, (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            assignee: oldData.data.possibleAssignees.find((x) => x.id === payload.assigneeId)!,
          },
        };
      });
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    async onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.assignee-change.error"),
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });

  const changeStatusMutation = useMutation({
    mutationFn: (payload: { statusId: string; silent?: boolean }) => api.putAdminTicketsStatusV1(ticketId, payload),
    onMutate(payload) {
      // Optimistic update
      queryClient.setQueryData(detailToken, (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            status: oldData.data.possibleStatuses.find((x) => x.id === payload.statusId)!,
          },
        };
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.status-change.error"),
      });

      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });

  const replyMutation = useMutation({
    mutationFn: (payload: { data: TicketCommentRequest; fetchAllActivities?: boolean }) =>
      api.postTicketsCommentsV1(ticketId, payload.data),
    async onSuccess(_, payload) {
      queryClient.setQueryData(detailToken, (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            activityCount: (oldData.data.activityCount || 0) + 1,
          },
        };
      });

      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });

      if (payload.fetchAllActivities) {
        setActivitiesAmount(10000);
        await queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, ticketId, activitiesParams),
        });
        setTimeout(() => {
          bottomOfTicketLogRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 50);
      }
    },
    onError(_, payload) {
      showFlashToast({
        type: "error",
        title:
          payload.data.accessType === "internal"
            ? t("page.tickets.details.note.error")
            : t("page.tickets.details.reply.error"),
      });

      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });

  const editReplyMutation = useMutation({
    mutationFn: (payload: { commentId: string; data: EditTicketCommentRequest }) =>
      api.putTicketsCommentsV1(ticketId, payload.commentId, payload.data),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.success") });
      void queryClient.invalidateQueries({ queryKey: detailToken });
    },
    onError() {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.error") });
    },
  });

  const activities = useMemo(
    () => activitiesQuery.data?.pages.flatMap((x) => x.items) || [],
    [activitiesQuery.data?.pages],
  );

  async function createComment({
    content,
    image,
    document,
    internal,
    fetchAllActivities,
  }: {
    content: string;
    image?: FormImage;
    document?: FormDocument;
    internal?: boolean;
    fetchAllActivities?: boolean;
  }) {
    const data: TicketCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
      accessType: internal ? "internal" : "public",
    };

    if (image) {
      const uploadedImage = await uploadFormImage(image);
      if (uploadedImage) {
        data.imageId = uploadedImage.id;
      }
    }
    if (document) {
      const uploadedDocument = await uploadFormDocument(document);

      if (uploadedDocument) {
        data.documentIds = [uploadedDocument.id];
      }
    }

    await replyMutation.mutateAsync({
      data,
      fetchAllActivities,
    });
  }

  async function editComment({
    commentId,
    content,
    image,
    document,
  }: {
    commentId: string;
    content: string;
    image?: FormImage;
    document?: FormDocument;
  }) {
    const data: EditTicketCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
    };

    if (image) {
      const uploadedImage = await uploadFormImage(image);
      if (uploadedImage) {
        data.imageId = uploadedImage.id;
      }
    }
    if (document) {
      const uploadedDocument = await uploadFormDocument(document);

      if (uploadedDocument) {
        data.documentIds = [uploadedDocument.id];
      }
    }

    await editReplyMutation.mutateAsync({
      commentId,
      data,
    });
  }

  useEffect(() => {
    if (isHttpError(ticketDetailsError) && ticketDetailsError.status === 404) {
      navigate(routes.tickets.list({ slug }));
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.errors.not-found"),
      });
    }
  }, [navigate, showFlashToast, t, ticketDetailsError, slug]);

  // Mark as read called everytime ticket is loaded
  const markAsReadMutation = useMutation({
    mutationFn: () => api.postTicketsReadV1(ticketId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" }),
  });

  const markAsRead = markAsReadMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ticket?.id) {
        markAsRead();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [markAsRead, ticket?.hasUnreadActivity, ticket?.hasUnreadComment, ticket?.id]);

  const isChangingStatus = changeStatusMutation.isPending;
  const onToggleActivitySorting = descendingActivitiesHandlers.toggle;
  const isFetchingMoreActivities = activitiesQuery.isFetchingNextPage || activitiesQuery.isPending;
  const hasMoreActivities = activitiesQuery.hasNextPage || false;
  const fetchMoreActivities = activitiesQuery.fetchNextPage;

  const bottomOfTicketLogRef = useRef<HTMLDivElement>(null);
  const statuses =
    ticket?.possibleStatuses.map((status) => ({
      id: status.id,
      description: status.name,
      labelColor: status.color,
      defaultStatusId: status.type,
    })) || [];

  const onChangeStatus = useCallback(
    async (status: TicketStatusDto) => {
      if (status.id === ticket?.status.id) {
        return;
      }

      await changeStatusMutation.mutateAsync({
        statusId: status.id,
        silent: false,
      });

      if (status.type === "inProgress" && ticket?.canAddInternalNote && ticket?.canAddPublicComment) {
        leaveCommentModalOpenHandlers.setTrue();
      }
    },
    [
      changeStatusMutation,
      leaveCommentModalOpenHandlers,
      ticket?.canAddInternalNote,
      ticket?.canAddPublicComment,
      ticket?.status.id,
    ],
  );

  const onChangeAssignee = useCallback(
    (assignee: UserDto) => {
      if (changingAssigneeWillResultIn403(ticket!, assignee, sessionUser)) {
        setWarningBeforeAssigneeChangeUser(assignee);
      } else {
        if (ticket?.canAddInternalNote) {
          setChangedAssignee(assignee);
        } else {
          changeAssigneeMutation.mutate({
            assigneeId: assignee.id,
          });
        }
      }
    },
    [changeAssigneeMutation, sessionUser, ticket],
  );

  async function askStatusChangeOrReply({
    content,
    image,
    document,
    internal,
  }: Omit<Parameters<typeof createComment>[0], "fetchAllActivities">) {
    await createComment({ content, image, document, internal, fetchAllActivities: !descendingActivities });

    const isStatusNewOrRejected = ["new", "rejected"].includes(ticket!.status.type);
    if (!internal && isStatusNewOrRejected && ticket!.canChangeStatus) {
      statusChangeModalOpenHandlers.setTrue();
    }
  }

  const error = ticketDetailsError || activitiesQuery.error;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingTicket) {
    return (
      <div className="flex h-[800px] max-h-screen-minus-16 w-screen max-w-screen-xl">
        <div className="w-full flex-1">
          <FullSizeLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-screen max-w-screen-xl flex-col gap-8 p-4">
      <div className="flex flex-row justify-between gap-2 pr-8">
        <Headline2 className="block text-headline3 leading-relaxed tracking-[-0.008em] md:text-headline2" as="h1">
          {showNextTicketBtn && leftInSession != undefined
            ? t("page.portfolio-tickets.details.title-session", { count: leftInSession })
            : t("page.portfolio-tickets.details.title")}
        </Headline2>
        <TicketReminderButton ticket={ticket} />
      </div>

      <div className="flex flex-col gap-4 xl:flex-row">
        <div className="flex max-w-6xl flex-1 flex-col gap-4">
          <TicketInfoHeader
            isChangingStatus={isChangingStatus}
            ticket={ticket}
            onChangeStatus={onChangeStatus}
            onChangeAssignee={onChangeAssignee}
            sessionUser={sessionUser}
            changedAssignee={changedAssignee}
            showProject
          />
          <TicketContent ticket={ticket} />
          <TicketLog
            activities={activities}
            askStatusChangeOrReply={askStatusChangeOrReply}
            editComment={editComment}
            isActivitiesDescending={descendingActivities}
            onToggleActivitySorting={onToggleActivitySorting}
            isFetchingMoreActivities={isFetchingMoreActivities}
            fetchMoreActivities={fetchMoreActivities}
            hasMoreActivities={hasMoreActivities}
            scrollTargetRef={bottomOfTicketLogRef}
            sessionUser={sessionUser}
            ticket={ticket}
          />
        </div>

        <aside className="flex w-full flex-col gap-4 md:max-w-xs">
          <TicketResidentInfo ticket={ticket} isGray />

          {canSeeServiceHistory(ticket, sessionUser) ? <TicketHistory ticketId={ticket.id} withOutline /> : null}

          {ticket.rems ? (
            <TicketRemsCard
              ticketId={ticket.id}
              ticketIsCollective={ticket.visibility !== "private"}
              rems={ticket.rems}
              withOutline
            />
          ) : null}

          {ticket.homeDna ? <TicketHomeDnaCard ticketId={ticket.id} homeDna={ticket.homeDna} withOutline /> : null}

          {showNextTicketBtn ? (
            <div className="mt-auto flex w-full flex-col items-end">
              <Button className="m-4 self-end" onClick={onGoToNextTicket}>
                {t("page.portfolio-tickets.details.next-button")}
              </Button>
            </div>
          ) : null}
        </aside>
      </div>
      <ConfirmModal
        id="before-assignee-change-modal"
        title={t("page.tickets.before-assignee-change-modal.title")}
        description={t("page.tickets.before-assignee-change-modal.description")}
        isLoading={false}
        isOpen={warningBeforeAssigneeChangeUser !== undefined}
        onReject={() => setWarningBeforeAssigneeChangeUser(undefined)}
        onResolve={() => {
          if (!warningBeforeAssigneeChangeUser) {
            return;
          }

          setWarningBeforeAssigneeChangeUser(undefined);
          if (ticket.canAddInternalNote) {
            setChangedAssignee(warningBeforeAssigneeChangeUser);
          } else {
            changeAssigneeMutation.mutate({
              assigneeId: warningBeforeAssigneeChangeUser.id,
            });
          }
        }}
      />
      <TicketLeaveCommentModal
        title={t("page.tickets.after-status-change-modal.title")}
        description={
          <Trans
            i18nKey="page.tickets.after-status-change-modal.description"
            components={{
              yellow: <span className="text-yellow-darker" />,
            }}
          />
        }
        user={sessionUser}
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={ticket.canAddPublicComment}
        isOpen={isLeaveCommentModalOpen}
        onClose={leaveCommentModalOpenHandlers.setFalse}
        onSubmit={async (value, note) => {
          try {
            await createComment({
              content: value,
              internal: note,
            });
          } finally {
            leaveCommentModalOpenHandlers.setFalse();
          }
        }}
      />
      <TicketLeaveCommentModal
        title={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.title"
            values={{ name: changedAssignee?.fullName }}
            components={{
              bold: <strong className="font-semibold" />,
            }}
          />
        }
        description={
          <Trans
            i18nKey="page.tickets.after-assignee-change-modal.description"
            components={{
              yellow: <span className="text-yellow-darker" />,
            }}
          />
        }
        user={sessionUser}
        canCommentInternal={ticket.canAddInternalNote}
        canCommentPublic={false}
        isOpen={!!changedAssignee}
        onClose={async () => {
          if (changedAssignee) {
            await changeAssigneeMutation.mutateAsync({ assigneeId: changedAssignee.id });
            setChangedAssignee(undefined);
          }
        }}
        onSubmit={async (note) => {
          if (changedAssignee) {
            try {
              await changeAssigneeMutation.mutateAsync({
                assigneeId: changedAssignee.id,
                note,
              });
            } finally {
              setChangedAssignee(undefined);
            }
          }
        }}
        assignee={changedAssignee}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpen={isStatusChangeModalOpen}
        onClose={statusChangeModalOpenHandlers.setFalse}
        onSubmit={async (status) => {
          try {
            if (status.id !== ticket.status.id) {
              await changeStatusMutation.mutateAsync({
                statusId: status.id,
                silent: true,
              });
            }
          } finally {
            statusChangeModalOpenHandlers.setFalse();
          }
        }}
        targetStatus={statuses[0]}
        possibleStatuses={statuses}
      />
    </div>
  );
}
