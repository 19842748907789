import "./polyfills/crypto-randomuuid";

import * as Sentry from "@sentry/react";
import { ErrorPage } from "components/Error/ErrorPage";
import { initializeCookieBot } from "external/cookiebot";
import { initializeGoogleAnalytics } from "external/g-analytics";
import { initializePostHog } from "external/posthog";
import { initializeSentry } from "external/sentry";
import type { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { GlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import React from "react";
import { Async } from "react-async";
import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { router } from "./App";

let posthog: PostHog | undefined = undefined;

async function initialize() {
  // Cookie bot must go first to potentially block cookies
  await initializeCookieBot(() => {
    initializeGoogleAnalytics();
  });

  posthog = initializePostHog();

  if (!("container" in document.documentElement.style)) {
    await import("container-query-polyfill");
  }

  if (import.meta.env.VITE_MOCK_API_IN_DEVELOPMENT === "true") {
    // imported dynamically to avoid being included in production
    console.warn("Mocks enabled");
    const { worker } = await import("./helpers/mocks/browser");
    await worker.start({ onUnhandledRequest: "bypass", quiet: true });
  }
}

initializeSentry();

const initializePromise = initialize();

const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
      <Async promise={initializePromise}>
        <Async.Fulfilled>
          <GlobalLoadingSpinner>
            <PostHogProvider client={posthog}>
              <RouterProvider router={router} />
            </PostHogProvider>
          </GlobalLoadingSpinner>
        </Async.Fulfilled>
      </Async>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
