import type { BookableAssetDto, BookableSlotDto } from "api/types";
import { addDays } from "date-fns";

export function getAllowedViewableDateRange(asset: BookableAssetDto): { minViewDate: Date; maxViewDate: Date } {
  const minViewDate = getMinDate(asset);
  const maxViewDate = getMaxDate(asset);

  return { minViewDate, maxViewDate };
}

export const getTimeslotDateTime = (timeslot: BookableSlotDto): { startTime: Date; endTime: Date } => {
  return {
    startTime: new Date(`${timeslot.date}T${timeslot.startTime}`),
    endTime: new Date(`${timeslot.date}T${timeslot.endTime}`),
  };
};

export function isBeforeOrSame(date1: Date, date2: Date): boolean {
  return date1 <= date2;
}

export function isAfterOrSame(date1: Date, date2: Date): boolean {
  return date1 >= date2;
}

function getMinDate(asset: BookableAssetDto) {
  let minDate = new Date(asset.createdAt);

  if (!asset.canEdit) {
    if (asset.publishAt) {
      const publishAt = new Date(asset.publishAt);
      minDate = minDate > publishAt ? minDate : publishAt;
    }

    if (asset.availableFrom) {
      const availableFrom = new Date(asset.availableFrom);
      minDate = minDate > availableFrom ? minDate : availableFrom;
    }
  }

  return minDate;
}

function getMaxDate(asset: BookableAssetDto) {
  let maxDate = addDays(new Date(), 365);

  if (asset.unpublishAt) {
    const unpublishAt = new Date(asset.unpublishAt);
    maxDate = maxDate > unpublishAt ? unpublishAt : maxDate;
  }

  if (asset.maxDaysInAdvance != null) {
    const toInAdvance = addDays(new Date(), asset.maxDaysInAdvance - 1);
    maxDate = maxDate > toInAdvance ? toInAdvance : maxDate;
  }

  return maxDate;
}

export function getBookingsExportFilename(projectName: string, assetName?: string): string {
  const cleanProjectName = projectName.replaceAll(/[^a-zA-Z0-9()| _-]+/g, "");
  const date = new Date()
    .toISOString()
    .substring(0, 16)
    .replaceAll(/[T\-:]/g, "");
  let fileName = `${date}_${cleanProjectName}.xlsx`;
  if (assetName) {
    const cleanAssetName = assetName.split(" ").join("_");
    fileName = `${cleanAssetName}_${fileName}`;
  }

  return fileName;
}
