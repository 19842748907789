import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { ProjectConnectionDetailsDto, ProjectConnectionGroupDto } from "api/types";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { Capture2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  projectConnection: ProjectConnectionDetailsDto;
  projectConnectionGroups: ProjectConnectionGroupDto[];
  isLoadingProjectConnectionGroups: boolean;
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
  onDelete: (groupId: string) => Promise<unknown>;
}

export function Layout({
  projectConnection,
  projectConnectionGroups,
  isLoadingProjectConnectionGroups,
  page,
  onPageChange,
  totalPages,
  onDelete,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const { id: connectionId } = useParams<{ id: string }>();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("pc-group-delete-modal");

  const columns = useMemo(() => {
    const helper = createColumnHelper<ProjectConnectionGroupDto>();

    return [
      helper.accessor("translations", {
        header: t("page.project-connection-groups.list.table.name"),
        cell: (cell) => (
          <span className="line-clamp-2">
            {cell.getValue()?.find((l) => l.languageId === sessionUser.language.id)?.name}
          </span>
        ),
      }),
      helper.accessor("type", {
        header: t("page.project-connection-groups.list.table.type"),
        cell: (cell) => {
          switch (cell.getValue()) {
            case "interest":
              return (
                <span className="whitespace-nowrap">
                  {t("page.project-connection-groups.form.type.option.interest-group")}
                </span>
              );
            case "helpCategory":
              return (
                <span className="whitespace-nowrap">
                  {t("page.project-connection-groups.form.type.option.help-category")}
                </span>
              );
          }
        },
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();

          const actions: ContextMenuAction[] = [
            {
              callback: () => navigate(routes.projectConnections.editGroup({ id: connectionId!, gid: id })),
              text: t("page.project-connection-groups.list.table.edit"),
            },
            {
              callback: () => openDeleteModal(id),
              text: t("page.project-connection-groups.list.table.delete"),
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [t, navigate, sessionUser, connectionId, openDeleteModal]);

  const tableInstance = useReactTable<ProjectConnectionGroupDto>({
    columns,
    data: projectConnectionGroups,
    getCoreRowModel: getCoreRowModel(),
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({
    module: "project-connections",
    subModule: projectConnection.name,
    status: "list-group",
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.project-connection-groups.list.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <Button type="link" href={routes.projectConnections.createGroup({ id: connectionId! })}>
          {t("page.project-connection-groups.list.new-group")}
        </Button>
      }
    >
      {projectConnectionGroups.length ? (
        <Table table={tableInstance} isLoading={isLoadingProjectConnectionGroups}>
          <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
        </Table>
      ) : isLoadingProjectConnectionGroups ? null : (
        <div className="rounded-lg bg-white p-5">
          <Capture2>{t("page.project-connection-groups.list.table.no-data")}</Capture2>
        </div>
      )}
      <DeleteModal
        title={t("page.project-connection-groups.modal.delete.title")}
        description={t("page.project-connection-groups.modal.delete.description")}
        onDelete={(groupId) => onDelete(groupId)}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
