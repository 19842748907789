import { Button } from "components/Button/Button";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { Select } from "components/Select/Select";
import { Headline4 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TicketStatus } from "./TicketStatus";

type Status = {
  id: string;
  description: string;
  labelColor: string;
  defaultStatusId: string;
};

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  onClose?: () => Promise<void> | void;
  targetStatus: Status;
  possibleStatuses: Status[];
  onSubmit?: (status: Status) => Promise<void> | void;
}

export function TicketStatusChangeModal({
  isOpen,
  title,
  description,
  onClose,
  onSubmit,
  targetStatus,
  possibleStatuses,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const [submitting, submittingHandlers] = useBool();
  const [status, setStatus] = useState<Status>();

  useEffect(() => {
    setStatus(targetStatus);
  }, [targetStatus]);

  async function close(submit: boolean) {
    if (submitting) {
      return;
    }

    submittingHandlers.setTrue();

    try {
      if (submit && status) {
        await onSubmit?.(status);
      } else {
        await onClose?.();
      }
    } finally {
      submittingHandlers.setFalse();
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => close(false)} shouldCloseOnEsc overflowYVisible>
      <div className="max-w-md rounded p-4 text-center" data-testid="quick-repair-request-status-modal">
        <Headline4 className="my-4 block">{title}</Headline4>
        <p className="mx-1 my-4">{description}</p>
        <div className="mx-auto w-full min-w-64">
          <Select
            placeholder={t("model.repair-request.status-change-label")}
            selected={status}
            disabled={submitting}
            items={possibleStatuses}
            onChange={setStatus}
            keySelector={(x) => x.id}
            renderOption={(status) => <TicketStatus {...status} />}
          />
        </div>
        {submitting ? (
          <LoadingIcon className="mx-auto mb-2 mt-4 w-8" />
        ) : (
          <div className="mt-6 flex w-full items-center justify-end gap-2">
            <Button
              styling="secondary"
              onClick={() => close(false)}
              data-testid="quick-repair-request-status-modal-reject"
            >
              {t("common.action.no-thanks")}
            </Button>
            <Button onClick={() => close(true)} data-testid="quick-repair-request-status-modal-apply">
              {t("common.action.change")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
