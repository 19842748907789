import type { CheckboxProps } from "components/Checkbox/Checkbox";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Tooltip } from "components/Tooltip/Tooltip";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";
import { twJoin } from "tailwind-merge";

export interface FormCheckboxProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<CheckboxProps, "checked"> {
  "data-testid"?: string;
  name: TName;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  label: string;
  tooltip?: string;
  rules?: RegisterOptions;
  memorise?: boolean;
  alignTop?: boolean;
}

export function FormCheckbox<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  "data-testid": dataTestId,
  name,
  rules,
  defaultValue,
  memorise,
  color,
  shape,
  alignTop,
  ...props
}: FormCheckboxProps<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules, defaultValue });
  const colorStyle = memorise == null ? color : memorise === field.value ? "blue" : "green";

  return (
    <Tooltip tooltip={props.tooltip ?? ""}>
      <label className={twJoin("inline-flex gap-x-2", alignTop ? "items-start" : "items-center")}>
        {props.label && <span className={"order-1"}>{props.label}</span>}
        <div className={twJoin("flex", alignTop ? "mt-1" : undefined)}>
          <Checkbox
            data-testid={dataTestId}
            {...field}
            {...props}
            name={name}
            checked={!!field.value}
            shape="square"
            color={colorStyle}
          />
        </div>
      </label>
    </Tooltip>
  );
}
