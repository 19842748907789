import { LegendDataComponent } from "components/Charts/LegendDataComponent";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { isDefined } from "helpers/util";
import { zipWith } from "lodash-es";
import { useTranslation } from "react-i18next";
import * as V from "victory";

import { useElementScale } from "../hooks/useElementScale";
import { chartTheme, dataColors, fontStyling } from "../theme";
import { splitLine } from "../util";
import { createDateTickFormat, createXAxis, createYAxis } from "./utility/Axis";
import { HoverLabel } from "./utility/HoverLabel";

interface DataPoint {
  x: string;
  y: number | undefined;
}

type NonEmptyDataPoint<T extends DataPoint> = T & { y: NonNullable<T["y"]> };

interface ZippedData<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint> {
  x: string;
  benchmark?: DataPoint;
  primary?: TPrimaryDataPoint;
  secondary?: TSecondaryDataPoint;
}

interface Props<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint> {
  minY?: number;
  maxY?: number;
  type: "text" | "date";
  primaryData: TPrimaryDataPoint[];
  primaryLabel?: string;
  benchmark?: DataPoint[];
  benchmarkLabel?: string;
  secondaryLabel?: string;
  secondaryData?: TSecondaryDataPoint[];
  renderLabel?: (data: ZippedData<TPrimaryDataPoint, TSecondaryDataPoint>, isSecondary: boolean) => React.ReactNode;
  formatXTick?: (y: NonEmptyDataPoint<TPrimaryDataPoint>["x"]) => string | number;
  formatYTick?: (y: NonEmptyDataPoint<TPrimaryDataPoint>["y"]) => string | number;
  xLabel?: string;
  yLabel?: string;
  showLegend?: boolean;
  skipXTicksModulo?: number;
  width?: number;
  height?: number;
  labelOrientation?: "horizontal" | "vertical";
}

const CHART_WIDTH = 400;
const CHART_HEIGHT = 350;
const LABEL_OFFSET_X = 12;
const LABEL_OFFSET_Y = -16;

export function BarChart<TPrimaryDataPoint extends DataPoint, TSecondaryDataPoint extends DataPoint>({
  minY = 0,
  maxY,
  type,
  benchmark,
  benchmarkLabel,
  primaryData,
  primaryLabel,
  secondaryLabel,
  secondaryData,
  formatYTick,
  formatXTick,
  renderLabel,
  xLabel,
  yLabel,
  showLegend,
  skipXTicksModulo = 1,
  width = CHART_WIDTH,
  height = CHART_HEIGHT,
  labelOrientation = "horizontal",
}: Props<TPrimaryDataPoint, TSecondaryDataPoint>): React.ReactNode {
  const { scale, ref } = useElementScale<HTMLDivElement>(width);
  const { i18n } = useTranslation();

  const barWidth = secondaryData && primaryData.length > 10 ? 12 : 16;

  const data: ZippedData<TPrimaryDataPoint, TSecondaryDataPoint>[] = zipWith(
    benchmark || [],
    primaryData,
    secondaryData || [],
    (benchmark, primary, secondary) => ({
      x: benchmark ? benchmark.x : primary.x,
      benchmark,
      primary,
      secondary,
    }),
  );

  return (
    <div ref={ref}>
      <V.VictoryChart
        minDomain={{ y: minY }}
        maxDomain={isDefined(maxY) ? { y: maxY } : undefined}
        theme={chartTheme}
        width={scale * width}
        height={scale * height}
        padding={{
          top: 38,
          right: 8,
          bottom: 80,
          left: 72,
        }}
        domainPadding={{ x: 24, y: [0, 120] }}
        categories={{ x: data.map((d) => d.x) }}
      >
        {createYAxis({ tickCount: 6, tickFormat: formatYTick, label: yLabel })}
        {createXAxis(
          {
            tickFormat:
              type === "text"
                ? (x, idx) => (idx % skipXTicksModulo === 0 ? (formatXTick ? formatXTick(x) : x) : " ")
                : createDateTickFormat(i18n, data.length, scale),
            label: xLabel,
          },
          labelOrientation === "vertical"
            ? {
                dx: -38,
                dy: -8,
                angle: -90,
                textAnchor: "middle",
              }
            : undefined,
        )}

        {showLegend && (
          <V.VictoryLegend
            style={{ labels: fontStyling }}
            x={38}
            dataComponent={<LegendDataComponent />}
            data={[
              {
                name: primaryLabel,
                symbol: {
                  fill: dataColors.primary,
                  type: "square",
                },
              },
              secondaryData
                ? {
                    name: secondaryLabel,
                    symbol: {
                      fill: dataColors.secondary,
                      type: "square",
                    },
                  }
                : undefined,
              benchmark
                ? {
                    name: benchmarkLabel,
                    symbol: {
                      fill: dataColors.benchmark,
                      type: "minus",
                    },
                  }
                : undefined,
            ].filter(isDefined)}
            gutter={24}
            symbolSpacer={8}
            orientation="horizontal"
          />
        )}

        <V.VictoryGroup offset={barWidth * scale}>
          <V.VictoryBar
            barWidth={barWidth * scale}
            style={{ data: { fill: dataColors.primary, strokeWidth: 0 } }}
            labelComponent={
              <V.VictoryTooltip
                text=""
                flyoutComponent={
                  <HoverLabel<(typeof data)[number]>
                    offsetX={LABEL_OFFSET_X * scale}
                    offsetY={LABEL_OFFSET_Y * scale}
                    containerWidth={width * scale}
                  >
                    {(data) => (
                      <>
                        {type === "text" ? null : <FormattedDate format="monthYear" date={data.x} />}
                        {renderLabel && renderLabel(data, false)}
                      </>
                    )}
                  </HoverLabel>
                }
              />
            }
            labels={(d) => d}
            y={(d: (typeof data)[number]) => d.primary?.y || 0}
            data={data.map((d) => (isDefined(d.primary?.y) ? d : undefined)).filter(isDefined)}
          />
          {secondaryData ? (
            <V.VictoryBar
              barWidth={barWidth * scale}
              style={{ data: { fill: dataColors.secondary, strokeWidth: 0 } }}
              labelComponent={
                <V.VictoryTooltip
                  text=""
                  flyoutComponent={
                    <HoverLabel<(typeof data)[number]>
                      offsetX={LABEL_OFFSET_X * scale}
                      offsetY={LABEL_OFFSET_Y * scale}
                      containerWidth={width * scale}
                    >
                      {(data) => (
                        <>
                          {type === "text" ? null : <FormattedDate format="monthYear" date={data.x} />}
                          {renderLabel && renderLabel(data, true)}
                        </>
                      )}
                    </HoverLabel>
                  }
                />
              }
              labels={(d) => d}
              y={(d: (typeof data)[number]) => d.secondary?.y || 0}
              data={data.map((d) => (isDefined(d.secondary?.y) ? d : undefined)).filter(isDefined)}
            />
          ) : null}
        </V.VictoryGroup>

        {benchmark ? (
          <V.VictoryGroup>
            {splitLine(benchmark, (d) => d.y).map((line) => (
              <V.VictoryLine
                key={JSON.stringify(line)}
                style={{ data: { pointerEvents: "none", strokeWidth: 2, stroke: dataColors.benchmark } }}
                data={line.map((d) => (isDefined(d?.y) ? d : undefined)).filter(isDefined)}
                labels={[]}
              />
            ))}
          </V.VictoryGroup>
        ) : null}
      </V.VictoryChart>
    </div>
  );
}
