import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type { AssetBookingDto } from "api/types";
import locationIcon from "assets/icons/marker-pin-01.svg";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { format, parse, parseISO } from "date-fns";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canViewSchedule } from "modules/bookings/permissions";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export function ReservationCard({
  reservation,
  isPast,
}: {
  reservation: AssetBookingDto;
  isPast?: boolean;
}): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const slug = useSlug();
  const navigate = useNavigate();
  const hasPermission = usePermission();
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();
  const showFlashToast = useFlashToast();
  const resolveImage = useImageResolver();

  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-reservation-modal");

  const deleteReservation = useMutation({
    mutationFn: () =>
      api
        .deleteBookableAssetsBookingsByIdV1(reservation.id, reservation.asset.id, { reason: undefined })
        .then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.reservation-details.delete.notification.success"),
      });

      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId),
      });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, reservation.asset.id, reservation.id),
      });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.reservation-details.delete.notification.error"),
      }),
  });

  const actions: ContextMenuAction[] = useMemo(() => {
    const actions = [];
    if (reservation.canEdit && !reservation.cancelledAt) {
      actions.push({
        dataTestId: "context-menu-edit-btn",
        text: t("page.bookings.reservations.actions.edit"),
        callback: () => navigate(routes.bookings.editBooking({ slug, aid: reservation.asset.id, bid: reservation.id })),
      });
    }
    if (reservation.canDelete && !reservation.cancelledAt) {
      actions.push({
        dataTestId: "context-menu-delete-btn",
        text: t("page.bookings.reservations.actions.cancel"),
        callback: () => openDeleteModal(reservation.id),
      });
    }

    return actions;
  }, [t, navigate, reservation, openDeleteModal, slug]);

  const isCancelled = !!reservation.cancelledAt;

  return (
    <>
      <div
        data-testid="reservation-card"
        className="relative grid cursor-pointer grid-cols-[7fr_3fr] grid-rows-1 rounded-lg border border-grey-lightest bg-white shadow-sm hover:shadow-lg"
        onClick={() =>
          hasPermission(canViewSchedule)
            ? navigate(routes.reservations.reservationDetails({ slug, aid: reservation.asset.id, rid: reservation.id }))
            : navigate(routes.calendar.reservationDetails({ slug, aid: reservation.asset.id, rid: reservation.id }))
        }
      >
        <div className="flex size-full items-center gap-3 py-2.5 pl-4">
          {/* Date */}
          <div className="flex h-full flex-col items-center justify-center gap-1 rounded-lg border border-grey-lightest px-2 py-1">
            <Capture2 className="text-grey">{formatDate(i18n, "weekDayShort", parseISO(reservation.date))}</Capture2>
            <Headline4 className={isPast ? "text-grey-dark" : undefined}>
              {parseISO(reservation.date).getDate()}
            </Headline4>
            <Subtitle2 className="font-normal text-grey">
              {formatDate(i18n, "monthShort", parseISO(reservation.date))}
            </Subtitle2>
          </div>
          {/* Info */}
          <div className="flex grow flex-col items-start justify-center gap-1">
            <Subtitle2 className={isPast ? "text-grey-dark" : undefined}>{reservation.asset.name}</Subtitle2>
            <Capture2 className="text-grey-darker">{`${format(parse(reservation.startTime, "HH:mm:ss", new Date()), "HH:mm")} - ${format(parse(reservation.endTime, "HH:mm:ss", new Date()), "HH:mm")}`}</Capture2>
            {reservation.asset.locationSpecification ? (
              <span className="flex items-center gap-1 text-grey-darker">
                <Icon name={locationIcon} />
                <Capture2 className="line-clamp-1">{reservation.asset.locationSpecification}</Capture2>
              </span>
            ) : null}
          </div>
          <div className="h-full">
            {/* Action menu */}
            <ContextMenu actions={actions} />
          </div>
        </div>
        {/* Image */}
        <div
          className="relative size-full rounded-r-lg bg-grey-lighter bg-cover bg-center"
          style={{
            backgroundImage: `url(${reservation.asset.images[0] ? resolveImage(reservation.asset.images[0], "large") : ""})`,
          }}
        >
          {isCancelled && <Label theme="red">{t("page.bookings.reservations.reservation-cancelled")}</Label>}
        </div>
      </div>
      <DeleteModal
        title={t("page.bookings.reservation-details.delete.modal.title")}
        description={t("page.bookings.reservation-details.delete.modal.description")}
        onDelete={() => deleteReservation.mutateAsync()}
        rejectBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.keep-reservation"),
          "data-testid": "modal-cancel-delete",
        }}
        deleteBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.cancel-reservation"),
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </>
  );
}
