import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AutomatedSurveyQueueDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { parseISO } from "date-fns";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface LayoutProps {
  isLoading: boolean;
  automatedSurveys: AutomatedSurveyQueueDto[];
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export function Layout({ automatedSurveys, isLoading, totalPages, page, onPageChange }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const helper = createColumnHelper<AutomatedSurveyQueueDto>();

    return [
      helper.accessor("title", {
        header: t("page.automated-survey-queues.table.name"),
        cell: (cell) => (
          <Anchor to={routes.automatedSurveyQueues.queueDetails({ slug, id: cell.row.original.id })}>
            {cell.getValue()}
          </Anchor>
        ),
      }),
      helper.accessor("isActive", {
        header: t("page.automated-survey-queues.table.state"),
        cell: (cell) => {
          const isEnded = parseISO(cell.row.original.endDate) < new Date();
          const isActive = cell.getValue();

          return (
            <span className="flex items-center">
              <span
                className={twJoin(
                  "mr-2 block size-2.5 shrink-0 rounded-full",
                  isEnded ? "bg-aop-basic-blue" : isActive ? "bg-green-dark" : "bg-yellow",
                )}
              />
              <span className="whitespace-nowrap">
                {isEnded
                  ? t("page.automated-survey-queues.table.state.ended")
                  : isActive
                    ? t("page.automated-survey-queues.table.state.active")
                    : t("page.automated-survey-queues.table.state.inactive")}
              </span>
            </span>
          );
        },
      }),
      helper.accessor("numberLeftInQueue", {
        header: t("page.automated-survey-queues.table.number-left-in-queue"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("numberResponses", {
        header: t("page.automated-survey-queues.table.number-of-responses"),
        cell: (cell) => <span>{cell.getValue()}</span>,
      }),
      helper.accessor("startDate", {
        header: t("page.automated-survey-queues.table.start-date"),
        cell: (cell) => (
          <span className="whitespace-nowrap">
            {cell.getValue() ? <FormattedDate date={cell.getValue()} format="dateMonthYearShort" /> : <span>-</span>}
          </span>
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: () => null,
      }),
    ];
  }, [t, slug]);

  const tableInstance = useReactTable<AutomatedSurveyQueueDto>({
    columns,
    data: automatedSurveys,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.automated-survey-queues.list.title")}
      subTitle={t("page.automated-survey-queues.list.description")}
      header={t("page.automated-survey-queues.list.header")}
    >
      {automatedSurveys.length ? (
        <Table table={tableInstance} isLoading={isLoading}>
          <Pagination count={totalPages} onChange={onPageChange} currentIndex={page} />
        </Table>
      ) : isLoading ? null : (
        <div className="rounded-lg bg-white p-5">
          <p>{t("page.automated-survey-queues.list.empty")}</p>
        </div>
      )}
    </DocumentPaper>
  );
}
