import type { IConfigCatClient, User } from "configcat-common";
import { PollingMode } from "configcat-common";
import { DataGovernance, getClient } from "configcat-js";

export interface Config {
  newCoreApiRootUri: string;
  newDashboardRootUrl: string;
  azureAdB2cInstance: string;
  azureAdB2cDomain: string;
  azureAdB2cNewDashboardClientId: string;
  azureAdB2cCoreApiScope: string;
  azureAdB2cDashboardSignInPolicyId: string;
  azureAdB2cPoliciesSignUpV2: string;
  azureApplicationInsightsInstrumentationKey: string;
  envLongName: string;
  pusherDashboardAppKey: string;
  intercomDashboardAppId: string;
  preprDashboardUrl: string;
  authenticationServerRootUrl: string;
  enablePortfolioEngagementStat: boolean;
  allowRealEstateGroups: boolean;
  allowBookings: boolean;
  useSignalR: boolean;
  showTicketPortfolioSettings: boolean;
  isAiToolingAvailable: boolean;
}

const developmentConfig: {
  [T in keyof Config]: Config[T] | undefined;
} = {
  newCoreApiRootUri: import.meta.env.VITE_API_BASE_URL,
  newDashboardRootUrl: import.meta.env.VITE_DASHBOARD_ROOT_URL,
  azureAdB2cInstance: import.meta.env.VITE_AZURE_AD_INSTANCE,
  azureAdB2cDomain: import.meta.env.VITE_AZURE_AD_DOMAIN,
  azureAdB2cNewDashboardClientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
  azureAdB2cCoreApiScope: import.meta.env.VITE_AZURE_AD_SCOPE,
  azureAdB2cDashboardSignInPolicyId: import.meta.env.VITE_AZURE_AD_SIGN_IN_POLICY_ID,
  azureAdB2cPoliciesSignUpV2: import.meta.env.VITE_AZURE_AD_SIGN_UP_POLICY_ID,
  azureApplicationInsightsInstrumentationKey: undefined,
  envLongName: import.meta.env.VITE_MOCK_API_IN_DEVELOPMENT === "true" ? "Mock" : "Local",
  pusherDashboardAppKey: import.meta.env.VITE_PUSHER_APP_KEY,
  intercomDashboardAppId: import.meta.env.VITE_INTERCOM_APP_ID,
  preprDashboardUrl: import.meta.env.VITE_PREPR_DASHBOARD_URL,
  authenticationServerRootUrl: import.meta.env.VITE_AUTHENTICATION_SERVER_ROOT_URL,
  enablePortfolioEngagementStat: true,
  allowRealEstateGroups: true,
  allowBookings: true,
  useSignalR: true,
  showTicketPortfolioSettings: true,
  isAiToolingAvailable: true,
};

let configClient: {
  snapshot: () => {
    getValue<T extends keyof Config, TValue extends Config[T] = Config[T]>(
      key: T,
      defaultValue?: TValue,
      user?: User,
    ): Config[T];
  };
  getAllKeysAsync: IConfigCatClient["getAllKeysAsync"];
};

if (import.meta.env.DEV || import.meta.env.MODE === "test" || import.meta.env.STORYBOOK === "true") {
  configClient = {
    snapshot: () => ({
      getValue: (key) => developmentConfig[key] as any,
    }),
    getAllKeysAsync: () => Promise.resolve(Object.keys(developmentConfig)),
  };
} else {
  // Use dynamic config cat key configuration in non development environments
  const configCatSDKKeyProperty = "AOP_CONFIGCAT_KEY";

  if (typeof window[configCatSDKKeyProperty] !== "string" || !window[configCatSDKKeyProperty]) {
    throw new Error(
      `Configuration Problem: Incorrect configcat SDK key. In production the configcat SDK key for the API has to be set on the window instance at 'window.${configCatSDKKeyProperty}' as string, found '${window[configCatSDKKeyProperty] as any}'.`,
    );
  }

  configClient = getClient(window[configCatSDKKeyProperty], PollingMode.LazyLoad, {
    dataGovernance: DataGovernance.EuOnly,
    cacheTimeToLiveSeconds: 600,
  }) as typeof configClient;
}

export async function getConfigSnapshot(): Promise<ReturnType<(typeof configClient)["snapshot"]>> {
  // Make sure configcat has loaded the configuration. Better than forceRefreshAsync,
  // because it's not forcing a refresh and it can potentially rely on cached values.
  await configClient.getAllKeysAsync();

  return configClient.snapshot();
}

export type ConfigSnapshot = Awaited<ReturnType<typeof getConfigSnapshot>>;
export type ConfigUser = User;
