import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AddMemberRequest, UserDetailsWithAddressDto, UserDto } from "api/types";
import backIcon from "assets/icons/chevron-left.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture1, Capture2, Headline4, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { MIN_GROUP_SEARCH_CHARACTERS } from "modules/chats/components/CreateChatModal";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { EditGroupChatFormValues } from "./EditGroupChatModal";

const DEBOUNCE_WAIT = 200;
const AVAILABLE_USERS_PAGE = 20;

interface EditGroupChatModalAddMembersViewProps {
  chatId: string;
  isOpen: boolean;
  onNavigateBack: () => void;
}

export function EditGroupChatModalAddMembersView({
  chatId,
  isOpen,
  onNavigateBack,
}: EditGroupChatModalAddMembersViewProps): React.ReactNode {
  const [query, setQuery] = useState<string>("");

  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();

  const formMethods = useFormContext<EditGroupChatFormValues>();
  const debouncedQuery = useDeferredValue(
    useDebounce(query.trim().length < MIN_GROUP_SEARCH_CHARACTERS ? "" : query, DEBOUNCE_WAIT),
  );

  const { data: members, isPending: isPendingMembers } = useQuery({
    queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatId),
    queryFn: () => api.getChatsMembersV2(chatId),
    select: commonAPIDataSelector,
    enabled: isOpen,
  });
  const {
    data: availableUsersData,
    hasNextPage: hasMoreUsers,
    fetchNextPage: fetchMoreUsers,
    isLoading: isLoadingUsers,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_AVAILABLE_USERS(projectId, debouncedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsAvailableUsersV1({
          search: debouncedQuery,
          Offset: pageParam * AVAILABLE_USERS_PAGE,
          Limit: AVAILABLE_USERS_PAGE,
        })
        .then(commonAPIDataSelector),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? pages.length : undefined),
    enabled: isOpen,
    staleTime: 30 * 1000, // 30 seconds
  });
  const { mutateAsync: addMembers, isPending: isAddingMembers } = useMutation({
    mutationFn: (payload: AddMemberRequest) => api.putChatsMembersAddV2(chatId, payload),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatId) });
    },
    onSuccess: () => {
      onNavigateBack();
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-info-modal.add-user.error"),
      });
    },
  });

  useEffect(() => {
    setQuery("");

    return () => setQuery("");
  }, []);

  const onClickRemoveSelectedUser = (user: UserDto) => {
    const selectedUsers = formMethods.getValues("newMembers");

    formMethods.setValue(
      "newMembers",
      selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
    );
  };

  const onClickConfirmAddMembers = async () => {
    await addMembers({ userIds: selectedUsers.map((selectedUser) => selectedUser.id) });

    formMethods.setValue("newMembers", []);
  };

  const availableUsers = useMemo(
    () => availableUsersData?.pages.flatMap((page) => page.items) ?? [],
    [availableUsersData],
  );
  const selectedUsers = formMethods.watch("newMembers");
  const existingMembers = members || [];
  const amountTotalUsers = availableUsersData?.pages[0].total || 0;

  return (
    <div className="flex w-full flex-1 flex-col gap-6">
      {/* Header */}
      <div className="flex items-center gap-2">
        <IconButton title={t("common.action.back")} styling="tertiary" onClick={onNavigateBack} withTooltip={false}>
          <Icon name={backIcon} size={24} />
        </IconButton>
        <Headline4 as="h2">{t("page.chats.modal.new-chat.group-chat.select-member.title")}</Headline4>
      </div>
      {/* Search */}
      <SearchInput
        data-testid="search-input"
        onChange={(e) => setQuery(e.target.value.trim())}
        placeholder={
          sessionUser.project.type === "addressBased"
            ? t("page.chats.modal.new-chat.input-placeholder.address-based")
            : t("page.chats.modal.new-chat.input-placeholder.company-based")
        }
      />
      {/* Newly selected users */}
      {selectedUsers.length > 0 && (
        <div className="flex h-fit max-w-full shrink-0 gap-6 overflow-x-auto rounded-lg border border-grey-lighter bg-grey-lightest p-4">
          {selectedUsers.map((user) => (
            <div key={user.id} className="flex flex-col items-center gap-2 text-center">
              <UserAvatar
                key={user.id}
                img={user.avatar}
                onDelete={() => onClickRemoveSelectedUser(user)}
                className="size-16"
                showDeleteButton
              />
              <Overline2>{user.firstName}</Overline2>
            </div>
          ))}
        </div>
      )}

      <EditGroupChatModalAddMembersListView
        members={existingMembers}
        users={availableUsers}
        isLoading={isLoadingUsers || isPendingMembers}
        isMoreUsersAvailable={hasMoreUsers}
        onLoadMoreUsers={fetchMoreUsers}
        {...{ amountTotalUsers }}
      />

      <div className="flex w-full justify-end">
        <Button
          styling="primary"
          isLoading={isAddingMembers}
          disabled={selectedUsers.length === 0}
          onClick={onClickConfirmAddMembers}
        >
          {t("common.action.confirm")}
        </Button>
      </div>
    </div>
  );
}

interface EditGroupChatModalAddMembersListViewProps {
  members: UserDetailsWithAddressDto[];
  users: UserDetailsWithAddressDto[];
  amountTotalUsers: number;
  isLoading: boolean;
  isMoreUsersAvailable: boolean;
  onLoadMoreUsers: () => void;
}

const EditGroupChatModalAddMembersListView = ({
  members,
  users,
  amountTotalUsers,
  isLoading,
  isMoreUsersAvailable,
  onLoadMoreUsers,
}: EditGroupChatModalAddMembersListViewProps) => {
  const { t } = useTranslation();
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      onLoadMoreUsers();
    }, [onLoadMoreUsers]),
  });
  const formMethods = useFormContext<EditGroupChatFormValues>();
  const selectedUsers = useWatch<EditGroupChatFormValues, "newMembers">({ name: "newMembers" });

  const onToggleSelectUser = (isSelected: boolean, user: UserDetailsWithAddressDto) => {
    const currSelectedUser = formMethods.getValues("newMembers");

    let newSelectedUsers = [...currSelectedUser];
    if (isSelected) newSelectedUsers.push(user);
    else newSelectedUsers = newSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id);

    formMethods.setValue("newMembers", newSelectedUsers);
  };

  return (
    <div className="flex size-full flex-col gap-2">
      <Capture1>{t("page.chats.modal.new-chat.total-results", { count: amountTotalUsers })}</Capture1>
      <div className="flex h-80 w-full flex-col items-center justify-center">
        {/* Loading state */}
        {isLoading && <LoadingIcon className="w-6" />}
        {/* Empty state */}
        {!isLoading && amountTotalUsers === 0 && (
          <Capture2 className="text-grey-dark" as="p">
            {t("page.chats.modal.new-chat.no-results")}
          </Capture2>
        )}
        {/* Contentful state */}
        {!isLoading && amountTotalUsers > 0 && (
          <ul className="size-full overflow-y-auto rounded-lg border border-grey-lighter">
            {users.map((user) => {
              const isUserMember = members.some((member) => member.id === user.id);
              const isUserSelected = !!selectedUsers.find((selectedUser) => selectedUser.id === user.id);

              return (
                <li
                  key={user.id}
                  data-testid="available-user"
                  className="cursor-pointer border-b border-b-grey-lighter last:border-b-0 odd:bg-grey-lightest hover:bg-aop-basic-blue-lightest"
                >
                  <label
                    htmlFor={`user-${user.id}`}
                    className="flex w-full cursor-pointer items-center justify-between p-4"
                  >
                    <div className="flex grow items-center gap-3">
                      <div className="size-10 shrink-0">
                        <UserAvatar img={user.avatar} />
                      </div>
                      <div className="text-sm">
                        <span className="font-semibold text-black">{user.fullName}</span>
                        <p className="text-grey">
                          <span>{user.locatedAt}</span>
                        </p>
                      </div>
                    </div>
                    <Checkbox
                      id={`user-${user.id}`}
                      name="groupMembers"
                      checked={isUserMember || isUserSelected}
                      onChange={(e) => onToggleSelectUser(e.target.checked, user)}
                      shape="rounded"
                      color="green"
                      disabled={isUserMember}
                    />
                  </label>
                </li>
              );
            })}
            {isMoreUsersAvailable && (
              <div className="p-4" ref={ref}>
                <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
