import { Anchor, AnchorButton } from "components/Anchor/Anchor";
import { useSlug } from "hooks/useSlug";
import { useForbiddenPopupContext } from "providers/ForbiddenPopupProvider";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes";

interface Props {
  user: { id: string; projectId: string; canViewProfile: boolean };
  asAnchor?: boolean;
  children: ReactNode;
  "data-testid"?: string;
}

export function UserNameLink({ user, children, asAnchor, ...props }: Props): React.ReactNode {
  const slug = useSlug();
  const forbiddenContext = useForbiddenPopupContext();

  if (user.canViewProfile) {
    const Component = asAnchor ? Anchor : Link;

    return (
      <Component to={routes.users.details({ slug, id: user.id })} {...props}>
        {children}
      </Component>
    );
  } else {
    const Component = asAnchor ? AnchorButton : "button";

    return (
      <Component onClick={forbiddenContext.open} {...props}>
        {children}
      </Component>
    );
  }
}
