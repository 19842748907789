import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentsRootDto, HttpResponse } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId),
    queryFn: () => api.getDocumentsV1(),
    select: rootFoldersSelector,
  });
  const { mutateAsync: deleteRootFolder } = useMutation({
    mutationFn: api.deleteDocumentsRootFolderByIdV1,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId) });
      showFlashToast({
        type: "success",
        title: t("model.document.root.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.root.action.delete.notification.failure"),
      });
    },
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      {isLoading ? (
        <FullSizeLoader withPadding />
      ) : (
        props.children({
          rootList: data,
          onDeleteFolder: deleteRootFolder,
        })
      )}
    </>
  );
}

function rootFoldersSelector(data: HttpResponse<DocumentsRootDto>) {
  return commonAPIDataSelector(data).rootFolders;
}
