import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { useDeleteRole } from "modules/roles/hooks/useDelete";
import { QUERY_KEYS } from "query-keys";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const slug = useSlug();
  const { id: roleId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: roleDetails,
    isFetching: isRoleDetailsLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_DETAILS(projectId, roleId!),
    queryFn: () => api.getPermissionsRolesDetailsV1(roleId!).then((x) => x.data),
  });
  const { mutateAsync: deleteRole } = useDeleteRole({
    onSuccess: () => {
      navigate(routes.roles.list({ slug }));
    },
  });
  const isPageLoading = isRoleDetailsLoading;

  if (error) {
    return <ErrorPage error={error} />;
  }

  return isPageLoading ? (
    <FullSizeLoader withPadding />
  ) : (
    <>{roleDetails ? props.children({ roleDetails: roleDetails, deleteRole }) : null}</>
  );
}
