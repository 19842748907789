import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormColorPicker } from "components/Form/FormColorPicker";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormRadio } from "components/Form/FormRadio";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Subtitle2 } from "components/Text/Text";
import { validateSize } from "helpers/file-size";
import { usePermission } from "hooks/usePermission";
import { canManageProjectManagement } from "modules/project/permissions";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  defaultValues?: Partial<FormValues>;
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
}

export function Layout({ defaultValues, isSubmitting, onSubmit }: LayoutProps): React.ReactNode {
  const formMethods = useForm<FormValues>({ defaultValues });
  const { t } = useTranslation();

  return (
    <DocumentPaper theme="wide" title={t("page.project-styling.title")}>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <EditForm isSubmitting={isSubmitting} formMethods={formMethods} />
      </Form>
    </DocumentPaper>
  );
}

function EditForm({ isSubmitting, formMethods }: { isSubmitting: boolean; formMethods: UseFormReturn<FormValues> }) {
  const { t } = useTranslation();
  const {
    selectedSplashScreenLogo,
    splashScreenBackground,
    useBackgroundImageForSplashScreen,
    background,
    logoLight,
    logoDark,
  } = useWatch<FormValues>();
  const hasPermission = usePermission();

  useEffect(() => {
    if (
      (!background?.length || (splashScreenBackground && splashScreenBackground.length > 0)) &&
      useBackgroundImageForSplashScreen
    ) {
      formMethods.setValue("useBackgroundImageForSplashScreen", false);
    }
  }, [background, splashScreenBackground, useBackgroundImageForSplashScreen, formMethods]);

  useEffect(() => {
    if (
      !logoLight ||
      (!logoLight.length && selectedSplashScreenLogo === "light") ||
      !logoDark ||
      (!logoDark.length && selectedSplashScreenLogo === "dark")
    ) {
      formMethods.setValue("selectedSplashScreenLogo", "none");
    }
  }, [logoLight, logoDark, selectedSplashScreenLogo, formMethods]);

  return (
    <>
      <div className="flex flex-col justify-start gap-2 whitespace-pre-line pb-8 md:flex-row">
        <FormField label={t("page.project-styling.form.logos")}>
          <div className="flex justify-start gap-5 sm:gap-7">
            <FormField htmlFor="logoDark" label={t("page.project-styling.form.logo-dark")} isSubLabel>
              <FormImageInput<FormValues, "logoDark">
                name="logoDark"
                id="logoDark"
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.project-styling.form.logo-dark"),
                  }),
                  validate: {
                    size(image) {
                      if (image) {
                        return validateSize(t, image);
                      }
                    },
                  },
                }}
                disabled={!hasPermission(canManageProjectManagement)}
              />
              <FormRadio<FormValues, "selectedSplashScreenLogo">
                className="pt-3 md:pt-6"
                name="selectedSplashScreenLogo"
                value="dark"
                displayValue={t("page.project-styling.form.splash-screen.logo-dark")}
                disabled={!logoDark || !logoDark.length || !hasPermission(canManageProjectManagement)}
              />
            </FormField>
            <FormField htmlFor="imageFile" label={t("page.project-styling.form.logo-light")} isSubLabel>
              <FormImageInput<FormValues, "logoLight">
                name="logoLight"
                id="logoLight"
                theme="dark"
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.project-styling.form.logo-light"),
                  }),
                  validate: {
                    size(image) {
                      if (image) {
                        return validateSize(t, image);
                      }
                    },
                  },
                }}
                disabled={!hasPermission(canManageProjectManagement)}
              />
              <FormRadio<FormValues, "selectedSplashScreenLogo">
                className="pt-3"
                name="selectedSplashScreenLogo"
                value="light"
                displayValue={t("page.project-styling.form.splash-screen.logo-light")}
                disabled={!logoLight || !logoLight.length || !hasPermission(canManageProjectManagement)}
              />
            </FormField>
            <FormRadio<FormValues, "selectedSplashScreenLogo">
              className="pt-3 sm:pt-32"
              name="selectedSplashScreenLogo"
              value="none"
              displayValue={t("page.project-styling.form.splash-screen.no-logo")}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </div>
        </FormField>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <FormContent>
          <FormField htmlFor="imageFile" label={t("page.project-styling.form.background")}>
            <FormImageInput<FormValues, "background">
              name="background"
              id="background"
              rules={{
                validate: {
                  size(image) {
                    if (image) {
                      return validateSize(t, image);
                    }
                  },
                },
              }}
              disabled={!hasPermission(canManageProjectManagement)}
            />
            <div className="pt-2">
              <FormCheckbox
                name="useBackgroundImageForSplashScreen"
                label={t("page.project-styling.form.splash-screen.background")}
                disabled={
                  !background?.length ||
                  (splashScreenBackground && splashScreenBackground.length > 0) ||
                  !hasPermission(canManageProjectManagement)
                }
              />
            </div>
          </FormField>

          <FormField htmlFor="color1" required label={t("page.project-styling.form.color1")}>
            <FormColorPicker id="color1" name="color1" disabled={!hasPermission(canManageProjectManagement)} />
          </FormField>
          <FormField htmlFor="color2" required label={t("page.project-styling.form.color2")}>
            <FormColorPicker id="color2" name="color2" disabled={!hasPermission(canManageProjectManagement)} />
          </FormField>
          <FormField
            htmlFor="pageTitleUnscrolledColor"
            required
            label={t("page.project-styling.form.page-title-unscrolled-color")}
          >
            <FormColorPicker
              id="pageTitleUnscrolledColor"
              name="pageTitleUnscrolledColor"
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField htmlFor="backgroundColor" required label={t("page.project-styling.form.background-color")}>
            <FormColorPicker
              id="backgroundColor"
              name="backgroundColor"
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField htmlFor="color3" required label={t("page.project-styling.form.color3")}>
            <FormColorPicker id="color3" name="color3" disabled={!hasPermission(canManageProjectManagement)} />
          </FormField>

          {!!hasPermission(canManageProjectManagement) && (
            <Button type="submit" isLoading={isSubmitting}>
              {t("page.project-styling.submit")}
            </Button>
          )}
        </FormContent>
        <div className="flex">
          <div className="flex flex-col gap-2 md:flex-row">
            <div className="flex flex-col gap-2">
              <Subtitle2 className="self-start">{t("page.project-styling.splash-screen.preview")}</Subtitle2>
              <div
                className="relative h-[417px] w-[208px] rounded-lg bg-black bg-cover bg-center shadow-lg"
                style={
                  splashScreenBackground?.[0] ||
                  (useBackgroundImageForSplashScreen && background?.length && background?.[0].url)
                    ? {
                        backgroundImage: `url("${
                          splashScreenBackground?.[0] ? splashScreenBackground[0].url : background?.[0].url
                        }")`,
                      }
                    : undefined
                }
              >
                {selectedSplashScreenLogo !== "none" && (
                  <div className="mx-auto mt-16 w-20">
                    <img
                      src={selectedSplashScreenLogo === "light" ? logoLight?.[0]?.url : logoDark?.[0]?.url}
                      alt="Logo"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2 pt-8 md:items-start">
              <FormImageInput<FormValues, "splashScreenBackground">
                name="splashScreenBackground"
                id="splashScreenBackground"
                texts={{ add: t("page.project-styling.form.splash-screen.custom-background") }}
                rules={{
                  validate: {
                    size(image) {
                      if (image) {
                        return validateSize(t, image);
                      }
                    },
                  },
                }}
                disabled={!hasPermission(canManageProjectManagement)}
              />
              <span className="max-w-xs whitespace-pre-line text-xs text-app-grey-1">
                {t("page.project-styling.form.splash-screen.custom-background.tip")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface FormValues {
  logoDark: FormImage[];
  logoLight: FormImage[];
  background: FormImage[];
  splashScreenBackground: FormImage[];
  useBackgroundImageForSplashScreen: boolean;
  selectedSplashScreenLogo: "light" | "dark" | "none";
  color1: string;
  color2: string;
  color3: string;
  backgroundColor: string;
  pageTitleUnscrolledColor: string;
}
