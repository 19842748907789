import "./RichTextView.css";

export interface RichTextViewProps {
  title?: string;
  children: string;
}

export const RichTextView = ({ children, ...props }: RichTextViewProps): React.ReactNode => {
  return <div {...props} className="aop-cleanslate w-full" dangerouslySetInnerHTML={{ __html: children }} />;
};
