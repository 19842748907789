import type { AutomatedSurveyQueueQuestionDetailsListDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { BarChart } from "modules/analytics/components/BarChart";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";

interface SurveyQuestionGroupAnswerCardHeaderProps {
  order: number;
  questionTitle: string;
  category: AutomatedSurveyQueueQuestionDetailsListDto["questionGroupType"];
}

export function SurveyQuestionGroupAnswerCardHeader({
  order,
  questionTitle,
  category,
}: SurveyQuestionGroupAnswerCardHeaderProps): React.ReactNode {
  const [t] = useTranslation();

  let categoryLabel = t("page.automated-surveys.types.other");
  switch (category) {
    case "building":
      categoryLabel = t("page.automated-surveys.question-type.building");
      break;
    case "community":
      categoryLabel = t("page.automated-surveys.question-type.community");
      break;
    case "service":
      categoryLabel = t("page.automated-surveys.question-type.service");
      break;
    case "feelAtHome":
      categoryLabel = t("page.automated-surveys.question-type.feeling-at-home");
  }

  return (
    <div className="flex justify-between gap-10">
      <div className="flex flex-col gap-2">
        <h4 className="text-headline4 font-semibold leading-headline4">
          {order}. {questionTitle}
        </h4>
        <span className="text-red-darker">{t("component.surveys-question-group-answer.label.mandatory")}</span>
      </div>
      {category && <Label theme="blue">{categoryLabel}</Label>}
    </div>
  );
}

interface SurveyQuestionGroupAnswerCardRatingProps {
  rating: number | undefined;
}

export function SurveyQuestionGroupAnswerCardRating({
  rating,
}: SurveyQuestionGroupAnswerCardRatingProps): React.ReactNode {
  const [t] = useTranslation();

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2">
      <div className="flex items-center gap-2">
        <Icon name={iconStar01} size={48} className="text-yellow" />
        <h2 className="text-headline2 font-semibold leading-headline2">
          {rating ? rating.toFixed(1) : t("component.surveys-question-group-answer.not-available")}
        </h2>
      </div>
      <span className="text-subtitle2 font-normal leading-subtitle2">
        {t("component.surveys-question-group-answer.label.avg-rating")}
      </span>
    </div>
  );
}

interface SurveyQuestionGroupAnswerCardBarChartProps {
  data: { x: string; y: number }[];
}

export function SurveyQuestionGroupAnswerCardBarChart({
  data,
}: SurveyQuestionGroupAnswerCardBarChartProps): React.ReactNode {
  const [t] = useTranslation();

  return (
    <BarChart
      type="text"
      primaryData={data}
      formatYTick={(y) => y}
      formatXTick={(x) => `${x} ⭐`}
      showLegend={false}
      labelOrientation="vertical"
      renderLabel={(data) => (
        <div className="whitespace-pre-line">
          {t("component.surveys-question-group-answer.chart.tooltip", {
            amount: data.primary?.y,
            rating: data.x,
          })}
        </div>
      )}
    />
  );
}

interface SurveyQuestionGroupAnswerCardLastResponseSectionProps {
  questionTitle: string | undefined;
  lastResponse: string | undefined;
}

export function SurveyQuestionGroupAnswerCardLastResponseSection({
  questionTitle,
  lastResponse,
}: SurveyQuestionGroupAnswerCardLastResponseSectionProps): React.ReactNode {
  return (
    <div className="flex w-full items-end justify-between">
      <div className="flex flex-col gap-2">
        {questionTitle && <h4 className="text-headline4 font-normal leading-headline4">{questionTitle}</h4>}
        {lastResponse && (
          <span className="text-subtitle2 font-normal leading-subtitle2">
            <Trans
              i18nKey="component.surveys-question-group-answer.label.latest-response"
              values={{
                answer: lastResponse,
              }}
              components={{
                italic: <span className="italic" />,
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
}
