import { Capture2, Headline2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";

export function Stat({
  title,
  value,
  tooltip,
}: {
  title: string;
  value: number | string;
  tooltip?: string;
}): React.ReactNode {
  return (
    <div className="flex shrink-0 flex-col justify-between rounded-lg border border-grey-lightest px-3 py-2">
      <Capture2 className="text-grey-dark">{title}</Capture2>
      <Tooltip tooltip={tooltip}>
        <Headline2 className="w-min">{value}</Headline2>
      </Tooltip>
    </div>
  );
}
