import { twResolve } from "helpers/tw-resolve";

export interface PillButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export function PillButton({ children, className, onClick }: PillButtonProps): React.ReactNode {
  return (
    <button
      tabIndex={0}
      type="button"
      {...{ onClick }}
      className={twResolve(
        "z-50 flex items-center gap-1 rounded-full bg-aop-basic-blue p-2 text-white shadow-lg hover:bg-aop-basic-blue-dark",
        className,
      )}
    >
      {children}
    </button>
  );
}
