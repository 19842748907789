import iconAlertCircle from "assets/icons/alert-circle.svg";
import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import iconCheckCircle from "assets/icons/check-circle.svg";
import { Icon } from "components/Icon/Icon";
import { Capture1, Capture2 } from "components/Text/Text";
import { AnimatePresence, motion } from "framer-motion";
import type { Dispatch, PropsWithChildren, SetStateAction } from "react";
import React, { useContext, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

export interface FlashToastProps {
  title: string;
  description?: string;
  type: FlashToastType;
  "data-testid"?: string;
}

export function FlashToast(props: FlashToastProps): React.ReactNode {
  return (
    <div
      className={twJoin("rounded-3px p-4 shadow-md sm:min-w-96", getFlashToastStyling(props.type))}
      data-testid={props["data-testid"]}
    >
      <div className="flex items-start">
        <div className={twJoin("shrink-0 pt-1", getFlashToastTitleStyling(props.type))}>
          {getFlashToastIcon(props.type)}
        </div>
        <div className={twJoin("ml-3", getFlashToastTitleStyling(props.type))}>
          {props.description ? (
            <>
              <Capture1>{props.title}</Capture1>
              <Capture2 className="mt-2 block whitespace-pre-wrap">{props.description}</Capture2>
            </>
          ) : (
            <Capture2>{props.title}</Capture2>
          )}
        </div>
      </div>
    </div>
  );
}

const DURATION = 3000;
type FlashToastType = "success" | "warning" | "error" | "info";

function getFlashToastIcon(type: FlashToastType) {
  switch (type) {
    case "error":
      return <Icon name={iconAlertCircle} />;
    case "info":
      return <Icon name={iconAlertCircle} />;
    case "success":
      return <Icon name={iconCheckCircle} />;
    case "warning":
      return <Icon name={iconAlertTriangle} />;
    default:
      throw new Error(`Unknown flash toast type: ${type}`);
  }
}

function getFlashToastStyling(type: FlashToastType) {
  switch (type) {
    case "error":
      return "bg-red-lightest";
    case "info":
      return "bg-blue-lightest";
    case "success":
      return "bg-green-lightest";
    case "warning":
      return "bg-yellow-lightest";
    default:
      throw new Error(`Unknown flash toast type: ${type}`);
  }
}

function getFlashToastTitleStyling(type: FlashToastType) {
  switch (type) {
    case "error":
      return "text-red-darkest";
    case "info":
      return "text-blue-darkest";
    case "success":
      return "text-green-darkest";
    case "warning":
      return "text-yellow-darkest";
    default:
      throw new Error(`Unknown flash toast type: ${type}`);
  }
}

const FlashToastsContext = React.createContext<{
  push: Dispatch<SetStateAction<FlashToastProps | null>>;
  current: FlashToastProps | null;
}>({
  push: () => {},
  current: null,
});

export function useFlashToast(): (props: FlashToastProps) => void {
  return useContext(FlashToastsContext).push;
}

export function FlashToastProvider({ children }: PropsWithChildren<{}>): React.ReactNode {
  const [current, setCurrent] = useState<FlashToastProps | null>(null);

  useEffect(() => {
    const timeoutToken = setTimeout(() => {
      setCurrent(null);
    }, DURATION);

    return () => {
      clearTimeout(timeoutToken);
    };
  }, [current]);

  return (
    <FlashToastsContext.Provider
      value={{
        push: setCurrent,
        current,
      }}
    >
      <div className="fixed left-1/2 top-0 z-50 mx-auto max-w-full -translate-x-1/2">
        <AnimatePresence>
          {current != null && (
            <motion.div
              initial={{ y: "-100%", scale: 0.3 }}
              animate={{ y: "0%", scale: 1 }}
              exit={{ y: "-100%", scale: 0.3 }}
              key={JSON.stringify(current)}
            >
              <FlashToast {...current} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {children}
    </FlashToastsContext.Provider>
  );
}
