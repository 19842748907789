import { ApiClientProvider } from "api/ApiClientProvider";
import { ErrorPage } from "components/Error/ErrorPage";
import { getRecentProject, setRecentProject } from "helpers/recent-project";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useEffect, useMemo, useRef } from "react";
import { matchPath, useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { ProjectContextData, ProjectId } from "./ProjectContext";
import { ProjectContext } from "./ProjectContext";

interface Props {
  /**
   * Optional project ID to use instead of the one from the URL.
   */
  projectId?: string;
  children: React.ReactNode;
}

/**
 * Loads all projects the user has access to and sets the current project.
 * Redirects to the registration page if the user has no projects.
 */
export function ProjectLoader({ projectId, children }: Props): React.ReactNode {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { data: projects, isFetching, error } = useConnectedProjects();

  const previousProject = useRef<string | undefined>(undefined);

  const projectState = useMemo(() => {
    if (!projects || (isFetching && projects.length === 0)) {
      return undefined;
    }

    if (projects.length === 0) {
      return { type: "no-projects" } as const;
    }

    const currentProject = projectId
      ? projects.find((x) => x.id === projectId) || getRecentProject(projects)
      : slug
        ? projects.find((x) => x.slug === slug) || getRecentProject(projects)
        : projects.find((x) => x.id === previousProject.current) || getRecentProject(projects);

    return {
      type: "ready",
      projectContext: {
        projectId: currentProject.id as ProjectId,
        projectSlug: currentProject.slug,
        changeProject: (newProjectId, redirect, forceUpdateProject) => {
          const newProject = projects.find((x) => x.id === newProjectId);
          if (forceUpdateProject !== "FORCE" && !newProject) {
            return;
          } else if (!newProject) {
            throw new Error("Project not found");
          }

          if (redirect) {
            if (matchPath(routes.tickets.ROOT, location.pathname)) {
              navigate(routes.tickets.list({ slug: newProject.slug }));
            } else {
              navigate(routes.home.home({ slug: newProject.slug }));
            }
          }
        },
      } satisfies ProjectContextData,
    } as const;
  }, [isFetching, navigate, projectId, projects, slug]);
  const currentProjectId = projectState?.projectContext?.projectId;
  const currentProjectSlug = projectState?.projectContext?.projectSlug;

  useEffect(() => {
    if (currentProjectId && previousProject.current !== currentProjectId) {
      previousProject.current = currentProjectId;
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (projectState?.type === "ready") {
      setRecentProject(projectState.projectContext.projectId);
    }
  }, [projectState?.projectContext?.projectId, projectState?.type]);

  // Redirect to location code screen
  useEffect(() => {
    if (projectState?.type === "no-projects") {
      navigate(routes.registration.main(), { replace: true });
    }
  }, [projectState?.type, navigate]);

  if (error) {
    return <ErrorPage status={(error as unknown as Response)?.status || 0} />;
  }

  if (!projectState) {
    return null;
  }

  if (projectState.type === "no-projects") {
    return null;
  }

  if (!projectId && currentProjectSlug && slug && slug !== currentProjectSlug) {
    return <ErrorPage status={404} />;
  }

  return (
    <ProjectContext.Provider value={projectState.projectContext}>
      <ApiClientProvider projectId={projectState.projectContext.projectId}>{children}</ApiClientProvider>{" "}
    </ProjectContext.Provider>
  );
}
