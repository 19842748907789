import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import type { MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmModalProps {
  isOpen: boolean;
  id: string;
  title: string;
  description?: string;
  renderTitle?: (title: string) => ReactNode;
  renderDescription?: (title: string) => ReactNode;
  isLoading: boolean;
  onResolve: MouseEventHandler;
  resolveBtnProps?: {
    hide?: boolean;
    text?: string;
    "data-testid"?: string;
  };
  onReject: MouseEventHandler;
  rejectBtnProps?: {
    hide?: boolean;
    text?: string;
    "data-testid"?: string;
  };
  theme?: "info" | "danger";
  "data-testid"?: string;
  shouldCloseOnEsc?: boolean;
  isActionRequired?: boolean;
  onAfterClose?: () => void;
}

export function ConfirmModal({
  id,
  title,
  description,
  isLoading,
  renderTitle = (title) => title,
  renderDescription = (description) => description,
  onReject,
  rejectBtnProps,
  onResolve,
  resolveBtnProps,
  children,
  theme = "info",
  isOpen,
  shouldCloseOnEsc,
  isActionRequired,
  onAfterClose,
  ...props
}: PropsWithChildren<ConfirmModalProps>): React.ReactNode {
  const { t } = useTranslation();
  const titleId = `${id}-title`;
  const descriptionId = `${id}-description`;

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      isActionRequired={isActionRequired}
      onRequestClose={onReject}
      aria={{ labelledby: titleId, describedby: descriptionId }}
      id={id}
    >
      <article className="grid grid-cols-1 gap-y-2 px-8 py-4" data-testid={props["data-testid"]}>
        <Headline4 className="max-w-sm justify-self-center" as="h2" id={titleId}>
          {renderTitle(title)}
        </Headline4>
        {description ? (
          <div className="max-w-sm justify-self-center" id={descriptionId}>
            {renderDescription(description)}
          </div>
        ) : null}
        {children && <div className="py-2">{children}</div>}
        <div className="mt-4 flex flex-wrap justify-center gap-x-4 gap-y-2">
          {!rejectBtnProps?.hide && (
            <Button
              className="w-full sm:w-auto"
              onClick={onReject}
              styling="secondary"
              disabled={isLoading}
              data-testid={rejectBtnProps?.["data-testid"] || "confirm-modal-reject"}
            >
              {rejectBtnProps?.text ?? t("common.action.cancel")}
            </Button>
          )}
          {!resolveBtnProps?.hide && (
            <Button
              className="w-full sm:w-auto"
              onClick={onResolve}
              isLoading={isLoading}
              styling={theme === "danger" ? "danger" : "primary"}
              data-testid={resolveBtnProps?.["data-testid"] || "confirm-modal-resolve"}
            >
              {resolveBtnProps?.text ?? t("common.action.ok")}
            </Button>
          )}
        </div>
      </article>
    </Modal>
  );
}
