import { useSuperTokensUserId } from "authentication/AuthenticationStateProvider";
import type { Config, ConfigSnapshot, ConfigUser } from "helpers/config";
import { getConfigSnapshot } from "helpers/config";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { createContext, useContext, useMemo } from "react";
import { Async } from "react-async";

type ContextValue = { snapshot: ConfigSnapshot; user?: ConfigUser };
const ConfigContext = createContext<ContextValue | null>(null);

export function ConfigProvider({
  withUser,
  children,
}: {
  withUser?: boolean;
  children: React.ReactNode;
}): React.ReactNode {
  if (withUser) {
    return <ConfigUserProvider>{children}</ConfigUserProvider>;
  }

  return <ConfigNoUserProvider>{children}</ConfigNoUserProvider>;
}

function ConfigNoUserProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const parentContextValue = useContext(ConfigContext);
  const configPromise = useMemo(async () => {
    const snapshot = parentContextValue?.snapshot || (await getConfigSnapshot());

    return { snapshot };
  }, [parentContextValue]);

  return (
    <Async promise={configPromise}>
      {/* Just continue rendering if we already have some context value */}
      <Async.Pending>{parentContextValue == null ? null : children}</Async.Pending>
      <Async.Fulfilled<ContextValue>>
        {(config) => <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>}
      </Async.Fulfilled>
    </Async>
  );
}

function ConfigUserProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const superTokenUserId = useSuperTokensUserId();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();

  const parentContextValue = useContext(ConfigContext);

  // Memo because <Async /> needs referential equality
  const configPromise = useMemo(async () => {
    const user = superTokenUserId
      ? {
          identifier: superTokenUserId,
          email: sessionUser.email,
          custom: {
            ProjectId: projectId,
          },
        }
      : undefined;

    const snapshot = parentContextValue?.snapshot || (await getConfigSnapshot());

    return { snapshot, user };
  }, [parentContextValue?.snapshot, projectId, sessionUser.email, superTokenUserId]);

  if (!superTokenUserId) {
    return null;
  }

  return (
    <Async promise={configPromise}>
      <Async.Fulfilled<ContextValue>>
        {(config) => <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>}
      </Async.Fulfilled>
    </Async>
  );
}

export function useConfig<TKey extends keyof Config>(key: TKey): Config[TKey] {
  const value = useContext(ConfigContext);

  if (value == null) {
    throw new Error("ConfigProvider not initialized");
  }

  return value.snapshot.getValue(key, undefined, value.user);
}
