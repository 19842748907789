import { Input } from "components/Input/Input";
import { twResolve } from "helpers/tw-resolve";

interface Props {
  id?: string;
  value?: string;
  name?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  isError?: boolean;
  readOnly?: boolean;
}

export function ColorPicker({ value = "", onChange, onBlur, isError, ...props }: Props): React.ReactNode {
  return (
    <div className="flex gap-2">
      <Input
        id={props.id}
        name={props.name}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        aria-invalid={isError}
        readOnly={props["readOnly"]}
      />
      <input
        className={twResolve(
          "size-10 appearance-none rounded-2px border border-grey-lighter bg-white p-1 leading-6 hover:border-grey-darker focus-visible:border-grey-darkest focus-visible:outline-none disabled:bg-grey-lightest disabled:text-grey-light",
          props["readOnly"] && "bg-grey-lightest text-grey-dark hover:border-grey-lighter",
          isError && "border-red-dark",
        )}
        aria-invalid={isError}
        type="color"
        name={props.name}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        disabled={props["readOnly"]}
      />
    </div>
  );
}
