import { twJoin } from "tailwind-merge";

type PillData = { id: string; name: string; callback?: () => void };

interface PillListProps {
  data: PillData[];
  total?: number;
  limit?: number;
  canWrap?: boolean;
  overLimitPill?: PillData;
}

export function Pill({ data }: { data: PillData }): React.ReactNode {
  const Component = data.callback ? "button" : "span";

  return (
    <Component
      className={twJoin(
        "truncate rounded-full bg-blue-lightest px-3 py-1 text-sm",
        data.callback ? "cursor-pointer hover:bg-blue-light/50" : undefined,
      )}
      key={data.id}
      onClick={data.callback}
      type={data.callback ? "button" : undefined}
    >
      {data.name}
    </Component>
  );
}

export function PillList({ data, canWrap, total = 0, limit = 0, overLimitPill }: PillListProps): React.ReactNode {
  let dataSlice = data;
  if (limit > 0) {
    dataSlice = data.slice(0, limit);
  }

  if (!overLimitPill) {
    overLimitPill = { id: "overlimit_pill", name: `+${data.length - limit}` };
  }

  return (
    <div className={twJoin("flex gap-2", canWrap ? "flex-wrap" : undefined)}>
      {dataSlice.map((x) => (
        <Pill key={x.id} data={x} />
      ))}
      {limit > 0 && (data.length - limit > 0 || total - limit > 0) && (
        <Pill key={overLimitPill.id} data={overLimitPill} />
      )}
    </div>
  );
}
