import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconStar01 from "assets/icons/star-01.svg";
import type { LegendDataIconProps } from "components/Charts/LegendDataComponent";
import { LegendDataIcon } from "components/Charts/LegendDataComponent";
import { Icon } from "components/Icon/Icon";
import { Select } from "components/Select/Select";
import { avgBy } from "helpers/math";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { groupBy, round, sum, uniqBy } from "lodash-es";
import { BarChart } from "modules/analytics/components/BarChart";
import { NoData } from "modules/analytics/components/NoData";
import { dataColors } from "modules/analytics/theme";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import { Card, CardHeader } from "../../components/Card";
import type { DateRangeOption } from "../../util";
import { formatChartDate } from "../../util";

interface Props {
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
}

export function AverageTicketRatingCard({ dateRangeOptions }: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const project = useProject();
  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);
  const [category, setCategory] = useState<{ name: string; id: string }>();
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_TICKETS_AVERAGE_RATING(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsTicketRatingMonthlyV1({ dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });
  const categories = useMemo(
    () =>
      uniqBy(
        data?.data.map((x) => ({ name: x.categoryName, id: x.categoryId })),
        (x) => x.id,
      ) ?? [],
    [data?.data],
  );
  const benchmark = useMemo(
    () =>
      data
        ? data.benchmark.map((d) => ({
            x: formatChartDate(d.range),
            y: d.averageRating,
          }))
        : [],
    [data],
  );
  const groupedByDate = useMemo(
    () =>
      data
        ? Object.entries(groupBy(data.data, (x) => formatChartDate(x.range))).map(([date, values]) => {
            const average = avgBy(
              values,
              (x) => x.averageRating,
              (x) => x.ratedTicketCount,
            );

            return {
              x: date,
              y: isDefined(average) ? round(average, 2) : undefined,
              ticketCount: sum(values.map((x) => x.ratedTicketCount)),
            };
          })
        : [],
    [data],
  );
  const groupedByCategory = useMemo(
    () =>
      data
        ? groupBy(
            data.data.map((d) => ({
              x: formatChartDate(d.range),
              y: d.averageRating,
              categoryName: d.categoryName,
              categoryId: d.categoryId,
              ticketCount: d.ratedTicketCount,
            })),
            (d) => d.categoryId,
          )
        : {},
    [data],
  );

  const noData = !data || data.data.every((x) => x.averageRating === undefined);

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.avg-ticket-rating.title")}
        tooltip={t("page.analytics-overview.chart.avg-ticket-rating.title-tooltip")}
      >
        <Select
          placeholder={t("page.analytics-overview.category-placeholder")}
          emptyItem={t("page.analytics-overview.category-deselect")}
          items={categories}
          selected={category}
          onChange={setCategory}
          renderOption={(x) => x.name}
          keySelector={(x) => x.id}
        />
        <Select
          items={dateRangeOptions}
          selected={dateRange}
          onChange={setDateRange}
          renderOption={(x) => x.name}
          keySelector={(x) => x.value}
        />
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <BarChart
          minY={data.minRating}
          maxY={data.maxRating}
          type="date"
          formatYTick={(x) => x}
          primaryData={groupedByDate}
          primaryLabel={project.name}
          benchmark={benchmark}
          benchmarkLabel={t("page.analytics-overview.benchmark")}
          secondaryLabel={category?.name}
          secondaryData={category ? groupedByCategory[category.id] : undefined}
          xLabel={t("page.analytics-overview.chart.avg-ticket-rating.x-axis")}
          yLabel={t("page.analytics-overview.chart.avg-ticket-rating.y-axis")}
          showLegend
          renderLabel={({ primary, benchmark, secondary }, isSecondary) => (
            <table>
              <tbody>
                <TicketCountRow ticketCount={(isSecondary ? secondary?.ticketCount : primary?.ticketCount) || 0} />
                <StarRow
                  title={project.name}
                  value={primary!.y || 0}
                  icon={{
                    fill: dataColors.primary,
                    type: "square",
                  }}
                />
                {secondary && isDefined(secondary.y) ? (
                  <StarRow
                    title={category?.name || "-"}
                    value={secondary.y || 0}
                    icon={{
                      fill: dataColors.secondary,
                      type: "square",
                    }}
                  />
                ) : null}
                {benchmark?.y ? (
                  <StarRow
                    title={t("page.analytics-overview.benchmark")}
                    value={benchmark.y}
                    icon={{
                      fill: dataColors.benchmark,
                      type: "minus",
                    }}
                  />
                ) : null}
              </tbody>
            </table>
          )}
        />
      )}
    </Card>
  );
}

function TicketCountRow({ ticketCount }: { ticketCount: number }) {
  const { t } = useTranslation();

  return (
    <tr>
      <td colSpan={4}>
        {t("page.analytics-overview.chart.avg-ticket-rating.datapoint-label", {
          amount: ticketCount,
        })}
      </td>
    </tr>
  );
}

function StarRow({ title, icon, value }: { title: string; icon: LegendDataIconProps; value: number }) {
  return (
    <tr>
      <td className="pr-2">
        <LegendDataIcon {...icon} />
      </td>
      <td>{title}</td>
      <td className="pl-2">
        <Icon name={iconStar01} className="text-yellow" />
      </td>
      <td>
        <strong>{value}</strong>
      </td>
    </tr>
  );
}
