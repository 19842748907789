import { isDefined } from "helpers/util";
import { twJoin } from "tailwind-merge";

import { Star } from "./Star";

export function BluePill({ percentage, withStar }: { percentage?: number; withStar?: boolean }): React.ReactNode {
  if (!isDefined(percentage) || percentage === 0) {
    if (withStar) {
      return (
        <span className="ml-2">
          <Star />
        </span>
      );
    }

    return null;
  }

  return (
    <span
      className={twJoin(
        "ml-2 inline-flex whitespace-nowrap rounded-full px-2 py-px text-xs",
        percentage < 0 ? "bg-red-lightest/50 text-red-dark" : "bg-green-lightest text-green-darker",
      )}
    >
      {withStar ? (
        <span className="ml-[calc(-0.5rem+1.5px)] mr-1">
          <Star />
        </span>
      ) : null}
      {percentage < 0 ? "-" : "+"} {Math.abs(percentage)}%
    </span>
  );
}
