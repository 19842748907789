import type { DocumentUploadDto } from "api/types";
import cameraPlusIcon from "assets/icons/camera-plus.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconMinusCircle from "assets/icons/minus-circle.svg";
import iconPlusCircle from "assets/icons/plus-circle.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { Icon54 } from "components/Icon54/Icon54";
import { UploadDocumentIcon, UploadImageIcon } from "components/Icons/Icons";
import { Pdf } from "components/Pdf/Pdf";
import { twResolve } from "helpers/tw-resolve";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import type { AriaAttributes, FocusEventHandler } from "react";
import type React from "react";
import { useTranslation } from "react-i18next";

import type { FormDocument } from "../DocumentInput/useDocumentFile";
import { MediaXButton } from "./MediaXButton";

export interface DocumentMediaProps extends MediaProps {
  document: FormDocument | DocumentUploadDto | undefined;
  withPreview?: boolean;
}

export function DocumentMedia({ document, withPreview, ...props }: DocumentMediaProps): React.ReactNode {
  const { t } = useTranslation();

  if (withPreview && document) {
    return (
      <Pdf
        fileName={"file" in document ? document.file.name : document.fileName}
        fileSize={"file" in document ? document.file.size : "fileSize" in document ? document.fileSize : undefined}
        onDelete={props.onDelete}
        isInvalid={!!props["aria-invalid"]}
      />
    );
  }

  return (
    <Media {...props} type="document" document={document}>
      {!withPreview && Boolean(document) && (
        <button
          className={twResolve(
            "flex size-full cursor-pointer items-center justify-center gap-2 px-2 text-grey-darker",
            innerButtonStyles,
          )}
          type="button"
          onClick={props.onDelete}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        >
          <Icon name={iconMinusCircle} />
          <span className="truncate">{t("common.action.remove")}</span>
        </button>
      )}
    </Media>
  );
}

export interface ImageMediaProps extends MediaProps {
  src: string | undefined;
}

export function ImageMedia({ src, ...props }: ImageMediaProps): React.ReactNode {
  return (
    <Media {...props} type="image" image={src}>
      {src ? <img className="relative left-0 top-0 size-full rounded-lg object-cover" src={src} alt="" /> : null}
    </Media>
  );
}

export interface IconMediaProps extends MediaProps {
  charCode: number | undefined;
}

export function IconMedia({ charCode, ...props }: IconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {typeof charCode === "number" ? (
        <Icon54
          className="absolute left-0 top-0 flex size-full items-center justify-center object-cover"
          charCode={charCode}
          disableUserSelect
        />
      ) : null}
    </Media>
  );
}

export interface GroupIconMediaProps extends MediaProps {
  icon: GroupIconType | undefined;
}

export function GroupIconMedia({ icon, ...props }: GroupIconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {icon ? <GroupIcon size={48} className="flex size-full items-center justify-center p-2" icon={icon} /> : null}
    </Media>
  );
}

interface MediaProps extends AriaAttributes {
  texts?: {
    add?: string;
    edit?: string;
  };
  onAdd?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  className?: string;
  theme?: "light" | "dark";
  "data-testid"?: string;
  "data-add-testid"?: string;
  "data-edit-testid"?: string;
  "data-delete-testid"?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function AvatarMedia({
  src,
  onEdit,
  onDelete,
  onAdd,
  ...props
}: Omit<ImageMediaProps, "theme" | "texts">): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-2">
      <div
        {...props}
        className={twResolve(
          "relative size-32 rounded-full border border-grey-lighter transition hocus:bg-grey-lightest",
          props["aria-invalid"] && "border-red-dark",
          props.disabled && "bg-grey-lightest",
          props.className,
        )}
      >
        {src && (
          <>
            {onEdit && (
              <IconButton
                className="absolute left-0 top-0 z-10 size-full rounded-full border-none !bg-black/60 text-white opacity-0 transition-opacity hocus:opacity-100 "
                type="button"
                data-testid={props["data-edit-testid"]}
                onClick={onEdit}
                title={t("common.action.edit")}
              >
                <Icon name={iconEdit05} size={24} />
              </IconButton>
            )}
            <img className="relative left-0 top-0 size-full rounded-full object-cover" {...{ src }} />
          </>
        )}
        {!src && (
          <IconButton
            title={t("common.action.add-a-photo")}
            className={twResolve(
              "flex size-full cursor-pointer items-center justify-center !rounded-full border-none",
              innerButtonStyles,
              props.disabled && "cursor-not-allowed",
            )}
            data-testid={props["data-add-testid"]}
            type="button"
            onClick={onAdd}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            withTooltip={false}
          >
            <Icon name={cameraPlusIcon} size={24} />
          </IconButton>
        )}
      </div>
    </div>
  );
}

interface CustomMediaProps {
  type?: "image" | "document";
  document?: FormDocument;
  image?: string;
}

function Media(props: MediaProps & CustomMediaProps): React.ReactNode {
  const { t } = useTranslation();

  if (!props.children) {
    return (
      <MediaInputBox {...props}>
        <button
          className={twResolve(
            "flex h-20 min-w-28 cursor-pointer items-center justify-center gap-2 px-2 text-grey-darker",
            innerButtonStyles,
            props.disabled && "cursor-not-allowed",
            props.theme === "dark" ? "text-grey-lightest" : undefined,
          )}
          data-testid={props["data-add-testid"]}
          type="button"
          onClick={props.onAdd}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {props.type === "image" ? (
            <UploadImageIcon size={16} />
          ) : props.type === "document" ? (
            <UploadDocumentIcon size={16} />
          ) : (
            <Icon name={iconPlusCircle} size={24} />
          )}
          <span className="max-w-[128px] whitespace-pre-line">{props.texts?.add || t("common.action.add")}</span>
        </button>
      </MediaInputBox>
    );
  }

  return (
    <MediaInputBox {...props}>
      {props.onEdit != null && !props.disabled && (
        <button
          className={twResolve(
            "absolute left-0 top-0 z-10 flex size-full flex-1 items-center justify-center gap-1 bg-[rgba(64,61,74,0.65)] text-white opacity-0 hocus:opacity-100",
            innerButtonStyles,
          )}
          data-testid={props["data-edit-testid"]}
          onClick={props.onEdit}
          type="button"
        >
          <Icon name={iconEdit05} size={16} />
          {props.texts?.edit || t("common.action.edit")}
        </button>
      )}
      {props.onDelete != null && !props.disabled && (
        <div className="absolute right-0 top-0 z-10 -translate-y-1/2 translate-x-1/2">
          <MediaXButton data-testid={props["data-delete-testid"]} onClick={props.onDelete} />
        </div>
      )}
      {props.children}
    </MediaInputBox>
  );
}

function MediaInputBox(props: MediaProps & { children: React.ReactNode }) {
  return (
    <div
      data-testid={props["data-testid"]}
      className={twResolve(
        "relative h-20 min-w-28 rounded-lg border border-grey-lighter hocus:bg-grey-lightest",
        props["aria-invalid"] ? "border-red-dark" : undefined,
        props.disabled && "bg-grey-lightest",
        props.theme === "dark" ? "bg-grey-darkest hocus:bg-grey-darker" : undefined,
        props.className,
      )}
      aria-invalid={props["aria-invalid"]}
    >
      {props.children}
    </div>
  );
}

const innerButtonStyles =
  "active:text-blue disabled:text-grey rounded-lg focus-visible:outline-none focus-visible:before:content-[''] focus-visible:before:absolute focus-visible:before:-inset-2px focus-visible:before:pointer-events-none focus-visible:before:ring-1 focus-visible:before:ring-grey-darkest focus-visible:before:rounded-lg";
