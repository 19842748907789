import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  ConstraintListItemDto,
  EventCategoryDto,
  EventDto,
  GroupDto,
  LanguageDto,
  TranslateRequest,
  UserDto,
} from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import { AudienceSelector } from "components/AudienceSelector/AudienceSelector";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { Form } from "components/Form/Form";
import { FormBadgeSelect } from "components/Form/FormBadgeSelect";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormScheduleInput } from "components/Form/FormScheduleInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { PreviewLayout } from "components/Layouts/PreviewLayout";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Phone } from "components/Phone/Phone";
import { addHours, addMinutes } from "date-fns";
import { validateSize } from "helpers/file-size";
import type { FormTranslations } from "helpers/languages";
import { useCurrentTranslation } from "helpers/languages";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useSlug } from "hooks/useSlug";
import { AppEventDetailsView } from "modules/events/pages/CreateOrEdit/components/AppEventDetailsView";
import { getEventCategoryName } from "modules/events/util";
import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { EventObserver } from "./Loader";

export interface LayoutProps {
  defaultFormValues: LayoutFormValues;
  languages: LanguageDto[];
  isSubmitting: boolean;
  onSubmit: (formValues: LayoutFormValues) => void;
  showAnnouncementModal: boolean;
  eventCategories: EventCategoryDto[];
  isResident: boolean;
  organizer: UserDto;
  isPublished: boolean;
  eventObservers: EventObserver[];
  isEditMode: boolean;
}
export function Layout(props: LayoutProps): React.ReactNode {
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);
  const [appPreviewOpen, appPreviewOpenHandlers] = useBool();

  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const { id: eventId } = useParams<{ id: string }>();
  useKey("Escape", appPreviewOpenHandlers.setFalse, appPreviewOpen);
  const formMethods = useForm<LayoutFormValues>({
    mode: "onChange",
    defaultValues: props.defaultFormValues,
  });
  const slug = useSlug();
  const navigate = useNavigate();

  const audience = useWatch({ control: formMethods.control, name: "audience" });
  const eventObserver = useWatch({ control: formMethods.control, name: "eventObserver" });
  const start = useWatch({ control: formMethods.control, name: "start" });
  const noParticipantsLimit = useWatch({ control: formMethods.control, name: "noParticipantsLimit" });
  const participantCount = useWatch({ control: formMethods.control, name: "participantCount" });
  const type = useWatch({ control: formMethods.control, name: "type" });

  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  const { clearErrors, setValue } = formMethods;
  const [isTypeModalOpen, typeModalHandlers] = useBool(props.showAnnouncementModal);

  useEffect(() => {
    if (noParticipantsLimit) {
      clearErrors("maxParticipants");
    }
  }, [clearErrors, noParticipantsLimit]);

  useEffect(() => {
    if (start !== "" && !eventId) {
      setValue("end", addHours(start, 1), { shouldValidate: true });
    }
  }, [setValue, start, eventId]);

  useEffect(() => {
    if (eventObserver.type === "projectConnection") {
      setValue("audience", []);
    }
  }, [eventObserver, setValue]);

  const minStart = useMemo(() => addMinutes(new Date(), 15), []);

  const api = useApi();
  const { mutateAsync: translate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  async function onTranslate(field: "name" | "description", languageId: LanguageDto["id"]) {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await translate({
      languages: props.languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  }

  return (
    <DocumentPaper
      theme="no-gaps"
      title={
        eventId ? t("page.event.create-or-edit.edit-mode.title") : t("page.event.create-or-edit.create-mode.title")
      }
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit}>
        <PreviewLayout
          previewTitleText={t("page.event.create-or-edit.create-mode.section.preview.title")}
          showPreviewButtonText={t("page.event.create-or-edit.app-preview.open")}
          preview={<Preview eventId={eventId} organizer={props.organizer} eventCategories={props.eventCategories} />}
        >
          <FormContent>
            <FormField label={t("model.event.category")} description={t("model.event.category.description")} required>
              <FormBadgeSelect<LayoutFormValues, EventCategoryDto>
                name="category"
                items={props.eventCategories}
                keySelector={(x) => x.id}
                renderOption={(x) => getEventCategoryName(t, x.id)}
              />
            </FormField>
            {type === "common" && (
              <FormField
                label={t("model.event.max-participants")}
                description={t("model.event.max-participants.description")}
                required
              >
                <div className="flex flex-wrap items-center gap-x-8 gap-y-2">
                  <div className="w-20">
                    <FormInput<LayoutFormValues>
                      data-testid="event-max-participants-input"
                      inputMode="numeric"
                      type="number"
                      name="maxParticipants"
                      readOnly={noParticipantsLimit} // why not use "disabled" - once field with value is disabled and then enabled again, value becomes `undefined`.
                      rules={{
                        required: {
                          message: t("components.form.error.required", {
                            inputName: t("model.event.max-participants"),
                          }),
                          value: !noParticipantsLimit,
                        },
                        min: {
                          message: t("model.event.max-participants.error.min"),
                          value: participantCount,
                        },
                      }}
                    />
                  </div>
                  <FormCheckbox<LayoutFormValues>
                    className="mt-1.5"
                    data-testid="event-unlimited-participants-input"
                    name="noParticipantsLimit"
                    label={t("model.event.max-participants.no-limit")}
                  />
                </div>
              </FormField>
            )}
            <FormField
              label={t("common.entity.audience")}
              description={t("component.audience-section.description", {
                audienceTarget: t("common.entity.event").toLowerCase(),
              })}
            >
              <div className="flex flex-col gap-2">
                <FormSelect<LayoutFormValues, EventObserver>
                  name="eventObserver"
                  items={props.eventObservers}
                  keySelector={(item) => item.id}
                  renderOption={(item) => item.name}
                  groupSelector={(item) => {
                    switch (item.type) {
                      case "project":
                        return t("model.event.audience.group.project");
                      case "projectConnection":
                        return t("model.event.audience.group.area");
                    }
                  }}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.message-feed.create.audience"),
                    }),
                  }}
                  disabled={props.isEditMode || sessionUser.connections.length === 0}
                />
                {!props.isResident && eventObserver.type !== "projectConnection" && (
                  <AudienceSelector
                    defaultAudience={audience}
                    isEditMode={props.isEditMode}
                    editWarningMessage={t("component.audience-selector.edit-warning.event")}
                    onSaveAudience={(audience) => formMethods.setValue("audience", audience)}
                  />
                )}
              </div>
            </FormField>
            {props.languages.map((lng) => {
              const nameId = `name-${lng.id}`;
              const descriptionId = `description-${lng.id}`;

              return (
                <React.Fragment key={lng.id}>
                  <FormField
                    label={`${t("model.event.name")} (${lng.poEditorCode})`}
                    htmlFor={nameId}
                    required
                    actions={
                      <Button
                        styling="ghostPrimary"
                        onClick={() => onTranslate("name", lng.id)}
                        icon={<Icon name={iconGlobe02} size={16} />}
                      >
                        {t("model.event.name.translate")}
                      </Button>
                    }
                  >
                    <FormInput<LayoutFormValues>
                      data-testid="event-name-input"
                      autoComplete="off"
                      name={`nameTranslations.${lng.id}`}
                      id={nameId}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "model.event.name"),
                        },
                        maxLength: {
                          message: t("components.form.error.max-length", {
                            length: NAME_MAX_LENGTH,
                          }),
                          value: NAME_MAX_LENGTH,
                        },
                      }}
                      placeholder={`${t("model.event.name.placeholder")} ${lng.description}`}
                      disabled={isTranslating}
                    />
                  </FormField>
                  <FormField
                    htmlFor={descriptionId}
                    label={`${t("model.event.description")} (${lng.poEditorCode})`}
                    required
                    actions={
                      <Button
                        styling="ghostPrimary"
                        onClick={() => onTranslate("description", lng.id)}
                        icon={<Icon name={iconGlobe02} size={16} />}
                      >
                        {t("model.event.description.translate")}
                      </Button>
                    }
                  >
                    <FormTextArea<LayoutFormValues>
                      data-testid="event-description-input"
                      autoComplete="off"
                      name={`descriptionTranslations.${lng.id}`}
                      id={descriptionId}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "model.event.description"),
                        },
                      }}
                      placeholder={`${t("model.event.description.placeholder")} ${lng.description}`}
                      disabled={isTranslating}
                    />
                  </FormField>
                </React.Fragment>
              );
            })}
            <FormField
              htmlFor="imageFile"
              label={t("model.event.image")}
              description={t("model.event.image.description")}
            >
              <FormImageInput<LayoutFormValues, "image">
                className="relative z-10"
                data-testid="event-image-input"
                name="image"
                id="imageFile"
                rules={{
                  validate: {
                    size(image) {
                      if (image) {
                        return validateSize(t, image);
                      }
                    },
                  },
                }}
              />
            </FormField>
            <FormField label={t("model.event.time-start")} htmlFor="startsAt" required>
              <FormDateAndTimePicker<LayoutFormValues>
                data-testid="event-start-time"
                name="start"
                id="startsAt"
                type="datetime"
                placeholder={t("model.event.time-start.placeholder")}
                min={minStart}
                showNow={false}
                rules={{
                  validate: {
                    laterThanMin: (startDate) => {
                      if (startDate == null) {
                        return undefined;
                      }

                      return startDate < minStart ? t("model.event.start-date.error.must-be-in-future") : undefined;
                    },
                  },
                  required: {
                    message: t("components.form.error.required", {
                      inputName: t("model.event.time-start"),
                    }),
                    value: true,
                  },
                }}
              />
            </FormField>
            <FormField label={t("model.event.time-end")} htmlFor="endsAt" required>
              <FormDateAndTimePicker<LayoutFormValues>
                data-testid="event-end-time"
                name="end"
                id="endsAt"
                type="datetime"
                min={start || minStart}
                placeholder={t("model.event.time-end.placeholder")}
                showNow={false}
                rules={{
                  validate: {
                    laterThanStart: (endDate) => {
                      if (endDate == null) {
                        return undefined;
                      }

                      return endDate <= start ? t("model.event.end-date.error.later-than-start-date") : undefined;
                    },
                  },
                  required: {
                    message: t("components.form.error.required", {
                      inputName: t("model.event.time-end"),
                    }),
                    value: true,
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.event.create-or-edit.location")} htmlFor="location">
              <FormInput<LayoutFormValues>
                data-testid="event-location-input"
                type="text"
                name="location"
                id="location"
                placeholder={t("page.event.create-or-edit.location.placeholder")}
              />
            </FormField>
            {!props.isPublished && sessionUser.isAdmin ? (
              <FormField label={t("page.event.create-or-edit.publish")}>
                <FormScheduleInput
                  name="publishAt"
                  min={minScheduled}
                  max={start ? start : undefined}
                  canEdit={!eventId || !props.isPublished}
                  rules={{
                    validate: {
                      mustBeBeforeStart: (date) => {
                        if (!date || !start) {
                          return undefined;
                        }

                        return date >= start
                          ? t("page.event.create-or-edit.publish.validation.must-be-before-live")
                          : undefined;
                      },
                      laterThanMin: (date) => {
                        if (!date) {
                          return undefined;
                        }

                        return date < new Date()
                          ? t("page.event.create-or-edit.publish.validation.must-be-in-future")
                          : undefined;
                      },
                    },
                  }}
                />
              </FormField>
            ) : null}
            {/* Actions */}
            <div className="flex justify-end gap-2">
              <div className="flex gap-2">
                <Button styling="secondary" disabled={props.isSubmitting} onClick={cancelModalHandler.setTrue}>
                  {t("common.action.cancel")}
                </Button>
                <Button data-testid="submit-event-btn" styling="primary" type="submit" isLoading={props.isSubmitting}>
                  {t("common.action.save")}
                </Button>
              </div>
            </div>
          </FormContent>
        </PreviewLayout>
      </Form>
      <ConfirmModal
        id="cancel-modal"
        isOpen={isCancelModalOpen}
        data-testid="cancel-modal"
        title={t("page.event.create-or-edit.cancel-modal.title")}
        description={t("page.event.create-or-edit.cancel-modal.description")}
        shouldCloseOnEsc
        onReject={cancelModalHandler.setFalse}
        rejectBtnProps={{
          text: t("common.action.cancel"),
        }}
        onResolve={() => navigate(routes.bookings.list({ slug }))}
        resolveBtnProps={{
          text: t("common.action.confirm"),
        }}
        isLoading={false}
      />
      <ConfirmModal
        id="event-type-modal"
        data-testid="event-type-modal"
        title={t("page.event.create-or-edit.create-mode.section.type.title")}
        description={t("page.event.create-or-edit.create-mode.section.type.description")}
        renderDescription={(title) => {
          return (
            <div className="flex items-center gap-1.5">
              {title}
              <InfoIcon
                tooltip={
                  <div className="text-overline">
                    <div className="justify-center">
                      <span className="font-semibold leading-5 text-white">{t("model.event.type.regular")}</span> -{" "}
                      {t("model.event.type.regular.description")}
                    </div>
                    <div>
                      <span className="font-semibold leading-5 text-white">{t("model.event.type.announcement")}</span> -{" "}
                      {t("model.event.type.announcement.description")}
                    </div>
                  </div>
                }
              />
            </div>
          );
        }}
        isOpen={isTypeModalOpen}
        isActionRequired
        onReject={() => {
          setValue("type", "common");
          typeModalHandlers.setFalse();
        }}
        rejectBtnProps={{
          text: t("model.event.type.regular"),
        }}
        onResolve={() => {
          setValue("type", "announcement");
          typeModalHandlers.setFalse();
        }}
        resolveBtnProps={{
          text: t("model.event.type.announcement"),
        }}
        isLoading={false}
      />
    </DocumentPaper>
  );
}

function Preview({
  eventId,
  organizer,
  eventCategories,
}: {
  eventId?: string;
  organizer: UserDto;
  eventCategories: EventCategoryDto[];
}) {
  const sessionUser = useSessionUser();
  const {
    nameTranslations,
    descriptionTranslations,
    start,
    end,
    location,
    noParticipantsLimit,
    maxParticipants,
    participantCount,
    type,
    category,
    image,
    eventObserver,
  } = useWatch<LayoutFormValues>();

  const name = useCurrentTranslation(nameTranslations);
  const description = useCurrentTranslation(descriptionTranslations);

  return (
    <Phone>
      <AppEventDetailsView
        name={name.slice(0, NAME_MAX_LENGTH) || ""}
        description={description}
        iconChar={eventCategories.find((c) => c.id === category?.id)?.iconChar}
        maxParticipants={+(maxParticipants ?? 0)}
        startDate={start ?? ""}
        endDate={end ?? ""}
        image={image?.[0]?.url ?? sessionUser.project?.backgroundImage?.url}
        location={location || ""}
        participantCount={eventId ? participantCount || 0 : 0}
        isAnnouncement={type === "announcement"}
        projectConnection={eventObserver?.type === "projectConnection" ? eventObserver?.name : undefined}
        category={eventCategories.find((c) => c.id === category?.id)}
        noParticipantsLimit={noParticipantsLimit}
        organizer={organizer}
        projectTintColor={sessionUser.project?.styling.tintColor}
        isEditMode={!!eventId}
      />
    </Phone>
  );
}

const NAME_MAX_LENGTH = 40;

export interface LayoutFormValues {
  type: EventDto["type"];
  category: EventCategoryDto;
  nameTranslations: FormTranslations;
  descriptionTranslations: FormTranslations;
  start: DatePickerValue;
  end: DatePickerValue;
  location?: string;
  image: FormImage[];
  groups?: GroupDto[];
  maxParticipants?: string;
  noParticipantsLimit: boolean;
  participantCount: number;
  audience: ConstraintListItemDto[];
  eventObserver: EventObserver;
  publishAt: DatePickerValue;
}
