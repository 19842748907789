import { type HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useConfig } from "providers/ConfigProvider";
import { useCallback, useEffect, useId, useState } from "react";
import Session from "supertokens-web-js/recipe/session";

export type SignalRHub =
  | "community-feed-hub"
  | "community-feed-detail-hub"
  | "notification-hub"
  | "chat-hub"
  | "broadcast"
  | "test";

const connections: {
  [url: string]: {
    hubConnection: HubConnection | undefined;
    hubConnectionStartPromise: Promise<void> | undefined;
    listeners: Set<string>;
  };
} = {};

async function getHubConnection({
  reactId,
  url,
  getAccessToken,
}: {
  reactId: string;
  url: string;
  getAccessToken: () => Promise<string> | string;
}): Promise<HubConnection> {
  if (connections[url]) {
    const hubConnection = connections[url].hubConnection;
    if (
      hubConnection &&
      (hubConnection.state === HubConnectionState.Connected ||
        hubConnection.state === HubConnectionState.Connecting ||
        hubConnection.state === HubConnectionState.Reconnecting)
    ) {
      connections[url].listeners.add(reactId);

      // Make sure SignalR is connected
      await connections[url].hubConnectionStartPromise;

      return hubConnection;
    }
  }

  const hubConnection = new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: getAccessToken,
      withCredentials: false,
    })
    .withAutomaticReconnect()
    .build();

  const hubConnectionStartPromise = hubConnection.start();

  if (connections[url]) {
    connections[url].hubConnection = hubConnection;
    connections[url].hubConnectionStartPromise = hubConnectionStartPromise;
    connections[url].listeners.add(reactId);
  } else {
    connections[url] = {
      hubConnection,
      hubConnectionStartPromise,
      listeners: new Set([reactId]),
    };
  }

  // Make sure SignalR is connected
  await hubConnectionStartPromise;

  return hubConnection;
}

function removeHubConnection(reactId: string, url: string): void {
  const connection = connections[url];
  if (connection) {
    connection.listeners.delete(reactId);

    // Time out, to avoid closing and opening the connection too often (especially useful when we trigger quick re-renders)
    setTimeout(() => {
      if (connection.listeners.size === 0) {
        void connection.hubConnection?.stop();
        connection.hubConnection = undefined;
        connection.hubConnectionStartPromise = undefined;
      }
    }, 50);
  }
}

export function useSignalRHub(
  hub: SignalRHub,
  opts?: {
    query?: string;
    disabled?: boolean;
  },
): {
  signalRConnection: HubConnection | null;
} {
  const id = useId();
  const useSignalR = useConfig("useSignalR");
  const coreApiUri = useConfig("newCoreApiRootUri");
  const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (!useSignalR) {
      return;
    }

    if (opts?.disabled) {
      return;
    }

    async function getAccessToken(): Promise<string> {
      const token = await Session.getAccessToken();

      if (!token) {
        throw new Error("Token is not available");
      }

      return token;
    }

    const url = `${coreApiUri}/hubs/${hub}${opts?.query ? "?" + opts.query : ""}`;
    void getHubConnection({ reactId: id, url, getAccessToken }).then(setSignalRConnection);

    return () => {
      removeHubConnection(id, url);
      setSignalRConnection(null);
    };
  }, [useSignalR, coreApiUri, hub, opts?.disabled, opts?.query, id]);

  return { signalRConnection: signalRConnection };
}

export function useSignalRSubscription(
  signalRHub: HubConnection | null,
  methodName: string,
  method: (...args: any[]) => void,
): void {
  useEffect(() => {
    if (!signalRHub) {
      return;
    }

    if (signalRHub.state !== HubConnectionState.Connected) {
      console.error("SignalR hub is available but not connected");

      return;
    }

    signalRHub.on(methodName, method);

    return () => {
      signalRHub.off(methodName, method);
    };
  }, [signalRHub, method, methodName]);
}

export function useSignalRInvocation<TReturnType = never>(
  signalRHub: HubConnection | null,
  methodName: string,
): { invoke: (...args: any[]) => Promise<TReturnType | undefined> } {
  const invoke = useCallback(
    async (...args: any[]) => {
      try {
        if (!signalRHub || signalRHub.state !== HubConnectionState.Connected) {
          throw new Error("SignalR connection is not established");
        }

        const data = await signalRHub.invoke<TReturnType>(methodName, ...args);

        return data;
      } catch (e) {
        console.error("Could not invoke SignalR", e);
      }
    },
    [signalRHub, methodName],
  );

  return { invoke };
}
