import { twResolve } from "helpers/tw-resolve";

const baseButtonStyling =
  "relative rounded-lg px-6 py-2.5 self-start font-bold text-center text-white truncate cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:text-white disabled:bg-grey-lighter disabled:pointer-events-none";

interface StaticButtonProps {
  children: React.ReactNode;
}

export function StaticButton({ children }: StaticButtonProps): React.ReactNode {
  return (
    <div className={twResolve(baseButtonStyling, "inline-block cursor-default bg-grey text-sm ring-grey-darkest")}>
      {children}
    </div>
  );
}
