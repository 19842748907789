import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { useBool } from "hooks/useBool";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

interface Context {
  isOpen: boolean;
  open: () => void;
}

const ForbiddenPopupContext = React.createContext<Context>(null!);

export function useForbiddenPopupContext(): Context {
  return useContext(ForbiddenPopupContext);
}

export function ForbiddenPopupProvider({ children }: React.PropsWithChildren<{}>): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, isOpenHandlers] = useBool(false);

  return (
    <ForbiddenPopupContext.Provider
      value={{
        isOpen,
        open: isOpenHandlers.setTrue,
      }}
    >
      <Modal isOpen={isOpen} onRequestClose={isOpenHandlers.setFalse} shouldCloseOnEsc id="forbidden-popup">
        <article className="grid grid-cols-1 gap-y-2 px-8 py-4">
          <Headline4 className="max-w-sm justify-self-center" as="h2">
            {t("component.forbidden-user-details-modal.title")}
          </Headline4>
          <p className="max-w-sm justify-self-center">{t("component.forbidden-user-details-modal.description")}</p>
          <div className="mt-4 flex flex-wrap justify-center gap-x-4 gap-y-2">
            <Button className="w-full sm:w-auto" onClick={isOpenHandlers.setFalse} styling="primary">
              {t("component.forbidden-user-details-modal.close-btn")}
            </Button>
          </div>
        </article>
      </Modal>
      {children}
    </ForbiddenPopupContext.Provider>
  );
}
