import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { Modal } from "components/Modal/Modal";
import { Capture2, Headline4 } from "components/Text/Text";
import { addDays, addMinutes, parseISO } from "date-fns";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TicketReminderModalProps {
  ticketId: string;
  isOpen: boolean;
  onClose: () => void;
  reminderDate?: string;
  daysToAutoClose: number;
}

interface FormValues {
  reminderDate: Date;
}

export function TicketReminderModal({
  ticketId,
  isOpen,
  onClose,
  reminderDate,
  daysToAutoClose,
}: TicketReminderModalProps): React.ReactNode {
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const detailToken = QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId);
  const createReminder = useMutation({
    mutationFn: (payload: { reminderDate: string }) => api.postAdminTicketsReminderV1(ticketId, payload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO });
      showFlashToast({ type: "success", title: t("page.tickets.details.reminder.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.reminder.error") });
    },
  });

  const deleteReminderMutation = useMutation({
    mutationFn: () => api.deleteAdminTicketsReminderDeleteV1(ticketId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: detailToken });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO });
    },
  });

  const isLoading = createReminder.isPending || deleteReminderMutation.isPending;

  const { t } = useTranslation();

  const form = useForm<FormValues>({
    defaultValues: {
      reminderDate: reminderDate ? parseISO(reminderDate) : addDays(new Date(), 1),
    },
  });

  const minScheduled = addMinutes(new Date(), 5);

  const isEdit = !!reminderDate;

  const handleSave = async () => {
    await createReminder.mutateAsync({
      reminderDate: form.getValues("reminderDate").toISOString(),
    });

    onClose();
  };

  const handleDelete = async () => {
    await deleteReminderMutation.mutateAsync();

    onClose();
  };

  const deleteReminder = deleteReminderMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (reminderDate && parseISO(reminderDate) < new Date()) {
        deleteReminder();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [ticketId, reminderDate, deleteReminder]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnEsc shouldCloseOnOverlayClick={false}>
      <div className="flex flex-col items-center gap-2 p-4">
        <Headline4>
          {isEdit
            ? t("page.tickets.details.reminder.modal.edit.title")
            : t("page.tickets.details.reminder.modal.create.title")}
        </Headline4>
        <Capture2>{t("page.tickets.details.reminder.modal.description")}</Capture2>
        <Form className="my-4" formMethods={form} onSubmit={handleSave}>
          <FormContent>
            <FormField label={t("page.tickets.details.reminder.modal.datepicker.name")} required>
              <FormDateAndTimePicker<FormValues>
                name="reminderDate"
                type="datetime"
                min={minScheduled}
                rules={{
                  validate: {
                    laterThanMin: (date) => {
                      if (date == null) {
                        return undefined;
                      }

                      return date < minScheduled
                        ? t("page.tickets.details.reminder.modal.datepicker.error.must-be-in-future")
                        : undefined;
                    },
                    earlierThanAutoClose: (date) => {
                      if (date == null || daysToAutoClose == 0) {
                        return undefined;
                      }

                      return date > addDays(new Date(), daysToAutoClose)
                        ? t("page.tickets.details.reminder.modal.datepicker.error.must-be-before-auto-close")
                        : undefined;
                    },
                  },
                  required: t("components.form.error.required", {
                    inputName: t("page.tickets.details.reminder.modal.datepicker.name"),
                  }),
                }}
              />
            </FormField>
          </FormContent>
          <div className="mt-6 flex gap-4">
            {isEdit ? (
              <Button styling="danger" onClick={handleDelete} disabled={isLoading}>
                {t("page.tickets.details.reminder.modal.button.delete")}
              </Button>
            ) : (
              <Button styling="secondary" onClick={onClose} disabled={isLoading}>
                {t("common.action.cancel")}
              </Button>
            )}
            {isEdit ? (
              <Button styling="primary" type="submit" isLoading={isLoading}>
                {t("page.tickets.details.reminder.modal.button.edit")}
              </Button>
            ) : (
              <Button styling="primary" type="submit" isLoading={isLoading}>
                {t("page.tickets.details.reminder.modal.button.create")}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
}
