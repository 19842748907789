import * as Sentry from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export function initializeSentry(): void {
  Sentry.init({
    dsn: import.meta.env.DEV
      ? undefined
      : "https://b54dbf1a9ece40a9a9339c6d64731c92@o4504289700610048.ingest.sentry.io/4504289709260800",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
    tracesSampleRate: 0.02,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    release: window.AOP_VERSION ? String(window.AOP_VERSION) : undefined,
    environment: window.SENTRY_ENVIRONMENT ? String(window.SENTRY_ENVIRONMENT) : undefined,
  });
}
