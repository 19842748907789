import { Button } from "components/Button/Button";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { FormStarInput } from "components/Form/FormStarInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  isTicketClosed: boolean;
  openDate: string;
  closingDate?: string;
  onClose?: () => void;
  onSubmit: ({ rating, remark, closingDate }: { rating?: number; remark: string; closingDate: Date }) => void;
}

const REMARK_MAX_LENGTH = 1000;

export function TicketResidentRateModal({
  isOpen,
  isLoading,
  closingDate,
  isTicketClosed,
  openDate,
  onClose,
  onSubmit,
}: Props): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    defaultValues: {
      rating: 4,
      remark: "",
      closingDate: closingDate ? parseISO(closingDate) : new Date(),
    },
  });
  const [tab, setTab] = useState<"rating" | "noRating">("rating");

  function submit(values: FormValues) {
    if (isLoading) {
      return;
    }

    return onSubmit({
      remark: values.remark,
      rating: tab === "rating" ? values.rating : undefined,
      closingDate: values.closingDate,
    });
  }

  const minDate = useMemo(() => {
    const minDate = parseISO(openDate);
    // Set to start of day to avoid trouble with comparison of same date
    minDate.setHours(0, 0, 0, 0);

    return minDate;
  }, [openDate]);
  const maxDate = useMemo(() => new Date(), []);

  const formContent = (
    <>
      <div className="mt-4" />
      <FormContent maxWidth="none">
        {tab === "rating" && (
          <FormField label={t("page.tickets.rate-modal.form.rating")}>
            <div className="flex w-full justify-center pt-5">
              <FormStarInput<FormValues, "rating"> name="rating" />
            </div>
          </FormField>
        )}
        <FormField label={t("page.tickets.rate-modal.form.remark")}>
          <FormTextArea<FormValues>
            name="remark"
            placeholder={t("page.tickets.rate-modal.form.remark.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: REMARK_MAX_LENGTH,
                }),
                value: REMARK_MAX_LENGTH,
              },
            }}
          />
        </FormField>
        <FormField label={t("page.tickets.rate-modal.form.closing-date")}>
          <FormDateAndTimePicker<FormValues>
            name="closingDate"
            min={minDate}
            max={maxDate}
            rules={{
              validate: {
                laterThanMin: (date) => {
                  if (!date) {
                    return undefined;
                  }

                  return date < minDate
                    ? t("page.tickets.rate-modal.form.closing-date.error.must-be-after-open")
                    : undefined;
                },
                soonerThanMax: (date) => {
                  if (!date) {
                    return undefined;
                  }

                  return date > maxDate
                    ? t("page.tickets.rate-modal.form.closing-date.error.must-be-in-past")
                    : undefined;
                },
              },
            }}
          />
        </FormField>
      </FormContent>

      <div className="mt-4 flex flex-wrap justify-end gap-x-4 gap-y-2">
        <Button
          className="w-full sm:w-auto"
          onClick={onClose}
          styling="secondary"
          disabled={isLoading}
          data-testid="rate-modal-cancel"
        >
          {t("common.action.cancel")}
        </Button>
        <Button
          className="w-full sm:w-auto"
          type="submit"
          isLoading={isLoading}
          styling="primary"
          data-testid="rate-modal-submit"
        >
          {t("common.action.ok")}
        </Button>
      </div>
    </>
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnEsc={!isLoading}>
      <Form formMethods={form} onSubmit={submit}>
        <div className="max-w-lg rounded p-4 text-left md:w-[512px]" data-testid="ticket-rate-modal">
          <Headline4 className="my-4 block">{t("page.tickets.rate-modal.title")}</Headline4>

          {!isTicketClosed && <p className="my-4">{t("page.tickets.rate-modal.description")}</p>}

          {isTicketClosed ? (
            formContent
          ) : (
            <ContentTabs<"rating" | "noRating">
              activeTabId={tab}
              onTabChange={setTab}
              tabs={[
                {
                  id: "rating",
                  name: t("page.tickets.rate-modal.form.tabs.with-rating"),
                },
                {
                  id: "noRating",
                  name: t("page.tickets.rate-modal.form.tabs.without-rating"),
                },
              ]}
            >
              {formContent}
            </ContentTabs>
          )}
        </div>
      </Form>
    </Modal>
  );
}

interface FormValues {
  rating: number;
  remark: string;
  closingDate: Date;
}
