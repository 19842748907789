import { extendTailwindMerge } from "tailwind-merge";

export const twResolve = extendTailwindMerge({
  extend: {
    theme: {
      colors: [
        "aop-off-white",
        "aop-basic-blue",
        "aop-basic-blue-dark",
        "aop-dark-blue",
        "aop-bright-purple",
        "transparent",
        "current",
        "black",
        "white",
        "grey-darkest",
        "grey-darker",
        "grey-dark",
        "grey",
        "grey-light",
        "grey-lighter",
        "grey-lightest",
        "blue-darkest",
        "blue-darker",
        "blue-dark",
        "blue",
        "blue-light",
        "blue-lighter",
        "blue-lightest",
        "green-darkest",
        "green-darker",
        "green-dark",
        "green",
        "green-light",
        "green-lighter",
        "green-lightest",
        "yellow-darkest",
        "yellow-darker",
        "yellow-dark",
        "yellow",
        "yellow-light",
        "yellow-lighter",
        "yellow-lightest",
        "red-darkest",
        "red-darker",
        "red-dark",
        "red",
        "red-light",
        "red-lighter",
        "red-lightest",
        "orange",
        "mint",
        "teal",
        "cyan",
        "indigo",
        "purple",
        "pink",
        "brown",
        "app-grey",
        "app-grey-1",
      ],
    },
    classGroups: {
      "font-size": [
        "text-headline2",
        "text-headline3",
        "text-headline4",
        "text-subtitle1",
        "text-subtitle2",
        "text-capture1",
        "text-capture2",
        "text-button",
        "text-overline",
      ],
      leading: [
        "leading-headline2",
        "leading-headline3",
        "leading-headline4",
        "leading-subtitle1",
        "leading-subtitle2",
        "leading-capture1",
        "leading-capture2",
        "leading-button",
        "leading-overline",
        "leading-none",
      ],
      "font-family": ["font-icon", "font-sans"],
      shadow: ["shadow-azure"],
      "border-w": ["border-1rem"],
    },
  },
});
