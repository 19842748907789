import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Switch } from "components/Switch/Switch";
import { Subtitle2 } from "components/Text/Text";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseType } from "types/api-types";

export function ChatListHeader(): React.ReactNode {
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const api = useApi();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  const mutationUpdateChatsState = useMutation({
    mutationFn: (state: boolean) => api.postChatsStateV1({ hideChat: !state }).then((x) => x.data),
    onMutate: (variables) => {
      queryClient.setQueryData<ApiResponseType<"getSelfV2"> | undefined>(QUERY_KEYS.SELF(projectId), (oldResponse) => {
        if (oldResponse) {
          return {
            ...oldResponse,
            data: {
              ...oldResponse.data,
              chatEnabled: variables,
            },
          };
        }
      });
    },
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chats.toggle-chats.error") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
  });

  return (
    <div className="flex items-center justify-center gap-4 md:justify-end">
      <div className="flex items-center gap-2">
        <Subtitle2 className="font-normal">{t("page.chats.switch.chat")}</Subtitle2>
        <Switch
          onChange={() => mutationUpdateChatsState.mutateAsync(!sessionUser.chatEnabled)}
          isChecked={!!sessionUser.chatEnabled}
          labels={{ on: t("page.chats.switch.on"), off: t("page.chats.switch.off") }}
        />
      </div>
    </div>
  );
}
