import { formatDate, stripRelativeFromDateFormat } from "components/FormattedDate/FormattedDate";
import { isSameDay as checkIsSameDay, parseISO } from "date-fns";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export interface DateRangeProps {
  start: string | number | Date;
  end: string | number | Date;
  className?: string;
  format?: "withTime" | "noTime" | "shortTime";
}

export const DateRange = memo(function DateRange({
  start,
  end,
  format = "withTime",
  className,
}: DateRangeProps): React.ReactNode {
  const { i18n } = useTranslation();
  const isSameDay = checkIsSameDay(
    typeof start === "string" ? parseISO(start) : start,
    typeof end === "string" ? parseISO(end) : end,
  );
  const dateTimeFormat = format === "shortTime" ? "datetimeShort" : format === "noTime" ? "date" : "datetime";
  const endDateType = isSameDay && format !== "noTime" ? "time" : stripRelativeFromDateFormat(dateTimeFormat);

  const startDateAbsolute = formatDate(i18n, dateTimeFormat, start);
  let startDateRelative = formatDate(i18n, dateTimeFormat, start);
  const endDate = formatDate(i18n, endDateType, end);

  const noTooltip = startDateAbsolute === startDateRelative;

  const currentYear = new Date().getFullYear().toString();
  if (startDateRelative.endsWith(currentYear) && endDate.endsWith(currentYear)) {
    startDateRelative = startDateRelative.replace(" " + currentYear, "");
  }

  return (
    <div className={className} title={noTooltip ? "" : `${startDateAbsolute} - ${endDate}`}>
      <time>{startDateRelative}</time>
      {" - "}
      <time>{endDate}</time>
    </div>
  );
});
