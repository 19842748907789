import { Label } from "components/Label/Label";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { Overline2, Subtitle2 } from "components/Text/Text";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface Project {
  id: string;
  name: string;
  city?: string;
  logoUrl: string;
  activeState: "unknown" | "active" | "closed";
}

interface Props {
  project: Project;
  onClick: () => void;
}

export function ProjectItem({ project, onClick }: Props): React.ReactNode {
  const { t } = useTranslation();

  const isClosed = project.activeState === "closed";

  return (
    <button
      className={twJoin(
        "flex w-full items-center gap-4 rounded-md p-2 outline-none hover:bg-aop-basic-blue-lightest focus-visible:bg-aop-basic-blue-lightest focus-visible:ring-grey-darkest",
        isClosed && "text-grey-light",
      )}
      onClick={onClick}
    >
      <ProjectLogo className={twJoin("size-14 shrink-0", isClosed && "opacity-50")} src={project.logoUrl} />
      <div className="flex flex-1 flex-col items-start">
        <Subtitle2 className="line-clamp-1 break-all text-left">{project.name}</Subtitle2>
        {project.city && (
          <Overline2 className={twJoin("text-left text-grey-darker", isClosed && "text-grey-lighter")}>
            {project.city}
          </Overline2>
        )}
      </div>
      {project.activeState === "closed" && (
        <Label theme="red" size="small">
          {t("component.project-switcher.project-closed")}
        </Label>
      )}
    </button>
  );
}
