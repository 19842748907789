import type { MessageLikeDto } from "api/types";
import { Button } from "components/Button/Button";
import { Capture2 } from "components/Text/Text";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useTranslation } from "react-i18next";

interface Props {
  hasMoreLikes: boolean;
  likes: MessageLikeDto[];
  onLoadMore: () => void;
}

export function LikeList({ likes, hasMoreLikes, onLoadMore }: Props): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div data-testid="like-list">
      {hasMoreLikes && (
        <Button styling="tertiary" onClick={onLoadMore}>
          <Capture2>{t("component.community-post.likes.see-more")}</Capture2>
        </Button>
      )}
      <ul>
        {likes.map((like) => (
          <li className="flex items-center gap-2 py-2" key={`comment_post_like_${like.author.id}`}>
            <div className="mr-2 size-8">
              <UserAvatarLink user={like.author} />
            </div>
            <UserNameLink user={like.author}>
              <span className="text-sm font-semibold text-black">{like.author.fullName}</span>
            </UserNameLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
