import iconChevronDown from "assets/icons/chevron-down.svg";
import iconChevronUp from "assets/icons/chevron-up.svg";
import iconX from "assets/icons/x.svg";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Icon } from "components/Icon/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import type { UseSelectPropGetters } from "downshift";
import { useMultipleSelection, useSelect } from "downshift";
import { twResolve } from "helpers/tw-resolve";
import type { ReactNode } from "react";
import { memo } from "react";
import { twJoin } from "tailwind-merge";

export interface CheckboxMultiSelectProps<T> {
  placeholder: ReactNode;
  selected: T[] | undefined;
  disabled?: boolean;
  items: readonly T[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  renderValue?: (items: T[]) => ReactNode;
  keySelector: (item: T) => string | number;
  onChange?: (items: T[]) => void;
  onBlur?: () => void;
  disabledItems?: T[];
  disabledItemTooltip?: string;
  disabledItemsStillSelectable?: boolean;
  "aria-invalid"?: boolean;
  id?: string;
  className?: string;
  allSelectedText?: ReactNode;
  hideClearButton?: boolean;
}

export function CheckboxMultiSelect<T>({
  selected = [],
  disabled,
  placeholder,
  items,
  renderOption,
  renderValue,
  keySelector,
  onChange,
  onBlur,
  disabledItems = [],
  disabledItemTooltip,
  disabledItemsStillSelectable,
  className,
  allSelectedText,
  hideClearButton,
  ...props
}: CheckboxMultiSelectProps<T>): React.ReactNode {
  const { getDropdownProps, setSelectedItems, addSelectedItem, removeSelectedItem, selectedItems } =
    useMultipleSelection({
      selectedItems: selected,
      defaultActiveIndex: 0,
      onSelectedItemsChange: (stateChange) => {
        onChange?.(stateChange.selectedItems ?? []);
      },
      onStateChange: ({ type, selectedItems: newSelectedItems = [] }) => {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedItems(newSelectedItems);
            break;
          default:
            break;
        }
      },
    });

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps, closeMenu } = useSelect({
    id: props.id,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: items as T[],
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            ...(changes.selectedItem && { isOpen: true, highlightedIndex: state.highlightedIndex }),
          };
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          return {
            ...changes,
            ...(changes.selectedItem && { isOpen: true, highlightedIndex: state.highlightedIndex }),
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          if (selectedItem && (disabledItemsStillSelectable || !disabledItems.includes(selectedItem))) {
            if (selectedItems.includes(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <div
      className={twResolve("relative", isOpen && "z-10", disabled && "pointer-events-none opacity-50", className)}
      data-testid="checkbox-multi-select"
    >
      <div
        {...getToggleButtonProps(
          getDropdownProps({
            preventKeyAction: isOpen,
          }),
        )}
        className={twResolve(
          "relative flex max-h-48 min-h-10 w-full cursor-default items-center justify-between overflow-y-auto rounded-lg border border-grey-lighter bg-white pl-2 pr-10 text-left leading-[26px] hocus:border-grey-darker hocus:outline-none",
          isOpen && "rounded-b-none border-grey-darker hocus:border-grey-darker",
          props["aria-invalid"] ? "ring-2 ring-red" : undefined,
        )}
      >
        {selectedItems.length > 0 ? (
          <>
            <span className="w-full">
              {selectedItems.length === items.length && allSelectedText ? (
                <span className="text-grey">{allSelectedText}</span>
              ) : renderValue ? (
                renderValue(selectedItems)
              ) : (
                <>
                  {placeholder} <span className="font-semibold">+{selectedItems.length}</span>
                </>
              )}
            </span>
            {hideClearButton ? null : (
              <button
                className="absolute right-7 top-0 flex h-full w-10 items-center justify-center focus:outline-none focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-black"
                aria-label="clear button"
                data-testid="checkbox-multi-select-clear"
                onClick={(e) => {
                  e.stopPropagation();

                  setSelectedItems([]);
                  closeMenu();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();

                  if (e.key === "Enter") {
                    setSelectedItems([]);
                    closeMenu();
                  }
                }}
              >
                <span className="flex items-center text-grey-darker">
                  <Icon name={iconX} />
                </span>
              </button>
            )}
          </>
        ) : (
          <span className="w-full text-grey-light">{placeholder}</span>
        )}
        <span
          className="absolute right-0 top-0 flex h-full w-10 items-center justify-center focus:outline-none"
          aria-label="toggle menu"
        >
          <span className="flex items-center text-grey-darker">
            {isOpen ? <Icon name={iconChevronUp} /> : <Icon name={iconChevronDown} />}
          </span>
        </span>
      </div>
      <ul
        {...getMenuProps()}
        className={twResolve(
          isOpen ? "opacity-100" : "opacity-0",
          "absolute flex max-h-96 min-h-[2.25rem] w-full flex-col items-start overflow-auto rounded-lg rounded-t-none border border-t-0 border-grey-darker bg-white shadow-md hocus:outline-none",
        )}
      >
        {isOpen &&
          items.map((item, index) => (
            <ListItem<T>
              key={keySelector(item)}
              renderOption={renderOption}
              selectedItems={selectedItems}
              item={item}
              index={index}
              isHighlighted={highlightedIndex === index}
              getItemProps={getItemProps}
              disabled={disabledItems.includes(item)}
              disabledTooltip={disabledItemTooltip}
              disabledStillSelectable={disabledItemsStillSelectable}
            />
          ))}
      </ul>
    </div>
  );
}

const ListItem = memo(ListItemInner) as typeof ListItemInner;

function ListItemInner<T>(props: {
  item: T;
  index: number;
  isHighlighted: boolean;
  getItemProps: UseSelectPropGetters<T>["getItemProps"];
  renderOption: CheckboxMultiSelectProps<T>["renderOption"];
  selectedItems: T[];
  disabled: boolean;
  disabledTooltip?: string;
  disabledStillSelectable?: boolean;
}) {
  const {
    item,
    index,
    isHighlighted,
    getItemProps,
    renderOption,
    selectedItems,
    disabled,
    disabledTooltip,
    disabledStillSelectable,
  } = props;

  const isSelected = selectedItems.includes(item);

  return (
    <li
      data-testid="checkbox-multi-select-item"
      {...getItemProps({ index, item })}
      className={twJoin(
        "w-full cursor-pointer select-none px-3 py-1 first:mt-2 last:mb-2",
        isHighlighted ? "bg-blue-lightest" : undefined,
        disabled && !disabledStillSelectable ? "cursor-default" : "",
        disabled ? "text-grey-light" : "text-black",
      )}
    >
      <Tooltip tooltip={disabled && disabledTooltip}>
        <div className={twJoin("flex items-center gap-2", isSelected ? "font-semibold" : undefined)}>
          {/* We set a null onChange handler because we only use it as a visual indicator */}
          <Checkbox checked={isSelected} disabled={disabled && !disabledStillSelectable} onChange={() => null} />
          {renderOption(item, selectedItems.includes(item))}
        </div>
      </Tooltip>
    </li>
  );
}
