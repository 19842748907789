import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconStar01 from "assets/icons/star-01.svg";
import { Icon } from "components/Icon/Icon";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { Capture1, Headline2, Overline2 } from "components/Text/Text";
import { addMonths, differenceInMonths } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const RANGE = "lastYear";

export function TicketOverviewCard(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const minDate = useMemo(() => addMonths(new Date(), -12), []);
  const maxDate = useMemo(() => addMonths(new Date(), -1), []);
  const [date, setDate] = useState(maxDate);
  const [monthIndex, setMonthIndex] = useState(0);
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_TICKETS_OVERVIEW(projectId, RANGE),
    queryFn: () => api.getAnalyticsTicketOverviewMonthlyV1({ dateRange: RANGE }),
    select: (response) => {
      const { data, benchmark } = commonAPIDataSelector(response);

      return {
        data: [...data].reverse(),
        benchmark: [...benchmark].reverse(),
      };
    },
  });

  useEffect(() => {
    setMonthIndex(differenceInMonths(maxDate, date));
  }, [date, maxDate]);

  const currentData = data?.data[monthIndex];
  const currentBenchmark = data?.benchmark[monthIndex];

  const noData =
    !data || !currentData || data.data.every((x) => x.averageTicketRating === undefined || x.ticketCount === 0);

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.tickets-overview.title")}
        tooltip={t("page.analytics-overview.chart.tickets-overview.title-tooltip")}
      >
        <MonthPicker value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} placement="left" />
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <Stat
            title={t("page.analytics-overview.chart.tickets-overview.item.total-tickets")}
            value={currentData.ticketCount}
          />
          <Stat
            title={t("page.analytics-overview.chart.tickets-overview.item.average-rating")}
            value={
              isDefined(currentData.averageTicketRating) ? (
                <span>
                  {currentData.averageTicketRating}
                  <span className="text-2xl font-normal">/10</span>
                </span>
              ) : (
                "-"
              )
            }
            icon={<Icon name={iconStar01} size={24} className="text-yellow" />}
            benchmark={
              isDefined(currentBenchmark?.averageTicketRating) ? currentBenchmark.averageTicketRating : undefined
            }
          />
          <Stat
            title={t("page.analytics-overview.chart.tickets-overview.item.closed-tickets")}
            value={currentData.ticketClosedCount}
          />
          <Stat
            title={t("page.analytics-overview.chart.tickets-overview.item.closed-by-resident")}
            value={`${Math.round(currentData.ticketClosedByResidentPercentage)}%`}
            benchmark={
              currentBenchmark ? `${Math.round(currentBenchmark.ticketClosedByResidentPercentage)}%` : undefined
            }
          />
        </div>
      )}
    </Card>
  );
}

interface StatProps {
  title: string;
  icon?: React.ReactNode;
  value: React.ReactNode;
  benchmark?: React.ReactNode;
}

function Stat({ title, icon, value, benchmark }: StatProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-3 rounded-lg border border-grey-lightest px-3 py-2">
      <Capture1 className="text-grey-dark">{title}</Capture1>
      <div className="mb-3 flex flex-col">
        <div className="flex items-center gap-2">
          {icon}
          <Headline2>{value}</Headline2>
        </div>
        {benchmark ? (
          <Overline2 className="text-grey-dark">
            {benchmark} {t("page.analytics-overview.benchmark")}
          </Overline2>
        ) : null}
      </div>
    </div>
  );
}
