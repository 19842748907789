import type { ImageDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { AnimatePresence, motion } from "framer-motion";
import { preloadImage } from "helpers/image";
import { useKey } from "hooks/useKey";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

type CarouselImage = {
  // Use type to guard in case of type mutation
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  url: ImageDto["url"] | FormImage["url"];
  description?: ImageDto["description"];
};

interface CarouselProps {
  images: CarouselImage[];
  defaultImage?: number;
  allowZoom?: boolean;
  objectFit?: React.CSSProperties["objectFit"];
  rounded?: "full" | "top" | "bottom";
}

const variants = {
  enter: (direction: "left" | "right") => {
    return {
      x: direction === "right" ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: "left" | "right") => {
    return {
      zIndex: 0,
      x: direction === "left" ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export function Carousel({
  images,
  defaultImage = 0,
  allowZoom = true,
  objectFit = "cover",
  rounded,
}: CarouselProps): React.ReactNode {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState<number>(defaultImage);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const [zoomedImage, setZoomedImage] = useState<CarouselImage | undefined>(undefined);

  const handleNext = () => {
    setDirection("right");
    setActiveSlide((prevSlide) => (prevSlide + 1 === images.length ? 0 : prevSlide + 1));
  };

  const handlePrev = () => {
    setDirection("left");
    setActiveSlide((prevSlide) => (prevSlide - 1 < 0 ? images.length - 1 : prevSlide - 1));
  };

  const handleDotClick = (idx: number) => {
    setDirection(idx > activeSlide ? "right" : "left");
    setActiveSlide(idx);
  };

  useKey(
    "Escape",
    () => {
      setZoomedImage(undefined);
    },
    zoomedImage !== undefined,
  );

  useEffect(() => {
    const nextImage = images[activeSlide + 1];
    if (nextImage) {
      preloadImage(nextImage.url);
    }
  }, [activeSlide, images]);

  return (
    <div
      className={twJoin(
        "group/carousel relative flex size-full items-center justify-center overflow-hidden bg-black",
        rounded === "full"
          ? "rounded-lg"
          : rounded === "top"
            ? "rounded-t-lg"
            : rounded === "bottom"
              ? "rounded-b-lg"
              : undefined,
      )}
    >
      <AnimatePresence initial={false} custom={direction}>
        <motion.div className="absolute inset-0" layoutId={images[activeSlide].url} />
        <motion.img
          key={activeSlide}
          src={images[activeSlide].url}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.1 },
          }}
          variants={variants}
          className="absolute size-full"
          style={{
            objectFit,
          }}
          onClick={allowZoom ? () => setZoomedImage(images[activeSlide]) : undefined}
        />
      </AnimatePresence>
      {images.length > 1 && (
        <>
          <div className="absolute bottom-5 left-1/2 z-30 flex -translate-x-1/2 space-x-3">
            {images.map((img, idx) => (
              <button
                key={img.url}
                type="button"
                className={twJoin(
                  "size-3 rounded-full transition-colors duration-500 focus:ring-2 focus:ring-white",
                  idx === activeSlide ? "bg-white/80" : "bg-grey-darkest/60",
                )}
                aria-current="true"
                aria-label={`Slide ${idx + 1}`}
                onClick={() => handleDotClick(idx)}
              />
            ))}
          </div>
          <div className="absolute top-1/2 z-10 flex w-full -translate-y-1/2 justify-between px-3 opacity-0 transition-opacity group-hover/carousel:opacity-100">
            <IconButton
              styling="primary"
              title={t("common.action.previous")}
              withTooltip={false}
              disabled={activeSlide === 0}
              onClick={handlePrev}
              isCircular
            >
              <Icon name={iconChevronLeft} />
            </IconButton>
            <IconButton
              styling="primary"
              title={t("common.action.next")}
              withTooltip={false}
              disabled={activeSlide === images.length - 1}
              onClick={handleNext}
              isCircular
            >
              <Icon name={iconChevronRight} />
            </IconButton>
          </div>
        </>
      )}
      {allowZoom &&
        ReactDOM.createPortal(
          <AnimatePresence>
            {zoomedImage !== undefined ? (
              <motion.div
                className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto"
                role="button"
                aria-hidden
                onClick={() => setZoomedImage(undefined)}
              >
                <motion.div
                  className="fixed inset-0 bg-black"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.8 }}
                  exit={{ opacity: 0 }}
                />

                <motion.div className="z-0 flex h-screen !w-screen items-center justify-center p-8 md:p-16">
                  <motion.img
                    className="block max-h-full max-w-full select-none rounded bg-grey-lightest shadow-xl"
                    src={zoomedImage.url}
                    alt={zoomedImage?.description || ""}
                    layoutId={zoomedImage.url}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  />
                </motion.div>
              </motion.div>
            ) : null}
          </AnimatePresence>,
          document.body,
        )}
    </div>
  );
}
