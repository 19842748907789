import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyRepairRequestCommentDto, QuickReplyRepairRequestEditCommentRequest } from "api/types";
import {
  type QuickReplyRepairRequestAssigneeChangeRequest,
  type QuickReplyRepairRequestCreateCommentRequest,
  type QuickReplyRepairRequestStatusChangeRequest,
  type QuickReplyRepairRequestStatusDto,
  type UserDto,
} from "api/types";
import { Button } from "components/Button/Button";
import type { BaseCommentFieldProps } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Gallery } from "components/Gallery/Gallery";
import type { FormImage } from "components/ImageInput/useImageInput";
import { isDocumentUploaded, isImageUploaded } from "components/ImageInput/useImageInput";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Headline3 } from "components/Text/Text";
import { TicketCommentField } from "components/Ticket/TicketCommentField";
import { TicketLeaveCommentModal } from "components/Ticket/TicketLeaveCommentModal";
import { TicketStatusChangeModal } from "components/Ticket/TicketStatusChangeModal";
import { sortStatuses } from "helpers/status-helpers";
import { useBool } from "hooks/useBool";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useQueryParam } from "hooks/useQueryParam";
import { useSetAtom } from "jotai";
import { sumBy } from "lodash-es";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routes } from "routes";
import { languageAtom } from "state/app-language";
import { twJoin } from "tailwind-merge";

import { QuickReplyMenuBar } from "../components/QuickReplyMenuBar";
import { TicketActivitiesSection } from "../components/Ticket/TicketActivitySection";
import { TicketInfoHeader } from "../components/Ticket/TicketInfoHeader";
import { TicketRemsCard } from "../components/Ticket/TicketRemsCard";
import { TicketResidentInfo } from "../components/Ticket/TicketResidentInfo";

export function QuickReplyRepairRequestPage(): React.ReactNode {
  const { token } = useParams<{ token: string }>();
  const response = useData(token!);
  const setLanguage = useSetAtom(languageAtom);

  useEffect(() => {
    if (response.data) {
      setLanguage(response.data.loggedInUser.language.id);
    }
  }, [response.data, setLanguage]);

  if (!response.data) {
    if (response.isLoading) {
      return null;
    }

    return <ErrorPage status={(response.error as unknown as Response)?.status || 0} />;
  }

  return (
    <>
      <StopGlobalLoadingSpinner />
      <QuickReplyRepairRequestPageInternal {...response} />
    </>
  );
}

function QuickReplyRepairRequestPageInternal({
  token,
  data,
  activities,
  hasMoreComments,
  isFetchingMoreActivities,
  isChangingStatus,
  translation,
  translationIsLoading,
  onHideTranslation,
  onTranslate,
  reply,
  editReply,
  fetchMoreComments,
  changeStatus,
  changeAssignee,
  uploadImage,
  uploadDocument,
}: ReturnType<typeof useData>) {
  const { t } = useTranslation();
  const [editingComment, setEditingComment] = useState<QuickReplyRepairRequestCommentDto | undefined>(undefined);
  const [changedAssignee, setChangedAssignee] = useState<UserDto>();
  const [statusChangeModalState, setStatusChangeModalState] = useState<
    | { open: false }
    | {
        open: true;
        commentPayload: {
          content: string;
          image?: FormImage;
          internal?: boolean;
        };
      }
  >({ open: false });
  const [isLeaveCommentModalOpen, leaveCommentModalOpenHandlers] = useBool();
  const [targetStatusId, setTargetStatusId] = useQueryParam("targetStatus");
  const [headerParam] = useQueryParam("header");
  const { loggedInUser } = data || {};

  const readonly = data?.deletedAt != null;
  const canChangeStatus = !readonly && !!data?.possibleStatuses?.length;
  const canChangeAssignee = !readonly && !!data?.possibleAssignees?.length;
  const showHeader = headerParam !== "false";

  useDocumentTitle(t("page.messages-email.title"));

  const sortedStatuses = useMemo(() => sortStatuses(data?.possibleStatuses), [data?.possibleStatuses]);
  const targetStatus = useMemo(
    () => (targetStatusId ? sortedStatuses.find((x) => x.id === targetStatusId) : undefined),
    [sortedStatuses, targetStatusId],
  );

  useEffect(() => {
    if (targetStatusId && targetStatusId === data?.status.id) {
      setTargetStatusId(null, { replace: true });
    }
  }, [data?.status.id, setTargetStatusId, targetStatusId]);

  async function askStatusChangeOrReply(payload: { content: string; image?: FormImage; internal?: boolean }) {
    {
      const isStatusNewOrRejected =
        data?.status.defaultStatusId && ["new", "rejected"].includes(data.status.defaultStatusId);
      if (!payload.internal && isStatusNewOrRejected && canChangeStatus) {
        setStatusChangeModalState({
          open: true,
          commentPayload: payload,
        });
      } else {
        await onReply(payload);
      }
    }
  }

  async function onReply({
    content,
    image,
    document,
    internal,
  }: {
    content: string;
    image?: FormImage;
    document?: FormDocument;
    internal?: boolean;
  }) {
    const data: QuickReplyRepairRequestCreateCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
      internal,
    };

    if (image) {
      if (isImageUploaded(image)) {
        data.imageId = image.id;
      } else {
        const uploadedImage = await uploadImage({ file: image.file });
        data.imageId = uploadedImage.id;
      }
    }

    if (document) {
      if (isDocumentUploaded(document)) {
        data.documentIds = [document.id];
      } else {
        const uploadedDocument = await uploadDocument({ file: document.file });
        data.documentIds = [uploadedDocument.id];
      }
    }

    await reply({
      payload: data,
      failureMessage: internal
        ? t("page.quick-reply-repair-request.note-error")
        : t("page.quick-reply-repair-request.comment-error"),
    });
  }

  async function editComment({
    commentId,
    content,
    image,
    document,
  }: {
    commentId: string;
    content: string;
    image?: FormImage;
    document?: FormDocument;
  }) {
    const data: QuickReplyRepairRequestEditCommentRequest = {
      content,
      imageId: undefined,
      documentIds: [],
    };

    if (image) {
      if (isImageUploaded(image)) {
        data.imageId = image.id;
      } else {
        const uploadedImage = await uploadImage({ file: image.file });
        data.imageId = uploadedImage.id;
      }
    }

    if (document) {
      if (isDocumentUploaded(document)) {
        data.documentIds = [document.id];
      } else {
        const uploadedDocument = await uploadDocument({ file: document.file });
        data.documentIds = [uploadedDocument.id];
      }
    }

    await editReply({
      payload: {
        commentId,
        ...data,
      },
      failureMessage: t("page.tickets.details.edit-note.error"),
    });
  }

  const onChangeStatus = useCallback(
    async (status: QuickReplyRepairRequestStatusDto) => {
      if (status.id === data?.status.id) {
        return;
      }

      await changeStatus({
        request: {
          statusId: status.id,
          silent: false,
        },
        failureMessage: t("page.quick-reply-repair-request.status-change-error"),
      });

      if (status.defaultStatusId === "inProgress" && data?.canAddInternalNote && data?.canAddPublicComment) {
        leaveCommentModalOpenHandlers.setTrue();
      }
    },
    [
      changeStatus,
      data?.canAddInternalNote,
      data?.canAddPublicComment,
      data?.status.id,
      leaveCommentModalOpenHandlers,
      t,
    ],
  );

  if (!data || !loggedInUser) {
    return null;
  }

  const allowedCommentAttachments: BaseCommentFieldProps["allowedAttachments"] = ["image"];
  if (loggedInUser.isAdmin) {
    allowedCommentAttachments.push("document");
  }

  return (
    <div className="min-h-full overflow-x-hidden">
      {!showHeader ? null : (
        <QuickReplyMenuBar
          user={loggedInUser}
          loginUrl={
            data.hasMoreProjects
              ? {
                  pathname: routes.portfolio.tickets(),
                  search: `openTicket=${btoa(`${data.projectId}|${data.requestId}`)}`,
                }
              : routes.tickets.details({
                  id: data.requestId,
                  slug: data.projectSlug,
                })
          }
        />
      )}
      <div
        className={twJoin(
          "flex flex-col items-center justify-center gap-4 px-0 py-2 sm:p-4 xl:flex-row xl:items-start",
          !showHeader ? "pb-0" : "pb-24",
        )}
      >
        <main className="flex max-w-3xl flex-col gap-4 xl:flex-1">
          <TicketInfoHeader
            isChangingStatus={isChangingStatus}
            onChangeAssignee={(assignee) => {
              if (data.canAddInternalNote) {
                setChangedAssignee(assignee);
              } else {
                void changeAssignee({
                  request: { assigneeId: assignee.id },
                  failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
                });
              }
            }}
            onChangeStatus={onChangeStatus}
            project={data.projectName}
            canChangeStatus={canChangeStatus}
            canChangeAssignee={canChangeAssignee}
            ticket={data}
            showProject={showHeader}
            changedAssignee={changedAssignee}
          />
          <div className="rounded-lg bg-white">
            <div className="p-4" data-testid="quick-repair-request-post">
              <div className="flex flex-col gap-2">
                <Headline3 data-testid="ticket-title">{translation?.title || data.title}</Headline3>
                <p data-testid="ticket-content">
                  <LinkFormatter>{translation?.content || data.content}</LinkFormatter>
                </p>
                <Gallery images={data.images} />
              </div>
            </div>

            {data.languageIsoCode !== data.loggedInUser.language.id && (
              <div className="ml-auto px-5 pb-5">
                <Button
                  styling="ghostPrimary"
                  isLoading={translationIsLoading}
                  onClick={() => {
                    if (translation) {
                      onHideTranslation();
                    } else {
                      onTranslate();
                    }
                  }}
                >
                  <span>
                    {translation
                      ? t("page.quick-reply-repair-request.content.translate.original")
                      : t("page.quick-reply-repair-request.content.translate")}
                  </span>
                </Button>
              </div>
            )}
          </div>
          <div className="rounded-lg bg-white">
            {activities && activities.length > 0 ? (
              <div className="px-3">
                <TicketActivitiesSection
                  token={token}
                  languageId={loggedInUser.language.id}
                  user={loggedInUser}
                  activities={activities}
                  commentCount={data.publicCommentCount}
                  isLoadingMore={isFetchingMoreActivities}
                  canLoadMore={hasMoreComments}
                  isReadonly={readonly}
                  onLoadMore={fetchMoreComments}
                  onNoteEdit={setEditingComment}
                />
              </div>
            ) : null}

            {readonly ? (
              <div className="h-5" />
            ) : (
              <form className="p-3">
                <TicketCommentField
                  canAddInternalNote={data.canAddInternalNote}
                  canAddPublicComment={data.canAddPublicComment}
                  onComment={askStatusChangeOrReply}
                  onEditComment={editComment}
                  editingComment={editingComment}
                  onCancelEditComment={() => setEditingComment(undefined)}
                  user={loggedInUser}
                  allowedAttachments={allowedCommentAttachments}
                  showCopilot
                  autoFocus
                />
              </form>
            )}
          </div>
        </main>
        <aside className="flex w-full flex-col gap-4 md:max-w-xs">
          <TicketResidentInfo ticket={data} />

          {data.rems ? (
            <TicketRemsCard token={token} ticketIsCollective={data.isCollective} rems={data.rems} withOutline />
          ) : null}
        </aside>
      </div>

      <TicketLeaveCommentModal
        title={t("page.quick-reply-repair-request.leave-comment-title")}
        description={
          <Trans
            i18nKey="page.quick-reply-repair-request.leave-comment-description"
            components={{
              yellow: <span className="text-yellow-darker" />,
            }}
          />
        }
        user={loggedInUser}
        canCommentInternal={data.canAddInternalNote}
        canCommentPublic={data.canAddPublicComment}
        isOpen={isLeaveCommentModalOpen}
        onClose={leaveCommentModalOpenHandlers.setFalse}
        onSubmit={async (value, note) => {
          try {
            await reply({
              payload: {
                content: value,
                internal: note,
              },
              failureMessage: note
                ? t("page.quick-reply-repair-request.note-error")
                : t("page.quick-reply-repair-request.comment-error"),
            });
          } finally {
            leaveCommentModalOpenHandlers.setFalse();
          }
        }}
      />
      <TicketLeaveCommentModal
        title={
          <Trans
            i18nKey="page.quick-reply-repair-request.assignee-change-title"
            values={{ name: changedAssignee?.fullName }}
            components={{
              bold: <strong className="font-semibold" />,
            }}
          />
        }
        description={
          <Trans
            i18nKey="page.quick-reply-repair-request.assignee-change-description"
            components={{
              yellow: <span className="text-yellow-darker" />,
            }}
          />
        }
        user={loggedInUser}
        canCommentInternal={data.canAddInternalNote}
        canCommentPublic={false}
        isOpen={!!changedAssignee}
        onClose={async () => {
          setChangedAssignee(undefined);
          if (changedAssignee) {
            await changeAssignee({
              request: { assigneeId: changedAssignee.id },
              failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
            });
          }
        }}
        onSubmit={async (note) => {
          if (changedAssignee) {
            try {
              await changeAssignee({
                request: {
                  assigneeId: changedAssignee.id,
                  note,
                },
                failureMessage: t("page.quick-reply-repair-request.assignee-change-error"),
              });
            } finally {
              setChangedAssignee(undefined);
            }
          }
        }}
        assignee={changedAssignee}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-via-email")}
        description={t("component.status-change-modal.description")}
        isOpen={!!targetStatus}
        onClose={() => setTargetStatusId(null)}
        onSubmit={async (status) => {
          try {
            if (status.id === data.status.id) {
              return;
            }

            await changeStatus({
              request: {
                statusId: status.id,
                silent: false,
              },
              failureMessage: t("page.quick-reply-repair-request.status-change-error"),
            });

            if (status.defaultStatusId === "inProgress" && data.canAddInternalNote && data.canAddPublicComment) {
              leaveCommentModalOpenHandlers.setTrue();
            }
          } finally {
            setTargetStatusId(null);
          }
        }}
        targetStatus={targetStatus!}
        possibleStatuses={sortedStatuses}
      />
      <TicketStatusChangeModal
        title={t("component.status-change-modal.title-after-response")}
        description={t("component.status-change-modal.description")}
        isOpen={statusChangeModalState.open}
        onClose={async () => {
          if (!statusChangeModalState.open) {
            return;
          }

          try {
            await onReply(statusChangeModalState.commentPayload);
          } finally {
            setStatusChangeModalState({ open: false });
          }
        }}
        onSubmit={async (status) => {
          if (!statusChangeModalState.open) {
            return;
          }

          try {
            await onReply(statusChangeModalState.commentPayload);
            if (status.id !== data.status.id) {
              await changeStatus({
                request: {
                  statusId: status.id,
                  silent: true,
                },
                failureMessage: t("page.quick-reply-repair-request.status-change-error"),
              });
            }
          } finally {
            setStatusChangeModalState({ open: false });
          }
        }}
        targetStatus={sortedStatuses[0]}
        possibleStatuses={sortedStatuses}
      />
    </div>
  );
}

function useData(token: string) {
  const detailToken = QUERY_KEYS.QUICK_REPLY_REPAIR_DETAILS(token);
  const activitiesToken = QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITIES(token);
  const detailTokenTranslation = [...detailToken, "translation"];
  const ACTIVITY_AMOUNT = 20;

  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const { data, isLoading, error } = useQuery({
    queryKey: detailToken,
    queryFn: () => api.getQuickReplyRepairRequestDetailsV1(token).then(({ data }) => data),
    placeholderData: keepPreviousData,
  });

  const activitiesQuery = useInfiniteQuery({
    queryKey: activitiesToken,
    queryFn: ({ pageParam }) =>
      api
        .getQuickReplyRepairRequestActivitiesV1(token, {
          Offset: pageParam,
          Limit: ACTIVITY_AMOUNT,
        })
        .then((x) => x.data),
    initialPageParam: 0,
    getNextPageParam: (latestResponse, allResponses) => {
      const newOffset = sumBy(allResponses, (page) => page.items.length);

      return latestResponse?.hasMore ? newOffset : undefined;
    },
  });

  const replyMutation = useMutation({
    mutationFn: ({ payload }: { payload: QuickReplyRepairRequestCreateCommentRequest; failureMessage: string }) =>
      api.postQuickReplyRepairRequestReplyV1(token, payload).then((x) => x.data),
    async onSuccess(newComment) {
      if (!data) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        commentCount: data.commentCount + 1,
        publicCommentCount: data.publicCommentCount + (newComment.internal ? 0 : 1),
      });

      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
  });

  const editReplyMutation = useMutation({
    mutationFn: ({
      payload,
    }: {
      payload: QuickReplyRepairRequestEditCommentRequest & { commentId: string };
      failureMessage: string;
    }) =>
      api.putQuickReplyRepairRequestReplyV1(token, payload.commentId, {
        content: payload.content,
        imageId: payload.imageId,
      }),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "success", title: failureMessage });
    },
  });

  const uploadImage = useMutation({
    mutationFn: ({ file }: { file: File }) =>
      api.postQuickReplyRepairRequestReplyImageV1(token, { File: file }).then((res) => res.data),
  });

  const uploadDocument = useMutation({
    mutationFn: ({ file }: { file: File }) =>
      api.postQuickReplyRepairRequestReplyDocumentV1(token, { File: file }).then((res) => res.data),
  });

  const changeAssignee = useMutation({
    mutationFn: ({ request }: { request: QuickReplyRepairRequestAssigneeChangeRequest; failureMessage: string }) =>
      api.putQuickReplyRepairRequestAssigneeV1(token, request).then((res) => res.data),

    onMutate: ({ request }) => {
      if (!data) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        assignee: data.possibleAssignees!.find((x) => x.id === request.assigneeId),
      });
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
  });

  const changeStatus = useMutation({
    mutationFn: ({ request }: { request: QuickReplyRepairRequestStatusChangeRequest; failureMessage: string }) =>
      api.putQuickReplyRepairRequestStatusV1(token, request).then((x) => x.data),

    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
    async onError(_, { failureMessage }) {
      showFlashToast({
        type: "error",
        title: failureMessage,
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({ queryKey: activitiesToken });
    },
  });

  const [showTranslation, showTranslationHandler] = useBool();
  const translation = useQuery({
    queryKey: detailTokenTranslation,
    queryFn: () => api.getQuickReplyRepairRequestTranslationsDetailsV1(token, data?.loggedInUser.language.id || "-"),
    retry: false,
    enabled: showTranslation,
  });

  const activities = useMemo(
    () => activitiesQuery.data?.pages.flatMap((x) => x.items) || [],
    [activitiesQuery.data?.pages],
  );

  return {
    token,
    data,
    error,
    isLoading,
    activities,
    isFetchingMoreActivities: activitiesQuery.isFetchingNextPage,
    hasMoreComments: activitiesQuery.hasNextPage,
    isChangingStatus: changeStatus.isPending,
    translation: showTranslation ? translation.data?.data : undefined,
    translationIsLoading: translation.isLoading,
    onTranslate: showTranslationHandler.setTrue,
    onHideTranslation: showTranslationHandler.setFalse,
    fetchMoreComments: activitiesQuery.fetchNextPage,
    uploadImage: uploadImage.mutateAsync,
    uploadDocument: uploadDocument.mutateAsync,
    reply: replyMutation.mutateAsync,
    editReply: editReplyMutation.mutateAsync,
    changeAssignee: changeAssignee.mutateAsync,
    changeStatus: changeStatus.mutateAsync,
  };
}
