import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Modal } from "components/Modal/Modal";
import { Speedometer } from "modules/home/components/Speedometer";
import type React from "react";
import { useTranslation } from "react-i18next";

export type AIAnalysisModalState = "success" | "unavailable" | "error" | "loading";

type AIAnalysisModalConfig = {
  title: string;
  description?: string;
};

interface AIAnalysisModalProps {
  data: {
    sentiment: number;
    summary: string;
    suggestion?: string;
    hashtags?: string[];
  };
  state: AIAnalysisModalState;
  config: Record<Exclude<AIAnalysisModalState, "loading">, AIAnalysisModalConfig>;
  isOpen: boolean;
  onClose: () => void;
}

export function AIAnalysisModal({ data, state, config, isOpen, onClose }: AIAnalysisModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal onRequestClose={onClose} {...{ isOpen }}>
      <div className="relative flex flex-col items-center gap-10 p-6 md:w-[640px] md:flex-row md:gap-16 lg:w-[740px]">
        {state === "success" && (
          <>
            <div className="flex flex-col items-center gap-4">
              <span className="text-subtitle2 font-semibold leading-none">
                {t("component.sentiment-modal.sentiment-score")}
              </span>
              <div className="w-40">
                <Speedometer score={data.sentiment / 100} fastAnimation />
              </div>
              <div className="flex w-full flex-col items-center gap-2">
                <h4 className="text-headline4 font-semibold leading-none">{data.sentiment} / 100</h4>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <h4 className="text-headline4 font-semibold leading-headline4">{config.success.title}</h4>
                <p className="whitespace-pre-wrap">{data.summary}</p>
              </div>
              {data.suggestion && (
                <div className="flex flex-col gap-2">
                  <h4 className="text-headline4 font-semibold leading-headline4">
                    {t("component.sentiment-modal.suggestion")}
                  </h4>
                  <p className="whitespace-pre-line break-words">{data.suggestion}</p>
                </div>
              )}
              {data.hashtags && (
                <div className="flex flex-col gap-2">
                  <h4 className="text-headline4 font-semibold leading-headline4">
                    {t("component.sentiment-modal.frequently-used-hashtags")}
                  </h4>
                  <div className="flex flex-wrap gap-1 lowercase text-grey-dark">
                    {data.hashtags.map((x) => (
                      <span key={x} className="leading-none">
                        {x.startsWith("#") ? x : "#" + x}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {(state === "unavailable" || state === "error") && (
          <div className="flex flex-col gap-2">
            <h4 className="text-headline4 font-semibold leading-headline4">{config[state].title}</h4>
            {config[state].description && <p className="whitespace-pre-wrap">{config[state].description}</p>}
          </div>
        )}
        {state === "loading" && <FullSizeLoader />}
      </div>
    </Modal>
  );
}
