import { CHAR_CODES, Icon54 } from "components/Icon54/Icon54";
import type { Size } from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";
import type { FixedSizeGridProps } from "react-window";
import { FixedSizeGrid as Grid } from "react-window";
import { twJoin } from "tailwind-merge";

export interface IconPickerProps {
  onChange: (charCode: number) => void;
  selectedCharCode: number | null;
}

interface ItemData {
  columnCount: number;
  selectedCharCode: number | null;
  onChange: (charCode: number) => void;
}

export function IconPicker({ onChange, selectedCharCode }: IconPickerProps): React.ReactNode {
  return (
    <AutoSizer>
      {({ height, width }: Size) => {
        const itemSize = 48;
        const columnCount = Math.floor((width - itemSize / 2) / itemSize);
        const rowCount = Math.ceil(CHAR_CODES.length / columnCount);
        const initialScrollTop = 0;
        // const initialScrollTop = selectedCharCode
        //   ? Math.floor((selectedCharCode - ICON_54.min) / columnCount) * itemSize - height / 2 + itemSize / 2
        //   : undefined;

        return (
          <Grid<ItemData>
            height={height}
            width={width}
            columnWidth={itemSize}
            rowHeight={itemSize}
            columnCount={columnCount}
            rowCount={rowCount}
            itemData={{
              columnCount,
              onChange,
              selectedCharCode,
            }}
            initialScrollTop={initialScrollTop}
          >
            {Cell}
          </Grid>
        );
      }}
    </AutoSizer>
  );
}

function Cell({ style, rowIndex, columnIndex, data }: React.ComponentProps<FixedSizeGridProps<ItemData>["children"]>) {
  const charCode = CHAR_CODES[rowIndex * data.columnCount + columnIndex];
  if (!charCode) {
    return null;
  }

  const isSelected = data.selectedCharCode === charCode;

  return (
    <div className="inline-flex" style={{ ...style, pointerEvents: "auto" }}>
      <button
        className={twJoin(
          "size-11 rounded-2px border hocus:border-aop-basic-blue hocus:outline-none",
          isSelected ? "border-aop-basic-blue bg-blue-lightest" : "border-grey-lighter",
        )}
        data-testid="icon-char-action-select"
        onClick={() => {
          data.onChange(charCode);
        }}
      >
        <Icon54 className="w-[2.125rem] text-[2.125rem]" charCode={charCode} disableUserSelect />
      </button>
    </div>
  );
}
