import type { AdminTicketDetailsDto, SelfDto } from "api/types";

export function changingAssigneeWillResultIn403(
  ticket: AdminTicketDetailsDto,
  assignee: { id: string },
  sessionUser: SelfDto,
): boolean {
  if (!ticket) {
    return false;
  }

  if (sessionUser.isSuperAdmin) {
    return false;
  }

  if (ticket.assignee?.id === assignee.id) {
    return false;
  }

  if (ticket.assignee?.id !== sessionUser.id) {
    return false;
  }

  if (ticket.user.id === sessionUser.id) {
    return false;
  }

  if (sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets) {
    return true;
  } else {
    const categoryPermissions = sessionUser.role.permissions.repairCategories.find(
      (x) => x.categoryId === ticket.category.id,
    );

    if (!categoryPermissions) {
      return false;
    }

    if (categoryPermissions.canListAllRequests) {
      return false;
    }

    if (ticket.visibility !== "private" && categoryPermissions.canListCollectiveRequests) {
      return false;
    }

    return true;
  }
}

export function canSeeServiceHistory(ticket: AdminTicketDetailsDto, sessionUser: SelfDto): boolean {
  return (
    sessionUser.project.type === "addressBased" &&
    (sessionUser.isSuperAdmin ||
      (!sessionUser.isOnlyAllowedToViewAssignedOrOwnTickets &&
        sessionUser.role.permissions.repairCategories.some(
          (x) => x.categoryId == ticket?.category.id && x.canListAllRequests,
        )))
  );
}
