import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommentDto } from "api/types";
import iconCornerDownRight from "assets/icons/corner-down-right.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { motion } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface Props {
  messageId: string;
  comment: CommentDto;
  isGreyedOut: boolean;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  createComment: ({
    comment,
    files,
    failureMessage,
    parentId,
  }: {
    comment: string;
    files: FormImage[];
    parentId?: string;
    failureMessage: string;
  }) => Promise<CommentDto>;
  editComment: ({
    comment,
    commentId,
    files,
    failureMessage,
  }: {
    comment: string;
    commentId: string;
    files: FormImage[];
    failureMessage: string;
  }) => void;
  onLike: (commentId: string) => void;
  onUnlike: (commentId: string) => void;
  onViewCommentLikes: (commentId: string) => void;
}

export function Comment({
  messageId,
  comment,
  isGreyedOut,
  createComment,
  onEdit,
  editComment,
  onDelete,
  onLike,
  onUnlike,
  onViewCommentLikes,
}: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const [hideAllReplies, hideAllRepliesHandler] = useBool(true);
  const [showReplyField, showReplyFieldHandler] = useBool(false);
  const sessionUser = useSessionUser();
  const [editingReply, setEditingReply] = useState<string | undefined>(undefined);
  const [replyImages, setReplyImages] = useState<FormImage[]>([]);
  const {
    addImages: addReplyImages,
    removeImage: removeReplyImage,
    removeImages: removeReplyImages,
  } = useImageInput({ onChange: setReplyImages });
  const { getValues, setValue, watch, register } = useForm<{ replyValue: string }>();

  useEffect(() => {
    register("replyValue");
  }, [register]);

  const replyValue = watch("replyValue");

  async function onReply() {
    const { replyValue } = getValues();

    if (editingReply) {
      editComment({
        comment: replyValue,
        commentId: editingReply,
        files: replyImages,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingReply(undefined);
    } else {
      await createComment({
        parentId: comment.id,
        comment: replyValue,
        files: replyImages,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeReplyImages();
    setValue("replyValue", "");
    showReplyFieldHandler.setFalse();
  }

  const onEditReply = useCallback(
    (comment: CommentDto) => {
      setValue("replyValue", comment.content || "");
      if (comment.image) {
        setReplyImages([comment.image]);
      }
      setEditingReply(comment.id);
      showReplyFieldHandler.setTrue();
    },
    [setValue, setReplyImages, setEditingReply, showReplyFieldHandler],
  );

  const onCancelReplyEdit = useCallback(() => {
    setValue("replyValue", "");
    removeReplyImages();
    setEditingReply(undefined);
    showReplyFieldHandler.setFalse();
  }, [removeReplyImages, setValue, setEditingReply, showReplyFieldHandler]);

  const onChangeReplyContent = useCallback((value: string) => setValue("replyValue", value), [setValue]);

  const onRemoveReplyImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeReplyImage(imageToBeRemoved);
    },
    [removeReplyImage],
  );

  const {
    data: commentDetails,
    isLoading: isLoadingReplies,
    refetch: refetchCommentDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, messageId, comment.id),
    queryFn: () => api.getMessagesCommentsDetailsV1(messageId, comment.id),
    select: commonAPIDataSelector,
    enabled: false,
  });

  function handleLike(comment: CommentDto) {
    if (comment.hasLiked) {
      onUnlike(comment.id);
    } else {
      onLike(comment.id);
    }
  }

  return (
    <div className="flex flex-col">
      <CommentInternal
        messageId={messageId}
        comment={comment}
        showReplyButton={comment.canReply}
        onReply={showReplyFieldHandler.toggle}
        isGreyedOut={isGreyedOut}
        onEdit={onEdit}
        onDelete={onDelete}
        onClickLike={() => handleLike(comment)}
        onViewCommentLikes={onViewCommentLikes}
      />
      <div className="ml-10" data-testid="comment-replies-section">
        {hideAllReplies && comment.latestReply ? (
          <>
            {comment.totalRepliesCount > 1 && (
              <Button
                styling="tertiary"
                className="mb-2 self-start text-aop-basic-blue no-underline"
                onClick={async () => {
                  hideAllRepliesHandler.setFalse();

                  if (!commentDetails && !isLoadingReplies) {
                    try {
                      await refetchCommentDetails();
                    } catch (error) {
                      showFlashToast({
                        type: "error",
                        title: t("component.community-post.comments.reply.show-error"),
                      });
                    }
                  }
                }}
              >
                <span>
                  {t("component.community-post.comments.replies.show-replies", {
                    count: comment.totalRepliesCount - 1,
                  })}
                </span>
              </Button>
            )}
            <CommentInternal
              messageId={messageId}
              comment={comment.latestReply}
              isGreyedOut={isGreyedOut}
              onEdit={onEditReply}
              onDelete={onDelete}
              onClickLike={() => handleLike(comment.latestReply!)}
              onViewCommentLikes={onViewCommentLikes}
            />
          </>
        ) : (
          <>
            {isLoadingReplies && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
                <LoadingIcon className="mx-auto mb-2 w-6" />
              </motion.div>
            )}
            {commentDetails &&
              (commentDetails.replies || [commentDetails.latestReply]).map((reply) => (
                <CommentInternal
                  key={reply.id}
                  messageId={messageId}
                  comment={reply}
                  isGreyedOut={isGreyedOut}
                  onEdit={onEditReply}
                  onDelete={onDelete}
                  onClickLike={() => handleLike(reply)}
                  onViewCommentLikes={onViewCommentLikes}
                />
              ))}
            {!hideAllReplies && (
              <Button
                styling="tertiary"
                className="mb-4 self-start text-aop-basic-blue no-underline"
                onClick={() => hideAllRepliesHandler.setTrue()}
              >
                <span>
                  {t("component.community-post.comments.replies.hide-replies", {
                    count: comment.totalRepliesCount - 1,
                  })}
                </span>
              </Button>
            )}
          </>
        )}
        {showReplyField && (
          <form className="mb-6">
            <div className="flex items-end">
              <CommentFieldWithAvatar
                isReply
                tooltips={{
                  send: editingReply
                    ? t("component.community-post.comments.edit.tooltip")
                    : t("component.community-post.comments.reply.tooltip"),
                }}
                placeholder={
                  editingReply
                    ? t("component.community-post.comments.edit.placeholder")
                    : t("component.community-post.comments.reply.placeholder")
                }
                user={sessionUser}
                value={replyValue}
                onChange={onChangeReplyContent}
                images={replyImages}
                onSubmit={onReply}
                isEdit={editingReply !== undefined}
                onCancel={onCancelReplyEdit}
                allowedAttachments={["image"]}
                onRemoveImage={onRemoveReplyImage}
                onAddImages={addReplyImages}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

interface InternalProps {
  messageId: string;
  comment: CommentDto;
  showReplyButton?: boolean;
  onReply?: () => void;
  isGreyedOut: boolean;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  onClickLike: () => void;
  onViewCommentLikes: (commentId: string) => void;
}

function CommentInternal({
  messageId,
  comment,
  showReplyButton,
  onReply,
  onEdit,
  onDelete,
  onClickLike,
  onViewCommentLikes,
}: InternalProps): React.ReactNode {
  const projectId = useProjectId();
  const { i18n, t } = useTranslation();
  const api = useApi();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();

  const [hideTranslation, hideTranslationHandler] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_COMMENT_TRANSLATION(projectId, messageId, comment.id, sessionUser.language.id),
    queryFn: () => api.getMessagesCommentsTranslationsDetailsV2(messageId, comment.id, sessionUser.language.id),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    comment.content && comment.languageIsoCode !== sessionUser.language.id && !comment.deletedAt ? (
      <Button
        styling="ghostPrimary"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandler.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("component.community-post.comments.translate-error") });
            }
          }
        }}
      >
        {hasTranslation
          ? t("component.community-post.comments.translate.original")
          : t("component.community-post.comments.translate")}
      </Button>
    ) : null;

  const content =
    comment.deletedAt && !comment.content
      ? t("component.community-post.comments.deleted")
      : hasTranslation
        ? translation.data?.data.content
        : comment.content;

  return (
    <div
      className={twJoin("flex rounded-3px pb-4 pt-2", comment.deletedAt && "text-grey-light first:pt-0")}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 mt-2.5 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatarLink user={comment.author} hideDeletedIcon />
      </div>
      <div className="grid flex-1 grid-cols-1">
        <div className="rounded-lg bg-grey-lightest/50 px-4 py-2">
          <div className={twJoin("flex w-full items-start", comment.deletedAt ? "text-grey-light" : undefined)}>
            <div className="flex w-full flex-wrap items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-1">
                  <UserNameLink user={comment.author}>
                    <Subtitle2 className={twJoin("truncate", comment.deletedAt ? "text-grey-light" : "text-black")}>
                      {comment.author.fullName}
                    </Subtitle2>
                  </UserNameLink>
                  {!!comment.author.deletedAt && <UserDeletedTag />}
                </div>
                <Capture2 className="text-grey">
                  {t("component.community-post.comments.posted-at", {
                    dateTime: formatDistance(i18n, { start: new Date(comment.postedAt) }),
                  })}
                </Capture2>
              </div>
              {comment.deletedAt && (
                <Tooltip tooltip={<FormattedDate date={comment.deletedAt} format="datetime" />}>
                  <Capture2
                    className={
                      "relative ml-1 cursor-default italic text-grey after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey after:content-[''] hover:after:block"
                    }
                  >
                    (
                    {t("component.community-post.comments.deleted-at", {
                      time: formatDate(i18n, "datetime", comment.deletedAt),
                    })}
                    )
                  </Capture2>
                </Tooltip>
              )}
              {!comment.deletedAt && comment.updatedAt && (
                <Capture2 className="text-grey">
                  {t("component.community-post.comments.last-edited-at", {
                    dateTime: formatDate(i18n, "datetime", comment.updatedAt),
                  })}
                </Capture2>
              )}
            </div>
          </div>

          {content && (
            <div className="flex flex-col gap-2">
              <p
                className={twJoin("flex-1", comment.deletedAt && comment.content ? "line-through" : undefined)}
                data-testid="comment-content"
              >
                <LinkFormatter>{content}</LinkFormatter>
              </p>
              {translateButton}
            </div>
          )}

          {comment.image && (
            <div className="max-w-full pt-2">
              <Gallery
                images={[comment.image]}
                isDeleted={!!comment.deletedAt}
                data-testid="comment-image-attachment"
              />
            </div>
          )}
        </div>
        <div className="-ml-1 flex flex-wrap gap-2 pt-2 text-grey">
          {comment.canLike || comment.totalLikesCount > 0 ? (
            <Button
              styling="ghostSecondary"
              data-testid="like-comment"
              onClick={onClickLike}
              size="sm"
              title={t("common.action.like")}
              disabled={!comment.canLike}
            >
              <Icon name={iconThumbsUp} size={16} />
            </Button>
          ) : null}
          {comment.totalLikesCount > 0 && (
            <Button
              size="sm"
              styling="ghostSecondary"
              data-testid="like-list"
              onClick={() => onViewCommentLikes(comment.id)}
            >
              {t("component.community-post.comment.likes.total", { count: comment.totalLikesCount })}
            </Button>
          )}
          {showReplyButton && (
            <Button
              styling="ghostSecondary"
              size="sm"
              title={t("component.community-post.comments.reply")}
              data-testid="reply-comment"
              onClick={onReply}
              icon={<Icon name={iconCornerDownRight} size={16} />}
            >
              {comment.totalRepliesCount ? comment.totalRepliesCount : null}
            </Button>
          )}
          {comment.canEdit && (
            <Button size="sm" styling="ghostSecondary" data-testid="edit-comment" onClick={() => onEdit(comment)}>
              {t("common.action.edit")}
            </Button>
          )}
          {comment.canDelete && (
            <Button size="sm" styling="ghostSecondary" data-testid="delete-comment" onClick={() => onDelete(comment)}>
              {t("common.action.delete")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
