import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EventDto, ImageDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconPlus from "assets/icons/plus.svg";
import { ToggleButton } from "components/Button/ToggleButton";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { UserAvatarList } from "components/UserAvatarList/UserAvatarList";
import { format, parseISO } from "date-fns";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageEvents } from "modules/events/permissions";
import { getEventCategoryName } from "modules/events/util";
import { usePostHog } from "posthog-js/react";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

interface EventCardProps {
  event: EventDto;
  isPast?: boolean;
}

export function EventCard({ event, isPast }: EventCardProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const posthog = usePostHog();

  const hasPermission = usePermission();
  const showFlashToast = useFlashToast();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-event-modal");

  const { mutateAsync: deleteEvent } = useMutation({
    mutationFn: (data: { id: string }) => api.deleteEventsByIdV1(data.id).then(({ data }) => data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.event.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.event.action.delete.notification.error"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId) });
    },
  });

  const { mutate: signUp, isPending: isSigningUp } = useMutation({
    mutationFn: (id: string) => api.postEventsSignupV1(id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId) });
    },
  });
  const { mutate: signOff, isPending: isSigningOff } = useMutation({
    mutationFn: (id: string) => api.postEventsSignoffV1(id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId) });
    },
  });

  const isLoading = isSigningUp || isSigningOff;

  function getSpotsLabel(): string | null {
    const participantsExist = isDefined(event.maxParticipants) && isDefined(event.participantCount);
    const isFull = participantsExist ? event.participantCount! >= event.maxParticipants! : false;
    const restSpotsAmount = participantsExist && !isFull ? event.maxParticipants! - event.participantCount! : null;
    const noLimit = event.maxParticipants == null;

    if (event.isSignedUp) {
      return t("page.calendar.events.spots.is-signed-up");
    }

    if (isFull) {
      return t("page.calendar.events.spots.no-spots");
    }

    if (!event.canParticipate) {
      return null;
    }

    if (isDefined(restSpotsAmount)) {
      return t("page.calendar.events.spots.free-spots", { count: event.maxParticipants! - event.participantCount! });
    }

    if (noLimit) {
      return t("page.calendar.events.spots.is-not-signed-up");
    }

    return null;
  }

  const onClickToggleSignUp = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (event.isSignedUp) signOff(event.id);
    else signUp(event.id);
  };

  const actions: ContextMenuAction[] = useMemo(() => {
    const actions = [];
    if (event.canEdit) {
      actions.push({
        text: t("common.action.edit"),
        callback: () => {
          if (parseISO(event.startsAt) < new Date()) {
            showFlashToast({
              type: "info",
              title: t("page.calendar.events.edit.notification.is-started"),
            });

            return;
          }
          navigate(routes.events.edit({ slug, id: event.id }));
        },
      });
    }

    if (hasPermission(canManageEvents)) {
      actions.push({
        text: t("common.action.duplicate"),
        callback: () => {
          navigate(routes.events.create({ slug }), { state: { id: event.id } });
        },
      });
    }
    if (event.canDelete) {
      actions.push({ text: t("common.action.delete"), callback: () => openDeleteModal(event.id) });
    }

    return actions;
  }, [t, event, slug, navigate, showFlashToast, hasPermission, openDeleteModal]);

  return (
    <>
      <div
        data-testid="event-card"
        className="relative cursor-pointer rounded-lg border border-grey-lightest bg-white shadow-sm hover:shadow-lg"
        onClick={() => {
          posthog?.capture("clicked_event");
          navigate(routes.events.details({ slug, id: event.id }));
        }}
      >
        <div className="flex h-full items-center gap-4 px-4 py-2.5">
          <div className="flex h-full flex-col items-center justify-center gap-1 rounded-lg border border-grey-lightest px-2 py-1">
            <Capture2 className="text-grey">{formatDate(i18n, "weekDayShort", parseISO(event.startsAt))}</Capture2>
            <Headline4 className={isPast ? "text-grey-dark" : undefined}>
              {parseISO(event.startsAt).getDate()}
            </Headline4>
            <Subtitle2 className="font-normal text-grey">
              {formatDate(i18n, "monthShort", parseISO(event.startsAt))}
            </Subtitle2>
          </div>
          <div className="flex w-full flex-col gap-1">
            <div className="flex w-full justify-between">
              <div className="flex flex-wrap gap-2">
                {!event.publishedAt && (
                  <Label theme="blue" center={false}>
                    <div className="flex gap-1 whitespace-nowrap">
                      {t("page.calendar.events.scheduled")}
                      <FormattedDate date={event.publishAt} format="datetimeShort" />
                    </div>
                  </Label>
                )}
                {event.category && (
                  <Label theme="gray" center={false}>
                    {getEventCategoryName(t, event.category.id)}
                  </Label>
                )}
              </div>
              <ContextMenu actions={actions} />
            </div>
            <Subtitle2 className={isPast ? "text-grey-dark" : undefined}>{event.name}</Subtitle2>
            <span className="flex flex-wrap items-center gap-1 text-grey-darker">
              <Capture2 className="whitespace-nowrap">{`${format(parseISO(event.startsAt), "HH:mm")} - ${format(parseISO(event.endsAt), "HH:mm")}`}</Capture2>
              {event.location && <Capture2>• {event.location}</Capture2>}
              {event.updatedAt && (
                <Capture2 className="whitespace-nowrap font-semibold">• {t("page.calendar.events.edited")}</Capture2>
              )}
            </span>
            {!isPast && <Capture2 className="text-grey-dark">{getSpotsLabel()}</Capture2>}
            <div className="flex w-full flex-wrap items-center justify-between gap-2">
              <UserAvatarList
                images={event.participants.filter((x) => x.avatar !== undefined).map((x) => x.avatar as ImageDto)}
                limit={3}
                totalElements={event.participantCount}
              />
              {!isPast && event.canParticipate && (
                <ToggleButton
                  config={{
                    checked: {
                      label: t("page.calendar.events.leave"),
                      icon: <Icon name={iconCheck} size={16} />,
                    },
                    unchecked: {
                      label: t("page.calendar.events.join"),
                      icon: <Icon name={iconPlus} size={16} />,
                    },
                  }}
                  size="sm"
                  onClick={onClickToggleSignUp}
                  isChecked={event.isSignedUp}
                  {...{ isLoading }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        title={t("page.calendar.events.delete.modal.title")}
        description={t("page.calendar.events.delete.modal.description")}
        onDelete={(id) => deleteEvent({ id: id })}
        deleteBtnProps={{
          "data-testid": "delete-event-modal",
        }}
        {...deleteModalProps}
      />
    </>
  );
}
