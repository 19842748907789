import { Capture2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import { twJoin } from "tailwind-merge";

export function FormContent({
  maxWidth,
  children,
  className,
  ...props
}: React.PropsWithChildren<{
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "none";
  "data-testid"?: string;
  className?: string;
}>): React.ReactNode {
  return (
    <div
      {...props}
      className={twResolve(
        "mb-7 flex flex-col gap-7",
        maxWidth === "none"
          ? undefined
          : maxWidth === "4xl"
            ? "max-w-4xl"
            : maxWidth === "3xl"
              ? "max-w-3xl"
              : maxWidth === "2xl"
                ? "max-w-2xl"
                : maxWidth === "xl"
                  ? "max-w-xl"
                  : maxWidth === "lg"
                    ? "max-w-lg"
                    : maxWidth === "md"
                      ? "max-w-md"
                      : "max-w-sm",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function FormSplitContent({
  splitAt,
  children,
}: React.PropsWithChildren<{ splitAt?: "sm" | "md" | "lg" | "xl" | "2xl" }>): React.ReactNode {
  return (
    <div
      className={twJoin(
        "flex flex-col justify-items-stretch gap-5",
        splitAt === "2xl"
          ? "2xl:flex-row 2xl:gap-7"
          : splitAt === "xl"
            ? "2xl:flex-row 2xl:gap-7"
            : splitAt === "lg"
              ? "lg:flex-row lg:gap-7"
              : splitAt === "md"
                ? "md:flex-row md:gap-7"
                : "sm:flex-row sm:gap-7",
      )}
    >
      {children}
    </div>
  );
}

type FormCheckboxGridProps<T extends { label: string; [key: string]: React.ReactNode }> = {
  rows: T[];
  headerLabels: { [key in Exclude<keyof T, "label">]: string };
};

export function FormCheckboxGrid<T extends { label: string }>({
  headerLabels,
  rows,
}: FormCheckboxGridProps<T>): React.ReactNode {
  if (rows.length === 0) {
    return null;
  }

  const labels = Object.entries<string>(headerLabels);

  return (
    <table>
      <thead>
        <tr>
          {labels.map(([key, value]) => (
            <th className="whitespace-nowrap px-2 first-of-type:pl-0" key={key}>
              <Capture2>{value}</Capture2>
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {labels.map(([key]) => (
              <td
                className={twJoin("px-2 text-center leading-[0] first-of-type:pl-0", i === 0 ? "pt-1.5" : "pt-3")}
                key={key}
              >
                {row[key as keyof typeof row] as React.ReactNode}
              </td>
            ))}
            <td className={i === 0 ? "pt-1.5" : "pt-3"}>{row.label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
