import { Overline2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import { get } from "lodash-es";
import type { PropsWithChildren } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useFormState } from "react-hook-form";

interface Props<TFormValues extends FieldValues> {
  name: FieldPath<TFormValues>;
  className?: string;
  "data-testid"?: string;
  subtext?: React.ReactNode;
  encircle?: boolean;
  hideErrorText?: boolean;
}

export function FormErrorWrapper<TFormValues extends FieldValues>(
  props: PropsWithChildren<Props<TFormValues>>,
): React.ReactNode {
  const { errors } = useFormState<TFormValues>({ name: props.name });
  const name = props.name;
  const error = name ? get(errors, name) : null;
  const isError = error != null;

  return (
    <div
      className={twResolve(
        "relative max-w-full",
        props.encircle && isError ? "rounded-lg border-red-dark p-1" : undefined,
        props.className,
      )}
      data-testid={props["data-testid"]}
    >
      {props.children}
      {!props.hideErrorText && name != null && (
        <div
          className={twResolve(
            "absolute top-full mt-1 flex min-h-5 w-full justify-between text-grey-dark",
            isError ? "text-red-dark" : undefined,
          )}
          data-testid="form-input-meta"
        >
          {isError && (
            <Overline2 className="whitespace-nowrap" data-testid="form-input-error" role="alert">
              {error.message as string}
            </Overline2>
          )}
          {props.subtext ? <Overline2 className="ml-auto mr-0 whitespace-nowrap">{props.subtext}</Overline2> : null}
        </div>
      )}
    </div>
  );
}
