import iconCheck from "assets/icons/check.svg";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

export function TipsModal({ isOpen, onClose }: Props): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen === true} onRequestClose={onClose} shouldCloseOnEsc>
      <div className="max-w-md rounded p-6">
        <Subtitle2 className="mb-4 block">{t("page.message-feed.create.tips.title")}</Subtitle2>
        <ul className="flex flex-col gap-3 text-grey-darkest">
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.1")}</Capture2>
          </li>
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.2")}</Capture2>
          </li>
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.3")}</Capture2>
          </li>
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.4")}</Capture2>
          </li>
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.5")}</Capture2>
          </li>
          <li className="flex items-center gap-2">
            <Icon name={iconCheck} size={16} />
            <Capture2>{t("page.message-feed.create.tips.6")}</Capture2>
          </li>
        </ul>
      </div>
    </Modal>
  );
}
