import { captureException, ErrorBoundary } from "@sentry/react";
import { ApiClientProvider } from "api/ApiClientProvider";
import Logo403 from "assets/images/403.svg";
import Logo404 from "assets/images/404.svg";
import Logo500 from "assets/images/500.svg";
import Logo503 from "assets/images/503.svg";
import { Anchor, AnchorButton } from "components/Anchor/Anchor";
import { SUPPORT_EMAIL } from "helpers/constants";
import { isHttpError } from "helpers/Network/errors";
import { ConfigProvider } from "providers/ConfigProvider";
import { Fragment, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useInRouterContext, useRouteError } from "react-router-dom";
import { TranslationsProvider } from "translations/TranslationsProvider";

import { ConnectionFailurePage } from "./ConnectionFailurePage";
import { ErrorPageInner } from "./ErrorPageInner";

interface ErrorPageProps {
  status?: number;
  error?: unknown;
}

interface ErrorInnerPageContentProps {
  content: {
    title: string;
    status: string;
    sessionId?: string;
    solutions: (React.ReactNode | string)[];
    image: string;
  };
}

export function ErrorPage({ status, error }: ErrorPageProps): React.ReactNode {
  status = status || (isHttpError(error) ? error.status : undefined);

  const { ready } = useTranslation();
  const isInRouter = useInRouterContext();

  const TranslationWrapper = ready
    ? Fragment
    : ({ children }: { children: React.ReactNode }) => (
        <ErrorBoundary fallback={<ConnectionFailurePage />}>
          <ConfigProvider>
            <ApiClientProvider>
              <TranslationsProvider>{children}</TranslationsProvider>
            </ApiClientProvider>
          </ConfigProvider>
        </ErrorBoundary>
      );
  const Logger = isInRouter ? RouterErrorLogger : ErrorLogger;

  return (
    <TranslationWrapper>
      <Logger error={error} status={status}>
        {getErrorPage(status)}
      </Logger>
    </TranslationWrapper>
  );
}

function RouterErrorLogger({
  error,
  status,
  children,
}: {
  error?: unknown;
  status?: number;
  children: React.ReactNode;
}) {
  const routeError = useRouteError();

  error = error || routeError;

  return (
    <ErrorLogger error={error} status={status}>
      {children}
    </ErrorLogger>
  );
}

function ErrorLogger({ error, status, children }: { error?: unknown; status?: number; children: React.ReactNode }) {
  if (!status && isHttpError(error)) {
    status = error.status;
  }

  useEffect(() => {
    if (import.meta.env.DEV) {
      console.error("Error page", error);
    }

    if (error) {
      captureException(error);
    } else if (status) {
      captureException(`Request failed with status ${status}`, {
        level: status === 404 || status === 403 ? "warning" : "error",
      });
    }
  }, [error, status]);

  return children;
}

function getErrorPage(status?: number) {
  switch (status) {
    case 404:
      return <NotFoundPage />;
    case 403:
      return <ForbiddenPage />;
    case 500:
      return <InternalServerErrorPage />;
    case 503:
      return <GenericErrorPage status="503" />;
    default:
      return <GenericErrorPage status="Unknown" />;
  }
}

function GenericErrorPage({ status }: { status: string }) {
  const { t } = useTranslation();
  const solutions: (React.ReactNode | string)[] = [];

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.refresh"
      values={{ page: t("page.error.generic.refresh") }}
      components={{
        anchor: <AnchorButton isBold onClick={() => location.reload()} />,
      }}
    />,
  );

  solutions.push(t("page.error.generic.solutions.later"));

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.contact"
      components={{
        email: (
          <Anchor isExternal isBold to={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Anchor>
        ),
      }}
    />,
  );

  return (
    <ErrorPageTemplate
      content={{
        title: t("page.error.generic.sorry"),
        status: status,
        solutions,
        image: Logo503,
      }}
    />
  );
}

function InternalServerErrorPage() {
  const { t } = useTranslation();
  const solutions: (React.ReactNode | string)[] = [];

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.refresh"
      values={{ page: t("page.error.generic.refresh") }}
      components={{
        anchor: <AnchorButton isBold onClick={() => location.reload()} />,
      }}
    />,
  );

  solutions.push(t("page.error.generic.solutions.later"));

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.link"
      values={{ page: t("page.error.generic.solutions.link.name") }}
      components={{
        anchor: <Anchor isBold to="/" />,
      }}
    />,
  );

  solutions.push(
    <Trans
      i18nKey="page.error.server.solutions.contact"
      components={{
        email: (
          <Anchor isExternal isBold to={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Anchor>
        ),
      }}
    />,
  );

  return (
    <ErrorPageTemplate
      content={{
        title: t("page.error.server.sorry"),
        status: "500",
        solutions,
        image: Logo500,
      }}
    />
  );
}

function ForbiddenPage() {
  const { t } = useTranslation();
  const solutions: (React.ReactNode | string)[] = [];

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.link"
      values={{ page: t("page.error.generic.solutions.link.name") }}
      components={{
        anchor: <Anchor isBold to="/" />,
      }}
    />,
  );

  solutions.push(
    <Trans
      i18nKey="page.error.forbidden.solutions.contact"
      components={{
        email: (
          <Anchor isExternal isBold to={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Anchor>
        ),
      }}
    />,
  );

  return (
    <ErrorPageTemplate
      content={{
        title: t("page.error.forbidden.sorry"),
        status: "403",
        solutions,
        image: Logo403,
      }}
    />
  );
}

function NotFoundPage() {
  const { t } = useTranslation();
  const solutions: (React.ReactNode | string)[] = [];

  solutions.push(
    <Trans
      i18nKey="page.error.generic.solutions.link"
      values={{ page: t("page.error.generic.solutions.link.name") }}
      components={{
        anchor: <Anchor isBold to="/" />,
      }}
    />,
  );

  solutions.push(t("page.error.not-found.solutions.check"));

  return (
    <ErrorPageTemplate
      content={{
        title: t("page.error.not-found.sorry"),
        status: "404",
        solutions,
        image: Logo404,
      }}
    />
  );
}

function ErrorPageTemplate({ content }: ErrorInnerPageContentProps) {
  const { t } = useTranslation();

  return (
    <ErrorPageInner
      labels={{
        error: t("page.error.generic.code"),
        solutions: t("page.error.generic.solutions.title"),
      }}
      content={content}
    />
  );
}
