import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Headline4, Overline2 } from "components/Text/Text";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ExportBookingsModalProps {
  onExport: (includeHistory: boolean) => void;
  onClose: () => void;
}

export function ExportBookingsModal({ onExport, onClose }: ExportBookingsModalProps): React.ReactNode {
  const [isHistoryIncluded, setIsHistoryIncluded] = useState(false);
  const { t } = useTranslation();

  const onClickExport = () => {
    onExport(isHistoryIncluded);
    onClose();
  };

  return (
    <div className="flex flex-col gap-4 p-8">
      <Headline4>{t("page.bookings.asset-detail.export.modal.title")}</Headline4>
      <span className="flex items-center gap-2">
        <Checkbox name="includeHistory" onChange={(e) => setIsHistoryIncluded(e.target.checked)} />
        <label className="text-capture2" htmlFor="includeHistory">
          {t("page.bookings.asset-detail.export.modal.checkbox")}
        </label>
      </span>
      <Overline2 className="text-grey">{t("page.bookings.asset-detail.export.modal.description")}</Overline2>
      <div className="flex items-center gap-2">
        <Button styling="secondary" onClick={onClose}>
          {t("common.action.cancel")}
        </Button>
        <Button styling="primary" onClick={onClickExport}>
          {t("page.bookings.asset-detail.export.modal.button")}
        </Button>
      </div>
    </div>
  );
}
