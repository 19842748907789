import type { EventCategoryDto, UserDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import markerIcon from "assets/icons/marker-pin-04.svg";
import iconPlus from "assets/icons/plus.svg";
import toolIcon from "assets/icons/tool-02.svg";
import iconUser01 from "assets/icons/user-01.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateRange } from "components/DateRange/DateRange";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { AppUserItem } from "components/Phone/AppCommon";
import { PhoneInner } from "components/Phone/PhoneInner";
import { getEventCategoryName } from "modules/events/util";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface AppEventDetailsViewProps {
  name: string;
  description: string;
  location: string;
  startDate: DatePickerValue;
  endDate: DatePickerValue;
  participantCount: number;
  maxParticipants?: number;
  image?: string;
  iconChar?: string;
  isAnnouncement?: boolean;
  category?: EventCategoryDto;
  projectConnection?: string;
  noParticipantsLimit?: boolean;
  organizer: UserDto;
  projectTintColor?: string;
  isEditMode?: boolean;
}

export function AppEventDetailsView({
  name,
  description,
  image,
  maxParticipants = 0,
  location,
  startDate,
  endDate,
  participantCount,
  isAnnouncement,
  category,
  projectConnection,
  noParticipantsLimit,
  organizer,
  projectTintColor,
  isEditMode,
}: AppEventDetailsViewProps): React.ReactNode {
  const { i18n, t } = useTranslation();
  const availableSpotsAmount = useMemo(() => {
    const value = maxParticipants - participantCount;
    if (value <= 0) {
      return 0;
    }

    return value;
  }, [maxParticipants, participantCount]);

  return (
    <PhoneInner>
      <div className="h-36 bg-grey bg-cover bg-center" style={{ backgroundImage: `url(${image || bgPlaceholder})` }} />
      <div className="relative bg-white px-2.5 pb-1 pt-2.5">
        <div className="flex flex-col gap-2">
          {category?.id === "maintenance" ? (
            <Label theme="purple" size="normal">
              <Icon name={toolIcon} size={12} />
              <span className="text-[8px] font-semibold">{getEventCategoryName(t, category.id)}</span>
            </Label>
          ) : null}
          {projectConnection ? (
            <Label theme="gray" size="normal">
              <Icon name={markerIcon} size={12} />
              <span className="text-[8px] font-semibold">{projectConnection}</span>
            </Label>
          ) : null}
        </div>
        <div className="mb-2 mt-3 flex items-center gap-1 leading-[12px] text-black">
          <Icon name={iconCalendar} size={12} />
          {startDate !== "" && endDate !== "" ? (
            <>
              <DateRange start={startDate.toISOString()} end={endDate.toISOString()} />
            </>
          ) : (
            "-"
          )}
        </div>
        <div className="flex flex-nowrap items-center justify-between gap-4">
          <div className="line-clamp-3 break-all text-[15px] font-semibold">{name || "-"}</div>
          {!isAnnouncement && (availableSpotsAmount > 0 || noParticipantsLimit) && (
            <div className="relative flex h-fit shrink-0 items-center justify-center gap-1 overflow-hidden rounded px-4 py-2 text-[12px] font-semibold text-aop-dark-blue">
              <div
                className="absolute left-0 top-0 size-full opacity-10"
                style={{
                  backgroundColor: projectTintColor || "#23175A",
                }}
              />
              <Icon name={iconPlus} size={12} />
              <span
                style={{
                  color: projectTintColor || "#23175A",
                }}
              >
                {t("model.event.join")}
              </span>
            </div>
          )}
        </div>

        {isEditMode ? (
          <div className="my-2 line-clamp-3 break-all text-[10px] font-semibold uppercase text-grey">
            {t("model.event.edited")}
          </div>
        ) : null}

        {location ? (
          <div className="my-2 flex items-center gap-1 text-black">
            <Icon
              name={iconMarkerPin01}
              size={12}
              style={{
                color: projectTintColor || "#23175A",
              }}
            />
            <span className="leading-3">{location}</span>
          </div>
        ) : null}

        <div className="my-2 flex items-center gap-1 text-black">
          <Icon
            name={iconUser01}
            size={12}
            style={{
              color: projectTintColor || "#23175A",
            }}
          />
          <span className="leading-3">{organizer.fullName}</span>
        </div>

        {!isAnnouncement && availableSpotsAmount > 0 && (
          <p className="my-2 leading-[10px] text-grey-darkest">
            {t("model.event.spots.spots-available", { count: availableSpotsAmount })}
          </p>
        )}

        <p className="my-4 whitespace-pre-line break-all">{description || "-"}</p>

        {isEditMode ? (
          <div className="my-2 line-clamp-3 break-all text-[8px] text-grey-light">
            {t("model.event.last-edit", { date: formatDate(i18n, "datetime", new Date()) })}
          </div>
        ) : null}
      </div>
      {!isAnnouncement && availableSpotsAmount > 0 && (
        <>
          <div className="bg-aop-off-white px-2.5 pb-2 pt-4 font-semibold uppercase text-black">
            {t("model.event.participant-count", { count: availableSpotsAmount })}
          </div>
          <div className="bg-aop-off-white">
            <ul className="flex flex-col gap-0.5">
              {Array.from({ length: Math.min(availableSpotsAmount, NUMBER_OF_PARTICIPANTS) }, () => undefined).map(
                (_, i) => (
                  <AppUserItem
                    key={i}
                    fullName={t("model.event.preview-participant.name")}
                    subTitle={t("model.event.preview-participant.address")}
                  />
                ),
              )}
            </ul>
          </div>
        </>
      )}
    </PhoneInner>
  );
}

const NUMBER_OF_PARTICIPANTS = 4;
