import { Input } from "antd";
import { Button } from "components/Button/Button";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { resyncSessionAndFetchMFAInfo } from "supertokens-web-js/recipe/multifactorauth";
import {
  clearLoginAttemptInfo,
  consumeCode,
  createCode,
  getLoginAttemptInfo,
} from "supertokens-web-js/recipe/passwordless";
import Session from "supertokens-web-js/recipe/session";

import { AuthenticationPage } from "../components/AuthenticationPage";

// THIS IS NOT IN USE IN THE CURRENT IMPLEMENTATION
export function MultiFactorAuthenticationPage(): React.ReactNode {
  const navigate = useNavigate();

  const [hasSent, setHasSent] = useState(false);

  const [email, setEmail] = useState("");

  useEffect(() => {
    let cancelled = false;

    void (async () => {
      if (await Session.doesSessionExist()) {
        try {
          const mfaInfo = await resyncSessionAndFetchMFAInfo();
          if (cancelled) {
            return;
          }

          const factorEmails = mfaInfo.emails;
          const factorPhoneNumbers = mfaInfo.phoneNumbers;

          const emailsForOTPEmail = factorEmails["otp-email"];
          const phoneNumbersForOTPSms = factorEmails["otp-sms"];
          const isTotpSetup = mfaInfo.factors.alreadySetup.includes("totp");
          const isOTPEmailSetup = mfaInfo.factors.alreadySetup.includes("otp-email");
          const isOTPSmsSetup = mfaInfo.factors.alreadySetup.includes("otp-sms");
          const next = mfaInfo.factors.next;

          const factorsAllowedToBeSetup = mfaInfo.factors.allowedToSetup;

          const loginAttemptInfo = await getLoginAttemptInfo();
          if (loginAttemptInfo && !cancelled) {
            setHasSent(true);

            return;
          }

          if (emailsForOTPEmail && next[0] === "otp-email") {
            setEmail(emailsForOTPEmail[0]);
          } else {
            console.error("Unknown MFA state", {
              emailsForOTPEmail,
              phoneNumbersForOTPSms,
              isTotpSetup,
              isOTPEmailSetup,
              isOTPSmsSetup,
              next,
              factorsAllowedToBeSetup,
              factorPhoneNumbers,
            });
          }
        } catch (err: any) {
          if (cancelled) {
            return;
          }

          if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
          } else {
            window.alert("Oops! Something went wrong.");
          }
        }
      } else {
        throw new Error("Illegal function call: For first factor setup, you do not need to call this function");
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [navigate]);

  const onSendCode = useCallback(async () => {
    try {
      const response = await createCode({
        email,
      });

      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in / up was not allowed.
        window.alert(response.reason);
      } else {
        // OTP sent successfully.
        setHasSent(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }, [email]);

  const onVerifyCode = useCallback(
    async (otp: string) => {
      try {
        // TODO: Improve error handling

        const response = await consumeCode({
          userInputCode: otp,
        });

        if (response.status === "OK") {
          // we clear the login attempt info that was added when the createCode function
          // was called since the login was successful.
          await clearLoginAttemptInfo();
          if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
            // user sign up success
          } else {
            // user sign in success
          }
          navigate("/home");
        } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
          // the user entered an invalid OTP
          window.alert(
            "Wrong OTP! Please try again. Number of attempts left: " +
              (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount),
          );
        } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
          // it can come here if the entered OTP was correct, but has expired because
          // it was generated too long ago.
          window.alert("Old OTP entered. Please regenerate a new one and try again");
        } else {
          // this can happen if the user tried an incorrect OTP too many times.
          // or if it was denied due to security reasons in case of automatic account linking

          // we clear the login attempt info that was added when the createCode function
          // was called - so that if the user does a page reload, they will now see the
          // enter email / phone UI again.
          await clearLoginAttemptInfo();
          window.alert("Login failed. Please try again");
          navigate(routes.authentication.login());
        }
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
        } else {
          window.alert("Oops! Something went wrong.");
        }
      }
    },
    [navigate],
  );

  const [code, setCode] = useState("");

  return (
    <AuthenticationPage header={"MFA"} body={hasSent ? "Check your inbox" : "Please complete MFA"}>
      <div className="mt-4">
        {hasSent ? (
          <div className="flex flex-col gap-2">
            <p className="-mb-1 text-left font-bold">Code</p>
            <Input value={code} onChange={(e) => setCode(e.target.value)} />
            <Button onClick={() => onVerifyCode(code)}>Submit</Button>
          </div>
        ) : (
          <Button onClick={() => onSendCode()}>Send code</Button>
        )}
      </div>
    </AuthenticationPage>
  );
}
