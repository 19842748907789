import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";

interface IqbiDetailsVerifyAddressModalProps {
  serviceId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function IqbiDetailsVerifyAddressModal({
  serviceId,
  isOpen,
  onClose,
}: IqbiDetailsVerifyAddressModalProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();

  const mutationVerify = useMutation({
    mutationFn: () => api.postServicepartnersIqbiConfirmAddressV1(),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.service.action.iqbi-verify.notification.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNER(projectId, serviceId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.service.action.iqbi-verify.notification.error"),
      });
    },
  });

  return (
    <ConfirmModal
      id="iqbi-address"
      title={t("page.service-details.iqbi.verify-modal.title")}
      isLoading={mutationVerify.isPending}
      onResolve={() => {
        mutationVerify.mutate();
        onClose();
      }}
      onReject={onClose}
      resolveBtnProps={{
        text: t("page.service-details.iqbi.verify-modal.confirm-btn"),
      }}
      rejectBtnProps={{
        text: t("page.service-details.iqbi.verify-modal.reject-btn"),
      }}
      {...{ isOpen }}
    >
      <div className="flex flex-col gap-4 text-center">
        <p>{t("page.service-details.iqbi.verify-modal.description")}</p>
        <p>
          {sessionUser.address ? (
            <>
              {sessionUser.address.location}
              <br />
              {sessionUser.address?.zipCode} {sessionUser.address?.city}
            </>
          ) : (
            sessionUser.company?.name
          )}
        </p>
      </div>
    </ConfirmModal>
  );
}
