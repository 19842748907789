import { useLogout } from "authentication/useLogout";
import { Anchor } from "components/Anchor/Anchor";
import { AuthCodeInput } from "components/AuthCodeInput/AuthCodeInput";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { isHttpError } from "helpers/Network/errors";
import { AuthenticationPage } from "modules/authentication/components/AuthenticationPage";
import { useForm, useFormState } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

export interface LayoutProps {
  onRegister: (code: string) => unknown;
  isSubmitting: boolean;
  isLoading: boolean;
  error: unknown;
  validationError?: "unknownLocation" | "locationUserLimitReached";
}

export function Layout({ onRegister, isLoading, isSubmitting, error, validationError }: LayoutProps): React.ReactNode {
  const logout = useLogout();
  const { t } = useTranslation();
  const form = useForm<{ code: string }>({
    resolver: (data) => {
      if (!data.code || data.code.length !== 8) {
        return {
          values: data,
          errors: {
            code: {
              type: "required",
              message: t("page.registration.errors.code-required"),
            },
          },
        };
      }

      return {
        values: data,
        errors: {},
      };
    },
  });
  const {
    errors: { code: codeError },
  } = useFormState(form);

  if (isLoading) {
    // no delay to prevent flickering
    return <FullSizeLoader withPadding noDelay />;
  }

  return (
    <AuthenticationPage
      header={isSubmitting ? t("page.registration.title.validating") : t("page.registration.title")}
      body={isSubmitting ? undefined : t("page.registration.subtitle")}
    >
      <Form
        formMethods={form}
        onSubmit={(d) => {
          if (isSubmitting) {
            return;
          }

          onRegister(d.code);
        }}
        noPrompt
      >
        {isSubmitting ? (
          <div className="py-16">
            <FullSizeLoader withPadding />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-center gap-6">
              <div className="mt-4 flex flex-col gap-0.5 text-center">
                {error || validationError || codeError ? (
                  <div className="mb-4 text-center text-sm text-red">
                    {codeError
                      ? codeError.message
                      : validationError === "locationUserLimitReached"
                        ? t("page.registration.errors.too-many-users")
                        : validationError === "unknownLocation"
                          ? t("page.registration.errors.unknown-location")
                          : isHttpError(error) && error.status === 429
                            ? t("page.registration.errors.too-many-attempts")
                            : t("page.registration.errors.unknown")}
                  </div>
                ) : null}
                <label className="mb-4 font-semibold" htmlFor="email">
                  {t("page.registration.form.title")}
                </label>
                <AuthCodeInput autoFocus length={8} onChange={(code) => form.setValue("code", code)} />
              </div>
              <Button type="submit" className="w-full">
                {t("page.registration.form.submit")}
              </Button>
            </div>
            <div className="text-left text-sm">
              <h2 className="text-base font-semibold">{t("page.registration.help.title")}</h2>
              <ul className="my-4 list-disc pl-4">
                {t("page.registration.help.body")
                  .split("\n")
                  .map((item, i) => item && <li key={i}>{item}</li>)}
              </ul>
              <p>
                <Trans
                  i18nKey="page.registration.help.footer"
                  components={{
                    a: <Anchor isExternal inNewTab to="https://areaofpeople.com/veelgestelde-vragen" />,
                  }}
                />
              </p>
              <div className="mt-3 flex justify-center">
                <Button styling="ghostSecondary" size="sm" onClick={() => logout()}>
                  {t("page.registration.help.log-out")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </AuthenticationPage>
  );
}
