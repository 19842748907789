import { useQuery } from "@tanstack/react-query";
import { getPreprIntegrationList } from "api/prepr";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Card, CardActions, CardContent, CardHeader } from "components/Card/Card";
import { CardGrid } from "components/CardGrid/CardGrid";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { integrationIsNew } from "modules/portfolio/helpers/integration";
import { usePostHog } from "posthog-js/react";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export function AvailableIntegrationsPage(): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const posthog = usePostHog();
  const preprUrl = useConfig("preprDashboardUrl");

  const { data, isLoading, error } = useQuery({
    queryKey: QUERY_KEYS.AVAILABLE_INTEGRATIONS(sessionUser.language.id),
    queryFn: () => getPreprIntegrationList(preprUrl, sessionUser.language.id),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.available-integrations.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.portfolio.title"),
              to: routes.portfolio.overview(),
            },
            {
              name: t("page.available-integrations.title"),
            },
          ]}
        />
      }
    >
      <CardGrid>
        {data?.data.Integrations.items.map((integration) => (
          <Card
            key={integration._id}
            link={integration._slug ? routes.portfolio.integrationDetails({ id: integration._slug }) : undefined}
            onClick={() => {
              posthog.capture("clicked_integration_card", {
                integration: integration._slug,
              });
            }}
          >
            <CardContent gap="4">
              <div className="flex justify-between">
                {integration.profile_image?.url ? (
                  <img
                    className="size-16 shrink-0 rounded-full border border-grey-lightest bg-white object-cover p-1"
                    src={integration?.profile_image?.url}
                    alt={integration.name || ""}
                  />
                ) : null}
                {integrationIsNew(integration.created) ? (
                  <Label theme="green">{t("page.available-integrations.integration.new")}</Label>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col gap-2">
                <CardHeader
                  title={integration.name || ""}
                  titleLink={
                    integration._slug ? routes.portfolio.integrationDetails({ id: integration._slug }) : undefined
                  }
                />
                <div>
                  {integration.preview_content ? (
                    <p className="whitespace-pre-line">{integration.preview_content}</p>
                  ) : (
                    <p className="line-clamp-4 whitespace-pre-line">{integration.content || ""}</p>
                  )}
                </div>
              </div>
              {integration._slug && (
                <CardActions alignLeft>
                  <Button
                    type="link"
                    styling="secondary"
                    href={routes.portfolio.integrationDetails({
                      id: integration._slug,
                    })}
                  >
                    {t("page.available-integrations.integration.learn-more")}
                  </Button>
                </CardActions>
              )}
            </CardContent>
          </Card>
        ))}
      </CardGrid>
    </DocumentPaper>
  );
}
