import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { MessageInformDto } from "api/types";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import { Table } from "components/Table/Table";
import { Capture2, Headline4 } from "components/Text/Text";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useProjectId } from "hooks/Network/useProjectId";
import { useOnIntersection } from "hooks/useOnIntersection";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const INFORMED_ADMINS_LENGTH = 15;

interface InformedAdminsProps {
  messageId: string;
  onClose: () => void;
}

export function InformedAdmins({ messageId, onClose }: InformedAdminsProps): React.ReactNode {
  const projectId = useProjectId();
  const { t, i18n } = useTranslation();
  const api = useApi();

  const {
    data: informedAdminsData,
    isPending: isLoadingInformedAdmins,
    hasNextPage: hasMoreInformedAdmins,
    isFetchingNextPage: isLoadingMoreInformedAdmins,
    fetchNextPage: fetchMoreInformedAdmins,
    error: informedAdminsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_INFORMED_ADMINS(projectId, messageId),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesInformsV1(messageId, {
        Offset: pageParam * INFORMED_ADMINS_LENGTH,
        Limit: INFORMED_ADMINS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const informedAdmins = useMemo(
    () => informedAdminsData?.pages.flatMap((x) => x.items ?? []) ?? [],
    [informedAdminsData],
  );

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreInformedAdmins && hasMoreInformedAdmins) {
        void fetchMoreInformedAdmins();
      }
    }, [fetchMoreInformedAdmins, hasMoreInformedAdmins, isLoadingMoreInformedAdmins]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<MessageInformDto>();

    return [
      helper.accessor("informed", {
        header: t("component.community-post.informed-admins.modal.header.informed"),
        cell: (cell) => (
          <UserNameLink user={cell.getValue()}>
            <span className="text-aop-basic-blue hocus:text-blue-darker hocus:underline">
              {cell.getValue().fullName}
            </span>
          </UserNameLink>
        ),
      }),
      helper.accessor("informedBy", {
        header: t("component.community-post.informed-admins.modal.header.by"),
        cell: (cell) => (
          <UserNameLink user={cell.getValue()}>
            <span className="text-aop-basic-blue hocus:text-blue-darker hocus:underline">
              {cell.getValue().fullName}
            </span>
          </UserNameLink>
        ),
      }),
      helper.accessor("createdAt", {
        header: t("component.community-post.informed-admins.modal.header.on"),
        cell: (cell) => <span className="whitespace-nowrap pr-4">{formatDate(i18n, "date", cell.getValue())}</span>,
      }),
    ];
  }, [t, i18n]);

  const tableInstance = useReactTable<MessageInformDto>({
    columns,
    data: informedAdmins ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-4 p-4" data-testid="informed-admins-modal-content">
      <div className="flex flex-col gap-0.5">
        <Headline4>{t("component.community-post.informed-admins.modal.title")}</Headline4>
        <Capture2 className="text-grey-dark">
          {t("component.community-post.informed-admins.modal.description")}
        </Capture2>
      </div>
      {isLoadingInformedAdmins ? (
        <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
      ) : (
        <>
          {(informedAdminsError || !informedAdmins.length) && (
            <Capture2>{t("component.community-post.informed-admins.modal.no-data")}</Capture2>
          )}
          {informedAdmins.length > 0 && (
            <div className="max-h-64 overflow-y-auto">
              <Table
                table={tableInstance}
                data-testid="informed-admins-list"
                getRowProps={() => ({
                  "data-testid": "informed-admins-item",
                })}
              />
              {hasMoreInformedAdmins && (
                <div className="p-4" ref={loaderRef}>
                  <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                </div>
              )}
            </div>
          )}
          <div className="flex justify-end gap-2">
            <Button styling="secondary" onClick={onClose}>
              {t("common.action.close")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
