import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import { Modal } from "components/Modal/Modal";
import { Headline4 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CustomUrlModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (id: string | undefined, maintainer: string) => Promise<void>;
  defaultValues?: FormValues;
}

interface FormValues {
  id: string;
  maintainer: string;
}

export function MaintainerModal({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
  defaultValues,
}: CustomUrlModalProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    defaultValues: defaultValues,
  });

  async function handleSubmit() {
    const formValues = form.getValues();
    form.reset();
    await onSubmit(formValues.id, formValues.maintainer);

    onClose();
  }

  return (
    <Modal isOpen={isOpen} shouldCloseOnEsc={true} onRequestClose={onClose}>
      <div className="flex max-w-sm flex-col gap-4 p-4">
        <Form formMethods={form} onSubmit={handleSubmit}>
          <FormContent>
            <FormHiddenInput<FormValues> name="id" />
            <Headline4>{t("page.plus-button-links.maintainer.modal.title")}</Headline4>
            <FormField htmlFor="url" label="" hideLabel required>
              <FormInput<FormValues>
                name="maintainer"
                id="maintainer"
                placeholder={t("page.plus-button-links.maintainer.modal.input-placeholder")}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.plus-button-links.maintainer.modal.input-name"),
                  },
                }}
              />
            </FormField>
            <div className="flex w-full justify-center">
              <Button type="submit" styling="primary" isLoading={isLoading}>
                {t("common.action.save")}
              </Button>
            </div>
          </FormContent>
        </Form>
      </div>
    </Modal>
  );
}
