import type { DocumentUploadDto, ImageDto, ProjectConnectionDto, UserDto } from "api/types";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { Capture2 } from "components/Text/Text";
import { User } from "components/User/User";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface Props {
  id: string;
  title: string;
  content: string;
  images: ImageDto[];
  documents?: DocumentUploadDto[];
  group?: string;
  postedAt: string;
  author: UserDto;
  projectConnection?: ProjectConnectionDto;
  archivedAt?: string;
  updatedAt?: string;
  isDeleted?: boolean;
  "data-testid"?: string;
}

function CommunityPost({
  id,
  title,
  isDeleted,
  content,
  images,
  documents,
  group,
  postedAt,
  author,
  projectConnection,
  archivedAt,
  updatedAt,
  ...props
}: Props) {
  const { t } = useTranslation();

  return (
    <article {...props} className="relative">
      <div className="mb-4 flex justify-between">
        <div className="order-first flex w-full flex-wrap items-center gap-2 sm:order-none sm:w-auto">
          {projectConnection ? (
            <Label theme="aopDarkBlue">
              <Icon name={iconMarkerPin01} size={16} />
              {projectConnection.name}
            </Label>
          ) : null}
          {isDeleted ? (
            <div className="ml-2 flex size-6 items-center justify-center rounded-full bg-red text-white shadow">
              <Icon name={iconTrash02} className="size-[70%]" />
            </div>
          ) : null}
        </div>
      </div>
      <User
        className={twJoin("my-4", isDeleted ? "opacity-50" : "")}
        title={
          <span className="text-base">
            <span data-testid="community-post-name">{author.fullName}</span>
            <span className="font-normal">
              {group ? <i> {t("component.community-post.posted-in-group", { group })}</i> : null}
            </span>
          </span>
        }
        subtitle={
          <Capture2>
            {archivedAt ? (
              <p>
                <FormattedDate format="datetimeRelative" date={postedAt} /> •{" "}
                <span className="text-sm font-semibold uppercase">{t("component.community-post.archived")}</span>
              </p>
            ) : updatedAt ? (
              <p>
                <FormattedDate format="datetimeRelative" date={postedAt} /> •{" "}
                <span className="text-sm font-semibold uppercase">{t("component.community-post.edited")}</span>
              </p>
            ) : (
              <FormattedDate format="datetimeRelative" date={postedAt} />
            )}
          </Capture2>
        }
        avatar={author.avatar}
        deletedAt={author.deletedAt}
      />
      <h1 className={twJoin("mb-2 max-w-prose font-semibold")} data-testid="community-post-title">
        {title}
      </h1>
      <p className={twJoin("max-w-prose", isDeleted ? "text-black/40" : "")} data-testid="community-post-content">
        <LinkFormatter>{content}</LinkFormatter>
      </p>
      {images.length > 0 ? (
        <div className="mt-4">
          <Gallery images={images} />
        </div>
      ) : null}
      {documents?.length ? (
        <div className="mt-4 flex flex-wrap gap-2">
          {documents.map((x) => (
            <Pdf
              key={x.id}
              previewImage={x.previewImage}
              fileName={x.fileName}
              fileSize={x.fileSize}
              onClick={() => window.open(x.url, "_blank")}
            />
          ))}
        </div>
      ) : null}
    </article>
  );
}

const CommunityPostMemo = memo(CommunityPost);

export { CommunityPostMemo as CommunityPost };
