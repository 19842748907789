import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserDetailsWithAddressDto } from "api/types";
import checkIcon from "assets/icons/check.svg";
import pencilLineIcon from "assets/icons/pencil-line.svg";
import userPlus01Icon from "assets/icons/user-plus-01.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Capture1, Capture2, Headline3, Headline4, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { GROUP_NAME_MAX_LENGTH, GROUP_NAME_MIN_LENGTH } from "modules/chats/components/CreateChatModal";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { EditGroupChatFormValues } from "./EditGroupChatModal";

interface EditGroupChatModalInfoViewProps {
  groupChatId: string;
  isModalOpen: boolean;
  isUpdating: boolean;
  onAddMembers: () => void;
  onLeaveGroup: () => void;
}

export function EditGroupChatModalInfoView({
  groupChatId,
  isModalOpen,
  isUpdating,
  onAddMembers,
  onLeaveGroup,
}: EditGroupChatModalInfoViewProps): React.ReactNode {
  const [isEditingName, editNameHandlers] = useBool(false);

  const projectId = useProjectId();
  const api = useApi();
  const { t } = useTranslation();
  const formMethods = useFormContext<EditGroupChatFormValues>();
  const sessionUser = useSessionUser();

  const { data: members, isPending: isPendingMembers } = useQuery({
    queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, groupChatId),
    queryFn: () => api.getChatsMembersV2(groupChatId),
    select: commonAPIDataSelector,
    enabled: isModalOpen,
  });

  const onConfirmNewName = async (isForced = false) => {
    const isNewGroupNameValid = await formMethods.trigger("groupName");
    const newGroupName = formMethods.watch("groupName");

    if (isNewGroupNameValid) {
      formMethods.setValue("groupName", newGroupName.trim());
      editNameHandlers.setFalse();
    } else if (!isNewGroupNameValid && isForced) {
      formMethods.setValue("groupName", formMethods.formState.defaultValues!.groupName!, {
        shouldDirty: true,
      });
    }
  };

  // Handle unconfirmed name when view gets unmounted
  useEffect(() => {
    return () => {
      void onConfirmNewName(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currGroupName = formMethods.watch("groupName");

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-2">
        {/* Header */}
        <Headline4>{t("component.group-chat-info-modal.title")}</Headline4>
        <span className="self-center">
          <FormField label={t("component.group-chat-info-modal.form.image.label")} htmlFor="groupImage" hideLabel>
            <FormImageInput<EditGroupChatFormValues, "groupImage">
              name="groupImage"
              rules={{
                validate: {
                  size(images) {
                    const filteredImages = images.filter((image) => Boolean(image));

                    if (filteredImages.length > 0) {
                      return validateSize(t, filteredImages);
                    }
                  },
                },
              }}
              nOfImages={1}
              isAvatarInput
            />
          </FormField>
        </span>
        <div className="flex w-full items-center justify-center gap-2">
          {isEditingName ? (
            <>
              <FormField
                label={t("component.group-chat-info-modal.form.name.label")}
                htmlFor="groupName"
                hideLabel
                required
              >
                <FormInput<EditGroupChatFormValues, "groupName">
                  id="groupName"
                  name="groupName"
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "component.group-chat-info-modal.form.name.label"),
                    },
                    minLength: {
                      message: t("components.form.error.min-length", {
                        length: GROUP_NAME_MIN_LENGTH,
                      }),
                      value: GROUP_NAME_MIN_LENGTH,
                    },
                    maxLength: {
                      message: t("components.form.error.max-length", { length: GROUP_NAME_MAX_LENGTH }),
                      value: GROUP_NAME_MAX_LENGTH,
                    },
                  }}
                  autoFocus
                />
              </FormField>
              <IconButton
                title={t("component.group-chat-info-modal.confirm-name.btn")}
                styling="ghostPrimary"
                size="sm"
                onClick={() => onConfirmNewName()}
                withTooltip={false}
              >
                <Icon name={checkIcon} size={16} />
              </IconButton>
            </>
          ) : (
            <>
              <Headline3 className="w-fit max-w-[33%] truncate">{currGroupName}</Headline3>
              {sessionUser.chatEnabled && (
                <IconButton
                  title={t("component.group-chat-info-modal.edit-name.btn")}
                  styling="ghostPrimary"
                  size="sm"
                  onClick={editNameHandlers.setTrue}
                  withTooltip={false}
                >
                  <Icon name={pencilLineIcon} size={16} />
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>

      <EditGroupChatModalMemberListView
        members={members || []}
        isLoading={isPendingMembers}
        onClickAddMembers={onAddMembers}
      />

      <div className="flex w-full flex-1 justify-between">
        {members && members.length > 1 && (
          <Button styling="danger" onClick={onLeaveGroup}>
            {t("component.group-chat-info-modal.leave-group.btn")}
          </Button>
        )}
        {sessionUser.chatEnabled && (
          <Button
            styling="primary"
            className="ml-auto"
            isLoading={isUpdating}
            disabled={!formMethods.formState.isDirty || isEditingName}
            type="submit"
          >
            {t("component.group-chat-info-modal.save-changes.btn")}
          </Button>
        )}
      </div>
    </div>
  );
}

interface EditGroupChatModalMemberListViewProps {
  members: UserDetailsWithAddressDto[];
  isLoading: boolean;
  onClickAddMembers: () => void;
}

function EditGroupChatModalMemberListView({
  members,
  isLoading,
  onClickAddMembers,
}: EditGroupChatModalMemberListViewProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex w-full justify-between">
        <Capture1>{t("component.group-chat-info-modal.members-count", { count: members.length || 0 })}</Capture1>
        {sessionUser.chatEnabled && (
          <Button styling="ghostPrimary" icon={<Icon name={userPlus01Icon} size={16} />} onClick={onClickAddMembers}>
            {t("component.group-chat-info-modal.action.add-members")}
          </Button>
        )}
      </div>
      <ul className="relative h-64 w-full overflow-y-auto overflow-x-hidden rounded-lg border border-grey-lighter">
        {isLoading && <LoadingIcon className="absolute left-1/2 top-1/2 w-6 -translate-x-1/2 -translate-y-1/2" />}
        {!isLoading &&
          members.length > 0 &&
          members.map((member) => (
            <li
              key={member.id}
              className="flex w-full items-center gap-2 overflow-x-hidden border-b border-grey-lighter px-4 py-2 last:rounded-b-lg last:border-b-0 even:bg-grey-lightest"
            >
              <span className="size-10">
                <UserAvatar img={member.avatar} />
              </span>
              <div className="flex w-full flex-col truncate">
                <Subtitle2>{member.fullName}</Subtitle2>
                <Capture2 className="text-grey">{member.locatedAt}</Capture2>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
