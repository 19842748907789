import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Modal } from "components/Modal/Modal";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { format } from "date-fns";
import { asUtc } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

interface Props {
  month?: Date;
  isOpen: boolean;
  onClose: () => void;
}

export function FeelingAtHomeExportModal({ month, isOpen, onClose }: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();

  const query = month ? { month: asUtc(month).toISOString() } : undefined;
  const { data, isFetching, error } = useQuery({
    queryKey: QUERY_KEYS.FEELING_AT_HOME_EXPORT(projectId, query),
    queryFn: () => api.getAnalyticsFeelingAtHomeExportV1(query),
    enabled: isOpen,
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick shouldCloseOnEsc>
      <div className="relative -z-10 flex flex-col gap-4 p-4">
        {isFetching && (
          <div className="pointer-events-none absolute inset-0" tabIndex={-1}>
            <FullSizeLoader />
          </div>
        )}
        <div className={isFetching ? "invisible" : undefined}>
          <Headline4 as="h2">{t("page.feeling-at-home-details.export.modal.title")}</Headline4>
          {!error && (
            <Subtitle2 as="p" className="mt-1 max-w-96 font-normal">
              {t("page.feeling-at-home-details.export.modal.description")}
            </Subtitle2>
          )}
          <div className="mt-4 max-w-96">
            {error ? (
              <span className="block max-w-96 text-red">{t("page.feeling-at-home-details.export.modal.error")}</span>
            ) : (
              <Button
                onClick={async () => {
                  if (!data) {
                    return;
                  }

                  const res = await data.blob();
                  const newBlob = new Blob([res]);

                  const blobUrl = window.URL.createObjectURL(newBlob);

                  const link = document.createElement("a");
                  link.href = blobUrl;

                  const translationFileName = t("page.feeling-at-home-details.export.modal.file-name");
                  const name = month ? "-" + format(month, "yyyy-MM") : "";

                  link.setAttribute("download", `${translationFileName.replace(/[^a-zA-Z0-9]/g, "")}-${name}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                  if (link.parentNode) {
                    link.parentNode.removeChild(link);
                  }

                  window.URL.revokeObjectURL(blobUrl);
                }}
              >
                {t("page.feeling-at-home-details.export.modal.button")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
