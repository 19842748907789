import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useResizeObserver } from "hooks/useResizeObserver";
import { BarChart } from "modules/analytics/components/BarChart";
import { NoData } from "modules/analytics/components/NoData";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Card, CardHeader } from "../../components/Card";

export function CommunicationsChart(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_WEEKLY_MESSAGES(projectId),
    queryFn: () => api.getAnalyticsWeeklyMessagesV1(),
    select: commonAPIDataSelector,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [skipTicksModulo, setSkipTicksModulo] = useState(2);

  const dataPoints = useMemo(() => {
    if (!data) {
      return [];
    }

    const dataPoints = [];
    for (const point of data.weeklyCount) {
      const x = String(point.weekNumber);
      dataPoints.push({ x: x, y: isDefined(point.count) ? point.count : undefined });
    }

    return dataPoints;
  }, [data]);

  useResizeObserver(containerRef, () => {
    if (containerRef?.current) {
      const width = containerRef?.current.offsetWidth;

      if (width < 450) {
        setSkipTicksModulo(5);
      } else if (width < 550) {
        setSkipTicksModulo(4);
      } else if (width < 650) {
        setSkipTicksModulo(3);
      } else {
        setSkipTicksModulo(2);
      }
    }
  });

  const noDataAvailable = !data || dataPoints.every((x) => x.y === 0) || data.totalOverall === 0;

  return (
    <Card ref={containerRef}>
      <CardHeader
        title={t("page.analytics-overview.chart.communication.title")}
        tooltip={t("page.analytics-overview.chart.communication.title-tooltip")}
      />
      {noDataAvailable ? (
        <NoData />
      ) : (
        <div className="flex flex-col items-center">
          <div>
            <Trans
              i18nKey="page.analytics-overview.chart.communication.legend"
              values={{
                total: data.totalOverall,
              }}
              components={{
                bold: <strong className="inline font-semibold" />,
              }}
            />
          </div>
          <div className="w-full">
            <BarChart
              type="text"
              primaryData={dataPoints}
              formatYTick={(y) => y}
              formatXTick={(x) => `WK ${x}`}
              xLabel={t("page.analytics-overview.chart.communication.x-axis")}
              yLabel={t("page.analytics-overview.chart.communication.y-axis")}
              showLegend={false}
              skipXTicksModulo={skipTicksModulo}
              width={550}
              renderLabel={(data) => (
                <div className="whitespace-pre-line">
                  {t("page.analytics-overview.chart.communication.datapoint-label", {
                    amount: data.primary?.y,
                    week: data.x,
                  })}
                </div>
              )}
            />
          </div>
        </div>
      )}
    </Card>
  );
}
