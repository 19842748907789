import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import { AlertBar } from "components/AlertBar/AlertBar";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { Headline4 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  initialSlug: string;
  takenSlugs: string[];
  onSubmit: (slug: string) => void;
  onClose: () => void;
}

export function SlugModal({ initialSlug, takenSlugs, onSubmit, onClose }: Props): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<SlugModalValues>({
    defaultValues: {
      slug: initialSlug,
    },
  });

  return (
    <Form<SlugModalValues> formMethods={form} onSubmit={(data) => onSubmit(data.slug.trim().toLowerCase())} noPrompt>
      <div className="flex max-w-lg flex-col items-center gap-4 p-4 text-left">
        <Headline4>{t("page.projects.slug-modal.title")}</Headline4>

        <AlertBar theme="yellow" icon={<Icon name={iconAlertTriangle} />}>
          {t("page.projects.slug-modal.warning")}
        </AlertBar>

        <div className="w-full">
          <FormContent maxWidth="4xl">
            <FormInput<SlugModalValues>
              id="slug"
              name="slug"
              rules={{
                maxLength: {
                  message: t("components.form.error.max-length", {
                    length: 64,
                  }),
                  value: 64,
                },
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.slug"),
                  mustNotContainSpecialCharacters: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    return /[^A-Za-z0-9-]/.test(value) ? t("components.form.error.no-special-characters") : undefined;
                  },
                  mustBeUnique: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    if (value === initialSlug) {
                      return undefined;
                    }

                    return takenSlugs.includes(value) ? t("page.projects.slug-modal.error.not-unique") : undefined;
                  },
                },
              }}
            />
          </FormContent>
        </div>

        <div className="mt-2 flex items-center justify-center gap-2">
          <Button type="button" styling="secondary" onClick={onClose}>
            {t("page.projects.slug-modal.cancel")}
          </Button>
          <Button type="submit">{t("page.projects.slug-modal.submit")}</Button>
        </div>
      </div>
    </Form>
  );
}

interface SlugModalValues {
  slug: string;
}
