import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { LegendDataIcon } from "components/Charts/LegendDataComponent";
import { formatDuration } from "components/FormattedDuration/FormattedDuration";
import { Select } from "components/Select/Select";
import { avgBy } from "helpers/math";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { groupBy, round, sum, uniqBy } from "lodash-es";
import { BarChart } from "modules/analytics/components/BarChart";
import { NoData } from "modules/analytics/components/NoData";
import { dataColors } from "modules/analytics/theme";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import { Card, CardHeader } from "../../components/Card";
import type { DateRangeOption } from "../../util";
import { formatChartDate } from "../../util";

interface Props {
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
}

export function AverageTicketClosingTimeCard({ dateRangeOptions }: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t, i18n } = useTranslation();
  const api = useApi();
  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);
  const [category, setCategory] = useState<{ id: string; name: string }>();
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_TICKETS_AVERAGE_CLOSING_TIME(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsTicketLeadTimeMonthlyV1({ dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });
  const project = useProject();
  const categories = useMemo(
    () =>
      uniqBy(
        data?.data.map((x) => ({ name: x.categoryName, id: x.categoryId })),
        (x) => x.id,
      ) ?? [],
    [data?.data],
  );
  const benchmark = useMemo(
    () =>
      data
        ? data.benchmark.map((d) => ({
            x: formatChartDate(d.range),
            y: d.benchmarkDaysBeforeTicketClosed,
          }))
        : [],
    [data],
  );
  const groupedByDate = useMemo(
    () =>
      data
        ? Object.entries(groupBy(data.data, (x) => formatChartDate(x.range))).map(([date, values]) => {
            const average = avgBy(
              values,
              (v) => v.averageDaysBeforeTicketClosed,
              (v) => v.closedTicketCount,
            );

            return {
              x: date,
              y: isDefined(average) ? round(average) : undefined,
              ticketCount: sum(values.map((x) => x.closedTicketCount)),
            };
          })
        : [],
    [data],
  );
  const groupedByCategory = useMemo(
    () =>
      data
        ? groupBy(
            data.data.map((d) => ({
              x: formatChartDate(d.range),
              y: d.averageDaysBeforeTicketClosed,
              categoryName: d.categoryName,
              categoryId: d.categoryId,
              ticketCount: d.closedTicketCount,
            })),
            (d) => d.categoryId,
          )
        : {},
    [data],
  );

  const noDataAvailable = !data || data.data.every((x) => x.closedTicketCount === 0);

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.avg-ticket-closing-time.title")}
        tooltip={t("page.analytics-overview.chart.avg-ticket-closing-time.title-tooltip")}
      >
        <Select
          placeholder={t("page.analytics-overview.category-placeholder")}
          emptyItem={t("page.analytics-overview.category-deselect")}
          items={categories}
          selected={category}
          onChange={setCategory}
          renderOption={(x) => x.name}
          keySelector={(x) => x.id}
        />
        <Select
          items={dateRangeOptions}
          selected={dateRange}
          onChange={setDateRange}
          renderOption={(x) => x.name}
          keySelector={(x) => x.value}
        />
      </CardHeader>
      {noDataAvailable ? (
        <NoData />
      ) : (
        <BarChart
          benchmark={benchmark}
          type="date"
          benchmarkLabel={t("page.analytics-overview.benchmark")}
          primaryData={groupedByDate}
          primaryLabel={project.name}
          formatYTick={(x) => formatDuration(i18n, { days: x }, ["days", "hours"], true)}
          secondaryLabel={category?.name}
          secondaryData={category ? groupedByCategory[category.id] : undefined}
          xLabel={t("page.analytics-overview.chart.avg-ticket-closing-time.x-axis")}
          yLabel={t("page.analytics-overview.chart.avg-ticket-closing-time.y-axis")}
          showLegend
          renderLabel={({ benchmark, primary, secondary }, isSecondary) => (
            <table>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    {t("page.analytics-overview.chart.avg-ticket-closing-time.datapoint-label", {
                      amount: (isSecondary ? secondary?.ticketCount : primary?.ticketCount) || 0,
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="pr-2">
                    <LegendDataIcon fill={dataColors.primary} />
                  </td>
                  <td>{project.name}</td>
                  <td className="pl-2 font-semibold">
                    {formatDuration(i18n, { days: primary?.y }, ["days", "hours"], true)}
                  </td>
                </tr>
                {secondary && isDefined(secondary.y) ? (
                  <tr>
                    <td className="pr-2">
                      <LegendDataIcon fill={dataColors.secondary} />
                    </td>
                    <td>{category?.name || "-"}</td>
                    <td className="pl-2 font-semibold">
                      {formatDuration(i18n, { days: secondary?.y }, ["days", "hours"], true)}
                    </td>
                  </tr>
                ) : null}
                {benchmark?.y ? (
                  <tr>
                    <td className="pr-2">
                      <LegendDataIcon fill={dataColors.benchmark} type="minus" />
                    </td>
                    <td>{t("page.analytics-overview.benchmark")}</td>
                    <td className="pl-2 font-semibold">
                      {formatDuration(i18n, { days: benchmark?.y }, ["days", "hours"], true)}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          )}
        />
      )}
    </Card>
  );
}
